import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UIState {
    theme: string;
    bookmarkImportModal: boolean;
    avatarModal: boolean;
    confirmModal: boolean;
    addBookmarkModal: boolean;
    showSearchModal: boolean;
    sidebarOpen: boolean;
    pageSize: number;
}

const initialState: UIState = {
    theme: '',
    bookmarkImportModal: false,
    avatarModal: false,
    confirmModal: false,
    addBookmarkModal: false,
    showSearchModal: false,
    sidebarOpen: false,
    pageSize: 9,
};

const uiSlice = createSlice({
    name: "ui",
    initialState,
    reducers: {
        themeDark(state) {
            state.theme = 'dark';
        },
        themeLight(state) {
            state.theme = 'light';
        },
        themeSystem(state) {
            state.theme = 'system';
        },
        toggleBookmarkImportModal(state) {
          state.bookmarkImportModal = !state.bookmarkImportModal
        },
        toggleSearchModal(state) {
          state.showSearchModal = !state.showSearchModal
        },
        setShowSearchModal(state, action: PayloadAction<boolean>) {
          state.showSearchModal = action.payload
        },
        toggleAvatarModal(state) {
          state.avatarModal = !state.avatarModal
        },
        toggleAddBookmarkModal(state) {
          state.addBookmarkModal = !state.addBookmarkModal
        },
        toggleConfirmModal(state) {
          state.confirmModal = !state.confirmModal
        },
        openSidebar(state,  action: PayloadAction<boolean>) {
            state.sidebarOpen = action.payload;
        },
        setPageSize(state,  action: PayloadAction<number>) {
            state.pageSize = action.payload;
        }
    },
});

export const    {
                    themeDark,
                    themeLight,
                    themeSystem,
                    toggleBookmarkImportModal,
                    toggleAvatarModal,
                    toggleAddBookmarkModal,
                    openSidebar,
                    toggleConfirmModal,
                    setPageSize,
                    toggleSearchModal,
                    setShowSearchModal
                } = uiSlice.actions;

export default uiSlice.reducer;