import React, {Fragment, useCallback, useEffect, useRef, useState} from 'react'
import { Dialog, Transition } from '@headlessui/react'
import axiosClient from "../../../config/axios.config";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux/rootState";
import {Person} from "../../../redux/models/Person";
import {useDropzone} from "react-dropzone";
import {toggleBookmarkImportModal} from "../../../redux/slices/sliceUI";
import {useTranslation} from "react-i18next";


interface Props {
}
const BookmarkImportModal = ({} : Props) => {
    const {t} = useTranslation()
    const dispatch = useDispatch();
    const open = useSelector((state: RootState) => state.ui.bookmarkImportModal)
    const [saveDisabled, setSaveDisabled] = useState(true)
    const [file, setFile] = useState<File | null>(null);
    const cancelButtonRef = useRef(null)


    const handleClickClose = (event: React.MouseEvent<HTMLElement>) => {
        dispatch(toggleBookmarkImportModal())
    }

    const onDrop = useCallback((acceptedFiles: File[]) => {
        console.log(acceptedFiles)
    }, [])

    const {getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
        accept: {
            'text/html': [],
            // 'image/png': [],
        },
        maxFiles: 1,
        maxSize: 1024 * 1024 * 10,
        onDrop: onDrop,
    });

    const setOpen = (open: boolean) => {
        console.log('open', open)
    }

    const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === "Escape") {
            e.preventDefault()
            document.removeEventListener('keydown', handleKeyDown)
            dispatch(toggleBookmarkImportModal())
        }

    }

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown)
    })

    const handleSubmit = async (event: React.MouseEvent<HTMLElement>) => {
        if (file) {
            const formData = new FormData()
            formData.append('file', file)
            const restClient = await axiosClient()
            restClient.post('/api/v1/upload/bookmarks', formData)
                .then((value) => {
                    console.log(value)
            //         updateUserMutation({variables: {avatarPath: value.data.path, avatarExtension: value.data.type}})
            //             .then((updatedUser) => {
            //                 if (updatedUser.data && updatedUser.data.UpdateUser) {
            //                     new Person(dispatch)
            //                         .importGraphQlUser(updatedUser.data!.UpdateUser)
            //                         .dispatchAvatar();
            //                 }
            //             })
            //         onClose();
                })
                .catch(console.log)
        }
    }

    const handleFileAdded = (newFile: File) => {
        setFile(newFile);

    };

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 dark:accent-slate-900">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg dark:accent-slate-900">
                                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 dark:bg-slate-900">
                                    <div className="sm:flex sm:items-start">
                                        <div className="container mx-auto">
                                            <label htmlFor="cover-photo"
                                                   className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">Import bookmarks</label>
                                            <div
                                                {...getRootProps()}
                                                className={`mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10 dark:border-gray-400 ${isDragActive ? "drop-active" : ""}`}>
                                                <div className="text-center">
                                                    <svg className="mx-auto h-12 w-12 text-gray-300" viewBox="0 0 24 24"
                                                         fill="currentColor" aria-hidden="true">
                                                        <path fillRule="evenodd"
                                                              d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z"
                                                              clipRule="evenodd"></path>
                                                    </svg>
                                                    <div className="mt-4 flex text-sm leading-6 text-gray-600">
                                                        <label htmlFor="file-upload"
                                                               className="relative cursor-pointer rounded-md bg-white font-semibold text-blue-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-600 focus-within:ring-offset-2 hover:text-blue-500  dark:bg-slate-900 dark:text-white">
                                                            <span className="dark:text-white">Upload a file</span>
                                                            <input {...getInputProps()} />
                                                        </label>
                                                        <p className="pl-1 dark:text-white">or drag and drop</p>
                                                    </div>
                                                    {!isDragActive && (<p className="text-xs leading-5 text-gray-600 dark:text-white">HTML up to 10MB</p>)}
                                                    {isDragActive && (<p className="text-xs leading-5 text-gray-600 dark:text-white">Drop some avatar file here ...</p>)}
                                                    {isDragReject && (<p className="text-xs leading-5 text-gray-600 dark:text-white">Only HTML files ...</p>)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 dark:bg-slate-700">
                                    <button
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto disabled:text-gray-900 disabled:bg-gray-200 dark:disabled:bg-gray-400"
                                        onClick={handleSubmit}
                                        disabled={saveDisabled}
                                    >
                                        Save
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto dark:bg-blue-600 dark:ring-blue-600 dark:text-white"
                                        onClick={handleClickClose}

                                        ref={cancelButtonRef}

                                    >
                                        Cancel
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default BookmarkImportModal;
