import React, {useState} from 'react'
import BookmarkTag from "../../../Bookmark/BookmarkTag";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {FolderIcon} from "@heroicons/react/20/solid";
import {classNames} from "../../../../../utils/classNames";
import {FormatTagLink} from "../../../../../utils/Tags";
import {compareStrings} from "../../../../../utils/strings";
import {IBookmarkTag} from "../../../../../config/interfaces/tags";
import {
    useGetTagsByParentWithCountLazyQuery,
    useGetTagsByParentWithCountQuery
} from "../../../../../generated/graphql/graph";
import {useDispatch} from "react-redux";
import {openSidebar} from "../../../../../redux/slices/sliceUI";


interface Props {
    tag: IBookmarkTag
    indent: number
}
const MenuTagLinkComponent = ({tag, indent} : Props) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {tagID} = useParams();
    const [openChildren, setOpenChildren] = useState<boolean>(false)
    const [children, setChildren] = useState<IBookmarkTag[]>([])
    const [getChildrenQuery, {data, loading, error}] = useGetTagsByParentWithCountLazyQuery({variables: {id: tag.value}})

    const toggleOpen = () => {
        if (!openChildren) {
            getChildrenQuery()
                .then(qd => {
                    if (qd && qd.data && qd.data.GetTagsByParent) {
                        setChildren(qd.data.GetTagsByParent)
                        setOpenChildren(!openChildren)
                    }
                })
                .catch(console.log)
        } else {
            setOpenChildren(!openChildren)
        }
    }

    const closeSideMenu = () => {
        dispatch(openSidebar(false))
    }

    return (
        <li key={tag.label}>
            <div className="flex items-center justify">
                <div style={{width: `${indent * 10}px` }}></div>
                <div>
                    <div className="w-6 h-full mr-1">
                        {tag.hasChildren ?
                            <NavLink  to="#" onClick={toggleOpen} className="text-gray-700 hover:text-blue-600 hover:bg-gray-50 dark:text-gray-400 dark:hover:bg-slate-400 hover:dark:text-white group flex rounded-md text-sm leading-6 font-semibold">
                                <FolderIcon
                                    className={classNames(
                                        false ? 'text-blue-600 dark:text-white dark:group-hover:text-white' : 'text-gray-400 dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-white', 'h-6 w-6 shrink-0'
                                    )}
                                    aria-hidden="true"
                                />
                            </NavLink>
                            : ''}
                    </div>
                </div>
                <div className="flex-grow">
                    <NavLink
                        to={FormatTagLink(tag)}
                        onClick={closeSideMenu}
                        className={classNames(
                            compareStrings(tagID, tag.value)
                                ? 'bg-gray-50 text-blue-600 dark:bg-slate-700'
                                : 'text-gray-700 hover:text-blue-600 hover:bg-gray-50 hover:dark:bg-slate-400 dark:hover:text-white',
                            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold dark:text-gray-400'
                        )}
                    >
                                  <span
                                      className={classNames(
                                          compareStrings(tagID, tag.value)
                                              ? 'text-blue-600 border-blue-600 dark:border-slate-500 dark:group-hover:border-slate-400 dark:group-hover:text-white dark:text-gray-100'
                                              : 'text-gray-400 border-gray-200 group-hover:border-blue-600 group-hover:text-blue-600 dark:border-slate-500 dark:group-hover:border-slate-400 dark:group-hover:text-white dark:text-gray-100',
                                          'flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white dark:bg-slate-600'
                                      )}
                                  >
                                    {tag.count}
                                  </span>
                        <span className="truncate"><span className="text-gray-400 dark:text-gray-300 dark:group-hover:text-white">#</span><span dangerouslySetInnerHTML={{__html: tag.label}}></span></span>
                    </NavLink>
                </div>
            </div>
            {openChildren &&
                <div>
                    {children.map(child =>(
                        <MenuTagLinkComponent tag={child} indent={indent + 1}/>
                    ))}
                </div>
            }
        </li>
    )
}

export default MenuTagLinkComponent;
