import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {IBookmark} from "../models/IBookmark";

interface TagsState {
    isUpdated: boolean;
}

const initialState: TagsState = {
    isUpdated: false,
};

const tagsSlice = createSlice({
    name: "tags",
    initialState,
    reducers: {
        setTagsUpdated(state, action: PayloadAction<boolean>) {
            state.isUpdated = action.payload
        },
    },
});

export const { setTagsUpdated } = tagsSlice.actions;

export default tagsSlice.reducer;