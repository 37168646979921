import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {IBookmark} from "../models/IBookmark";

interface LatestBookmarkState {
    bookmarks: IBookmark[];
    isUpdated: boolean;
}

const initialState: LatestBookmarkState = {
    bookmarks: [],
    isUpdated: false,
};

const latestBookmarksSlice = createSlice({
    name: "latestBookmarks",
    initialState,
    reducers: {
        setLatestBookmarks(state, action: PayloadAction<IBookmark[]>) {
            state.bookmarks = action.payload
        },
        clearLatestBookmarks(state) {
            state.bookmarks = [];
        },
        setLatestUpdated(state, action: PayloadAction<boolean>) {
            state.isUpdated = action.payload
        },
        toggleLatestUpdated(state) {
            state.isUpdated = !state.isUpdated
        },
    },
});

export const { setLatestBookmarks, clearLatestBookmarks, setLatestUpdated,toggleLatestUpdated } = latestBookmarksSlice.actions;

export default latestBookmarksSlice.reducer;