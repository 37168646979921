import React from 'react'
import {t} from "i18next";
import PageSizeComponent from "./PageSizeComponent";
import {ClassesH2, ClassesP} from "../../../../utils/StylingClasses";
import LanguageComponent from "./LanguageComponent";
import ThemeComponent from "./ThemeComponent";
import AutoPurgeTrashComponent from "./AutoPurgeTrashComponent";


interface Props {
}
const UISettingsComponent = ({} : Props) => {
    return (
        <div className="my-5">
            <h1 className={ClassesH2}>{t("Settings")}</h1>
            <p className={ClassesP}>
                {t("General settings to change the behaviour of the application")}
            </p>

            <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6 dark:divide-slate-500">
                <PageSizeComponent />
                <AutoPurgeTrashComponent />
                <LanguageComponent />
                <ThemeComponent />
            </dl>
            <hr className="my-10 border border-t-1 border-gray-400 dark:divide-slate-500"/>
        </div>
    )
}

export default UISettingsComponent;

