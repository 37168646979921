import React, {Fragment, useEffect, useState} from 'react';
import {Menu, Transition} from "@headlessui/react";
import {
    SunIcon,
} from '@heroicons/react/20/solid';
import classNames from "classnames";
import {useDispatch, useSelector} from "react-redux";
import {themeDark, themeLight, themeSystem} from "../../../../redux/slices/sliceUI";
import {useTranslation} from "react-i18next";
import useUserSettings, {THEME_DARK, THEME_LIGHT} from "../../../../hooks/UserSettings";
import {setTheme} from "../../../../redux/slices/sliceUserSettings";

interface Props {
}


const ThemeSwitch: React.FC<Props> = () => {
    const {t} = useTranslation()
    const dispatch = useDispatch();
    const {SetThemeLight, SetThemeDark, SetThemeSystem, theme} = useUserSettings()

    const switchToDarkMode = () => {
        localStorage.setItem('theme', THEME_DARK)
        SetThemeDark()
        document.documentElement.classList.add('dark')

    }

    const switchToLightMode = () => {
        localStorage.setItem('theme', THEME_LIGHT)
        SetThemeLight();
        document.documentElement.classList.remove('dark')
    }

    const switchToSystemMode = () => {
        localStorage.removeItem('theme')
        SetThemeSystem()
    }

    useEffect(() =>{
        const storageTheme = localStorage.getItem('theme')
        if ((storageTheme ===  null || storageTheme === undefined)) {
            if(window.matchMedia('(prefers-color-scheme: dark)')) {
                document.documentElement.classList.add('dark')
                dispatch(setTheme(THEME_DARK))
            } else {
                dispatch(setTheme(THEME_LIGHT))
                document.documentElement.classList.remove('dark')
            }
        } else if (storageTheme == THEME_DARK) {
            dispatch(setTheme(THEME_DARK))
        } else {
            dispatch(setTheme(THEME_LIGHT))
        }
    })

    return (
        < >
            <Menu as="div" className="relative inline-block text-left mt-1">
                {/*<div>*/}
                    {/*px-3 py-2*/}
                    <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md  text-sm font-semibold text-gray-900 dark:bg-slate-800" title={t("Select your theme").toString()}>
                        {theme === 'dark' &&
                            <svg viewBox="0 0 24 24" className="h-5 w-5 text-gray-400 group-hover:text-gray-500 dark:fill-gray-400">
                                <path fill="currentColor"
                                      d="M17.75,4.09L15.22,6.03L16.13,9.09L13.5,7.28L10.87,9.09L11.78,6.03L9.25,4.09L12.44,4L13.5,1L14.56,4L17.75,4.09M21.25,11L19.61,12.25L20.2,14.23L18.5,13.06L16.8,14.23L17.39,12.25L15.75,11L17.81,10.95L18.5,9L19.19,10.95L21.25,11M18.97,15.95C19.8,15.87 20.69,17.05 20.16,17.8C19.84,18.25 19.5,18.67 19.08,19.07C15.17,23 8.84,23 4.94,19.07C1.03,15.17 1.03,8.83 4.94,4.93C5.34,4.53 5.76,4.17 6.21,3.85C6.96,3.32 8.14,4.21 8.06,5.04C7.79,7.9 8.75,10.87 10.95,13.06C13.14,15.26 16.1,16.22 18.97,15.95M17.33,17.97C14.5,17.81 11.7,16.64 9.53,14.5C7.36,12.31 6.2,9.5 6.04,6.68C3.23,9.82 3.34,14.64 6.35,17.66C9.37,20.67 14.19,20.78 17.33,17.97Z"/>
                            </svg>
                        }
                        {theme === 'light' && <SunIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 dark:fill-gray-400" />}
                        {(theme === 'system' || theme == '') &&
                            <svg className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 dark:fill-gray-400"  viewBox="0 0 24 24">
                                <path fill="currentColor"
                                      d="M12 2A10 10 0 0 0 2 12A10 10 0 0 0 12 22A10 10 0 0 0 22 12A10 10 0 0 0 12 2M12 4A8 8 0 0 1 20 12A8 8 0 0 1 12 20V4Z"/>
                            </svg>
                        }
                    </Menu.Button>
                {/*</div>*/}

                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-54 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-slate-700">
                        <div className="py-1">
                            <Menu.Item>
                                {({ active }) => (
                                    <button
                                        onClick={switchToLightMode}
                                        className={classNames(
                                            active ? 'bg-gray-100 text-gray-900 dark:bg-slate-500 themeSwitch active' : 'text-gray-700',
                                            'group flex items-center px-4 py-2 text-sm dark:text-white w-full'
                                        )}
                                    >
                                        <SunIcon
                                            className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 themeSwitchIcon"
                                            aria-hidden="true"
                                        />
                                        {t("Light")}
                                    </button>
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                {({ active }) => (
                                    <button
                                        onClick={switchToDarkMode}
                                        className={classNames(
                                            active ? 'bg-gray-100 text-gray-900 dark:bg-slate-500 themeSwitch active' : 'text-gray-700',
                                            'group flex items-center px-4 py-2 text-sm dark:text-white w-full'
                                        )}
                                    >
                                        <svg viewBox="0 0 24 24" className="mr-3 h-5 w-5 text-gray-400 fill-gray-400 group-hover:text-gray-500 themeSwitchIcon">
                                            <path
                                                  d="M17.75,4.09L15.22,6.03L16.13,9.09L13.5,7.28L10.87,9.09L11.78,6.03L9.25,4.09L12.44,4L13.5,1L14.56,4L17.75,4.09M21.25,11L19.61,12.25L20.2,14.23L18.5,13.06L16.8,14.23L17.39,12.25L15.75,11L17.81,10.95L18.5,9L19.19,10.95L21.25,11M18.97,15.95C19.8,15.87 20.69,17.05 20.16,17.8C19.84,18.25 19.5,18.67 19.08,19.07C15.17,23 8.84,23 4.94,19.07C1.03,15.17 1.03,8.83 4.94,4.93C5.34,4.53 5.76,4.17 6.21,3.85C6.96,3.32 8.14,4.21 8.06,5.04C7.79,7.9 8.75,10.87 10.95,13.06C13.14,15.26 16.1,16.22 18.97,15.95M17.33,17.97C14.5,17.81 11.7,16.64 9.53,14.5C7.36,12.31 6.2,9.5 6.04,6.68C3.23,9.82 3.34,14.64 6.35,17.66C9.37,20.67 14.19,20.78 17.33,17.97Z"/>
                                        </svg>
                                        {t("Dark")}
                                    </button>
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                {({ active }) => (
                                    <button
                                        onClick={switchToSystemMode}
                                        className={classNames(
                                            active ? 'bg-gray-100 text-gray-900 dark:bg-slate-500 themeSwitch active' : 'text-gray-700',
                                            'group flex items-center px-4 py-2 text-sm dark:text-white w-full'
                                        )}
                                    >
                                        <svg className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 fill-gray-400 themeSwitchIcon"  viewBox="0 0 24 24">
                                            <path
                                                  d="M12 2A10 10 0 0 0 2 12A10 10 0 0 0 12 22A10 10 0 0 0 22 12A10 10 0 0 0 12 2M12 4A8 8 0 0 1 20 12A8 8 0 0 1 12 20V4Z"/>
                                        </svg>
                                        {t("Automatic")}
                                    </button>
                                )}
                            </Menu.Item>
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
        </ >
    );
};

export default ThemeSwitch;