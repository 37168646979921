import SignupForm from "../../general/SignUpForm";

interface Props {
}

const Signup: React.FC<Props> = () => {
    return (
        <div className="App">
            <SignupForm />
        </div>
    );
};

export default Signup


