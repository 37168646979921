import React, {useEffect, useState} from 'react'
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {ClassesFormLabel, ClassesFormInput, ClassesDeleteBtn} from "../../../../utils/StylingClasses";
import {ActionMeta, MultiValue} from "react-select"
import CreatableSelect from "react-select/creatable";
import {
    useDeleteBookmarkSoftMutation,
    useGetAllTagsQuery,
    useGetBookmarkQuery, useGetLatestBookmarksLazyQuery,
    useUpdateBookmarkMutation
} from "../../../../generated/graphql/graph";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import ROUTE, {getRoute} from "../../../../config/routes";
import SubmitBtn from "../../Buttons/SubmitBtn";
import CancelBtn from "../../Buttons/CancelBtn";
import {useTranslation} from "react-i18next";
import ActionBtn from "../../Buttons/ActionBtn";
import ConfirmModal from "../../Modals/ConfirmModal";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../redux/rootState";
import {toggleConfirmModal} from "../../../../redux/slices/sliceUI";
import {setLatestUpdated} from "../../../../redux/slices/sliceLatestBookmarks";
import BookmarkScreenshotImage from "../BookmarkScreenshotImage";
import {ITag} from "../../../../redux/models/ITag";
import CheckBoxComponent from "../../Form/CheckBoxComponent";


interface Props {
}

const validationSchema = Yup.object().shape({

});


interface Option {
    value: string;
    label: string;
}

interface InitialValues {
    title: string;
    text: string;
    url: string;
    id: string;
}


const BookmarkEdit = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {id} = useParams();
    const [tagsLoaded, setTagsLoaded] = useState<boolean>(false);
    const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);
    const [options, setOptions] = useState<Option[]>([]);
    const [title, setTitle] = useState('')
    const [text, setText] = useState('')
    const [url, setUrl] = useState('')
    const {data: allTags, refetch: refetchTags} = useGetAllTagsQuery({variables: {order: "path"}})
    const {data: contentData, error: contentError, loading: contentLoading} = useGetBookmarkQuery({variables: {id: id!}})
    const [updateBookmarkMutation, {loading: updateLoading, error: updateError, data: updateData}] = useUpdateBookmarkMutation()
    const [deleteBookmarkMutation, {loading: loadingDelete, error: loadingError, data: loadingData}] = useDeleteBookmarkSoftMutation()
    const showConfirmModal = useSelector((state: RootState) => state.ui.confirmModal)
    const [favorite, setFavorite] = useState(false)
    const dispatch = useDispatch()

    let initialValues: InitialValues = {
        title: title,
        text: text,
        url: url,
        id: id!,
    }

    // const { data, error } = useGetMeQuery()

    useEffect(() => {
        refetchTags()
        if (allTags && allTags.GetAllTags) {
            const tags: ITag[] = []
            allTags.GetAllTags.map(tag => {
                tags.push({value: tag.value, label: tag.path})
            })
            setOptions(tags)
            setTagsLoaded(true)
        }

        if (contentData && contentData.GetBookmark) {
            setTitle(contentData.GetBookmark.title)
            setText(contentData.GetBookmark.text)
            setUrl(contentData.GetBookmark.url)
            setFavorite(contentData.GetBookmark.favorite)
            if (contentData.GetBookmark.tags.length) {
                const tags: ITag[] = []
                contentData.GetBookmark.tags.map(tag => {
                    tags.push({value: tag.value, label: tag.path})
                })
                setSelectedOptions(tags)
            }
        }

    }, [allTags, contentData])

    const handleChange = (newValue: MultiValue<Option>, actionMeta: ActionMeta<Option>) => {
        const newOptions: Option[] = []
        for (const newOption of newValue) {
            newOptions.push({label: newOption.label, value: newOption.value})
        }
        setSelectedOptions(newOptions)

    };

    const favoriteSwitch = (value: boolean) => {
        setFavorite(value)
    }

    const handleSubmit = async (values: InitialValues) => {
        updateBookmarkMutation({variables:   {
            id: values.id,
            url: values.url,
            text: values.text,
            title: values.title,
            favorite: favorite,
            tags: selectedOptions,
        }})
            .then((result) => {
                dispatch(setLatestUpdated(true))
                navigate(getRoute(ROUTE.HOME))
            })
            .catch(console.log)
    }

    const handleDeleteClick = () => {
        dispatch(toggleConfirmModal())
        if (contentData && contentData.GetBookmark && contentData.GetBookmark.id) {
            deleteBookmarkMutation({variables: {id: contentData.GetBookmark.id}})
                .then((result) => {
                    dispatch(setLatestUpdated(true))
                    navigate(ROUTE.HOME)
                })
                .catch(console.log)
        }
    }

    const toggleModal = () => {
        dispatch(toggleConfirmModal())
    }

    if (contentLoading) return (
        <div className="mx-auto max-w-3xl">
            <div className="mx-auto max-w-7xl px-4 pt-2 pb-2 sm:px-6 sm:pt-16 lg:px-8">
                <div className="border-b border-gray-900/10 pb-8 dark:border-slate-400">
                    <h2 className="text-base font-semibold leading-7 text-gray-900 mb-3">{t("Edit Bookmark")}</h2>
                    <div className="mb-3">
                        <label htmlFor="email" className={ClassesFormLabel}>
                            {t("Title")}
                        </label>
                        <div className="mt-2">
                            <div className="bg-gray-200 border border-gray-200 w-full h-10 animate-pulse rounded-md"></div>
                        </div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="email" className={ClassesFormLabel}>
                            {t("Description")}
                        </label>
                        <div className="mt-2">
                            <div className="bg-gray-200 border border-gray-200 w-full h-10 animate-pulse rounded-md"></div>
                        </div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="email" className={ClassesFormLabel}>
                            {t("URL")}
                        </label>
                        <div className="mt-2">
                            <div className="bg-gray-200 border border-gray-200 w-full h-10 animate-pulse rounded-md"></div>
                        </div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="email" className={ClassesFormLabel}>
                            {t("Tags")}
                        </label>
                        <p className="bg-red-400 border-red-800"></p>
                        <div className="mt-2 focus:outline-none">
                            <div className="bg-gray-200 border border-gray-200 w-full h-10 animate-pulse rounded-md"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

    if (contentError) {
        return (
            <div className="bg-white shadow sm:rounded-lg dark:bg-slate-700">
                <div className="px-4 py-2 sm:p-6">
                    <h3 className="text-base font-semibold leading-6 text-gray-900 dark:text-white">{t("Issue loading the requested bookmark")}</h3>
                    <div className="mt-2 sm:flex sm:items-start sm:justify-between">
                        <div className="max-w-xl text-sm text-gray-500 dark:text-gray-300">
                            <p>
                                {t("Sorry, we couldn't load your bookmark right now due to an error. We apologize for the inconvenience and suggest that you try again later. If the problem persists, please contact our support team for assistance.")}
                                <span className="font-bold py-3 block">{t("Error")}: {contentError.message}</span>
                            </p>
                        </div>
                        <div className="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-center">
                            <NavLink
                                to={ROUTE.HOME}
                                className="inline-flex items-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
                            >
                                {t("Continue")}
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            {showConfirmModal && <ConfirmModal title={t("Are you sure you want to delete this bookmark?")} content={t("Are you sure you want to delete this bookmark. It will be placed in the trash if you want to recover it.")} btnLabel={t("Yes, I'm sure!")} onClickedYes={handleDeleteClick}  onClickedNo={toggleModal}/>}
            <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema} enableReinitialize={true}>
                {({errors, touched}) => (
                    <Form>
                        <div className="mx-auto max-w-3xl">
                            <div className="mx-auto max-w-7xl px-4 py-5 sm:px-6 sm:pt-28 lg:px-8">
                                <div className="border-b border-gray-900/10 pb-8 dark:border-slate-400">
                                    <h2 className="text-base font-semibold leading-7 text-gray-900 mb-3">{t("Edit Bookmark")}</h2>
                                    <div className="mb-2 max-w-xs">
                                        <BookmarkScreenshotImage screenshot={contentData?.GetBookmark?.screenshot!} />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="email" className={ClassesFormLabel}>
                                            {t("Title")}
                                        </label>
                                        <div className="mt-2">
                                            <Field
                                                type="text"
                                                name="title"
                                                id="title"
                                                className={ClassesFormInput} />
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="email" className={ClassesFormLabel}>
                                            {t("Description")}
                                        </label>
                                        <div className="mt-2">
                                            <Field
                                                type="text"
                                                name="text"
                                                id="text"
                                                className={ClassesFormInput} />
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="email" className={ClassesFormLabel}>
                                            {t("URL")}
                                        </label>
                                        <div className="mt-2">
                                            <Field
                                                type="url"
                                                name="url"
                                                id="url"
                                                className={ClassesFormInput} />
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="email" className={ClassesFormLabel}>
                                            {t("Tags")}
                                        </label>
                                        <p className="bg-red-400 border-red-800"></p>
                                        <div className="mt-2 focus:outline-none">
                                            {tagsLoaded ?
                                                <CreatableSelect
                                                    className="react-select-container"
                                                    classNamePrefix="react-select"
                                                    isMulti={true}
                                                    isClearable={true}
                                                    // loadOptions={loadOptions}
                                                    closeMenuOnSelect={false}
                                                    options={options}
                                                    onChange={handleChange}
                                                    value={selectedOptions}
                                                /> : '' }
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="email" className={ClassesFormLabel}>
                                            {t("Favorite this bookmark")}
                                        </label>
                                        <p className="bg-red-400 border-red-800"></p>
                                        <div className="mt-2 focus:outline-none">
                                            {contentData?.GetBookmark && <CheckBoxComponent initialValue={contentData?.GetBookmark.favorite!} onChange={favoriteSwitch} icon="heart"/> }
                                        </div>
                                    </div>
                                </div>
                                <Field
                                    type="hidden"
                                    name="id"
                                    id="id"
                                    />
                            </div>
                            <div className="flex justify-between mt-3">
                                <div className="flex items-center justify-normal gap-x-6">
                                    <ActionBtn onClick={toggleModal} className={ClassesDeleteBtn}>
                                        {t("Delete")}
                                    </ActionBtn>
                                </div>
                                <div className="flex items-center justify-end gap-x-6">
                                    <CancelBtn to="/" />
                                    <SubmitBtn loading={updateLoading} error={updateError} done={updateData} />
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default BookmarkEdit;
