import {useDispatch, useSelector} from "react-redux";
import {setPageSize, setTheme, setLanguage, setAutoPurgeTrash} from '../../redux/slices/sliceUserSettings'
import {useGetMeQuery, useUpdateSettingsMutation} from "../../generated/graphql/graph";
import {RootState} from "../../redux/rootState";
import {useEffect} from "react";
import i18next from "i18next";


export const THEME_DARK = 'dark'
export const THEME_LIGHT = 'light'
export const THEME_SYSTEM = 'system'
const useUserSettings= () => {

    const dispatch = useDispatch();
    const {data: meData, error: meError}= useGetMeQuery()
    const [updateSettingsMutation, {loading, data, error}] = useUpdateSettingsMutation()
    const theme = useSelector((state: RootState) => state.userSettings.theme)
    const pageSize = useSelector((state: RootState) => state.userSettings.pageSize)
    const language = useSelector((state: RootState) => state.userSettings.language)
    const autoPurgeTrash = useSelector((state: RootState) => state.userSettings.autoPurgeTrash)

    useEffect(() => {
        if (meData && meData.GetMe) {
            const settings = meData.GetMe.settings
            dispatch(setLanguage(settings.language))
            dispatch(setPageSize(settings.pageSize))
            dispatch(setTheme(settings.theme))
            dispatch(setAutoPurgeTrash(settings.autoPurgeTrash))
        }
    }, [meError, meData])



    const SetThemeDark = () => {
        localStorage.setItem('theme', THEME_DARK)
        dispatch(setTheme(THEME_DARK))
        updateSettingsMutation({variables: {theme: THEME_DARK}}).catch(console.log)
    }

    const SetThemeLight = () => {
        localStorage.setItem('theme', THEME_LIGHT)
        dispatch(setTheme(THEME_LIGHT))
        updateSettingsMutation({variables: {theme: THEME_LIGHT}})
    }

    const SetTheme = (theme: string) => {
        switch (theme) {
            case THEME_LIGHT:
                SetThemeLight()
                break
            case THEME_DARK:
                SetThemeDark()
                break
            default:
                SetThemeSystem()
        }
    }

    const SetThemeSystem = () => {
        localStorage.removeItem('theme')
        dispatch(setTheme(THEME_SYSTEM))
        updateSettingsMutation({variables: {theme: ''}})
    }

    const SetPageSize = (size: number) => {
        dispatch(setPageSize(size))
        updateSettingsMutation({variables: {pageSize: size}})
    }

    const SetAutoPurgeTrash = (days: number) => {
        dispatch(setAutoPurgeTrash(days))
        updateSettingsMutation({variables: {autoPurgeTrash: days}})
    }

    const SetLanguage = (lng : string) => {
        updateSettingsMutation({variables: {language: lng}})
        if (lng) {
            i18next.changeLanguage(lng)
        } else {
            const detectedLanguage = i18next.services.languageDetector.detect();
            i18next.changeLanguage(detectedLanguage)
        }
    }

    return {SetThemeDark, SetThemeLight, SetThemeSystem, SetPageSize, SetLanguage, SetTheme, SetAutoPurgeTrash,
            theme, pageSize, language, autoPurgeTrash}
}

export default useUserSettings;