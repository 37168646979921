import React from 'react';
import MainMultiColumnContainer from "../../layout/MainMultiColumnContainer";
import BookmarkTrashComponent from "../../general/Bookmark/BookmarkTrash";


interface Props {
}



const BookmarksFavoritesPage: React.FC<Props> = () => {
    return (
        <div className="App">
            <MainMultiColumnContainer>
                <BookmarkTrashComponent />
            </MainMultiColumnContainer>
        </div>
    );
};

export default BookmarksFavoritesPage


