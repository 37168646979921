import React, {useEffect, useRef, useState} from 'react'
import {

    ClassesFormLabel, ClassesFormInput, ClassesFormDatePicker
} from "../../../../utils/StylingClasses";
import {
    useAddApiKeyMutation,
    useGetApiKeyQuery,
    useGetApiKeysQuery,
    useUpdateApiKeyMutation
} from "../../../../generated/graphql/graph";
import {Field, Form, Formik} from "formik";
import CancelBtn from "../../Buttons/CancelBtn";
import SubmitBtn from "../../Buttons/SubmitBtn";
import * as Yup from "yup";
import {date, string} from "yup";
import Datepicker from "react-tailwindcss-datepicker";
import moment from "moment";
import {useSelector} from "react-redux";
import {RootState} from "../../../../redux/rootState";
import {t} from "i18next";
import ROUTE from "../../../../config/routes";
import {useNavigate, useParams} from "react-router-dom";

interface Props {
}

interface InitialValues {
    name: string;
    id: string;
    origins: string;
}

const validationSchema = Yup.object().shape({
    name: string().required().min(2)
});


const EditApiKeysComponent = () => {
    const {id: idParam} = useParams();
    const {data: keyData, error: keyError, loading: keyLoading} = useGetApiKeyQuery({variables: {id: idParam!}})
    const [updateApiMutation, {data, loading, error}]= useUpdateApiKeyMutation()
    const keyInputRef = useRef<HTMLInputElement>(null);
    const [copyState, setCopyState] = useState('')
    const [isCopied, setIsCopied] = useState(false)
    const [dateValue, setDateValue] = useState({
        startDate: null,
        endDate: null
    });
    const navigate = useNavigate()
    const [name, setName] = useState('')
    const [origin, setOrigin] = useState('')
    const [id, setId] = useState('')


    let initialValues: InitialValues = {
        name: name,
        id: id,
        origins: origin,
    }
    const handleSubmit = (values: InitialValues) => {
        let expiresAt: string|null = null;
        if (dateValue.startDate) {
            const momentDate = moment.utc(dateValue.startDate);
            expiresAt = momentDate.format('YYYY-MM-DD');
        }

        updateApiMutation({variables: {id: values.id, name: values.name, expiresAt: expiresAt, allowedOrigins: values.origins}})
            .then(() => {
                navigate(ROUTE.SETTINGS, {state: {refresh: true}})
            })
            .catch(console.log)
    }
    const handleDateValueChange = (newValue: any) => {
        setDateValue(newValue)
    }

    const handleCopy = () => {
        setIsCopied(true)
        navigator.clipboard.writeText(copyState)
            .then(() => console.log(`Copied to clipboard: ${copyState}`))
            .catch((err) => console.error('Failed to copy text: ', err));
        setTimeout(() => {
            setIsCopied(false)
        }, 2000)
    }

    useEffect(() => {
        if (keyData && keyData.GetAPIKey) {
            setId(keyData.GetAPIKey.id!)
            setName(keyData.GetAPIKey.name!)
            setOrigin(keyData.GetAPIKey.allowedOrigins || '')
            if (keyData.GetAPIKey.expiresAt) {
                // @ts-ignore
                setDateValue({startDate: new Date(keyData.GetAPIKey.expiresAt), endDate: new Date(keyData.GetAPIKey.expiresAt)})
            }
        }
    }, [keyData])

    return (
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema} enableReinitialize={true}>
                {({errors, touched}) => (
                    <Form>
                        <div className="mx-auto max-w-3xl">
                            <div className="mx-auto max-w-7xl px-4 py-5 sm:px-6 sm:pt-32 lg:px-8">
                                <div className="border-b border-gray-900/10 pb-12 dark:border-slate-400">
                                    <h2 className="text-base font-semibold leading-7 text-gray-900 mb-3">{t("Edit API Key")}</h2>
                                    <div className="mb-3">
                                        <label htmlFor="email" className={ClassesFormLabel}>
                                            {t("Name")}
                                        </label>
                                        <div className="mt-2">
                                            <Field
                                                type="text"
                                                name="name"
                                                id="name"
                                                className={ClassesFormInput} />
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="email" className={ClassesFormLabel}>
                                            {t("Expiration date")}
                                        </label>
                                        <div className="mt-2">
                                            <Datepicker
                                                asSingle={true}
                                                inputClassName={ClassesFormDatePicker}
                                                useRange={false}
                                                startWeekOn="mon"
                                                value={dateValue}
                                                onChange={handleDateValueChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="email" className={ClassesFormLabel}>
                                            {t("Allow Origins. (IP's/IP ranges comma seperated)")}
                                        </label>
                                        <div className="mt-2">
                                            <Field
                                                type="text"
                                                name="origins"
                                                id="origins"
                                                className={ClassesFormInput} />
                                        </div>
                                    </div>
                                </div>
                                <Field
                                    type="hidden"
                                    name="id"
                                    id="id"
                                />
                                <Field
                                    type="hidden"
                                    name="expiredAt"
                                    id="expiredAt"
                                />
                            </div>
                            <div className="mt-6 flex items-center justify-end gap-x-6">
                                <CancelBtn to={ROUTE.SETTINGS} />
                                <SubmitBtn loading={loading} error={error} done={data} />
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default EditApiKeysComponent;
