import React, {MouseEventHandler} from 'react'
import {NavLink} from "react-router-dom";
import {ClassesCancelBtn} from "../../../../utils/StylingClasses";
import {useTranslation} from "react-i18next";

interface Props {
    label?: string
    to: string
    onClick?: MouseEventHandler<HTMLAnchorElement|HTMLButtonElement> | undefined
    className?: string
}
const SubmitBtn = ({label, to, onClick, className} : Props) => {
    const {t} = useTranslation()
    return (
        <NavLink to={to} onClick={onClick} className={className ? className : ClassesCancelBtn}>
            {label? label: t("Cancel")}
        </NavLink>
    )
}

export default SubmitBtn;
