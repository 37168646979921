import React from 'react'
import {
    MagnifyingGlassIcon,
} from '@heroicons/react/24/outline'
import {NavLink} from "react-router-dom";
import {useDispatch} from "react-redux";
import {openSidebar, toggleSearchModal} from "../../../../redux/slices/sliceUI";
import {useTranslation} from "react-i18next";

interface Props {
}

const OpenBookmarkModalBtn = ({} : Props) => {
    const {t} = useTranslation()
    const dispatch = useDispatch();

    const addBookmarkClick = () => {
        dispatch(openSidebar(false))
        dispatch(toggleSearchModal())
    }
    return (
        <div>
            <NavLink to="" onClick={addBookmarkClick} title={t("Add new bookmark").toString()}>
                <MagnifyingGlassIcon className="w-5 h-w text-gray-400" />
            </NavLink>
        </div>
    )
}

export default OpenBookmarkModalBtn;
