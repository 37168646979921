import BookmarkCard from "../BookmarkCard";
import {
    useEmptyTrashBookmarkMutation,
    useGetAllBookmarksQuery, useGetInboxBookmarksQuery, useGetTrashBookmarksQuery,
} from "../../../../generated/graphql/graph";
import React, {useRef, useState} from "react";
import {ArrowPathIcon} from '@heroicons/react/20/solid'
import ActionBtn from "../../Buttons/ActionBtn";
import {classNames} from "../../../../utils/classNames";
import {ClassesSubmitBtn} from "../../../../utils/StylingClasses";
import {useLocation} from "react-router-dom";
import userSettings from "../../../../hooks/UserSettings";
import PaginationComponent from "../../PaginationComponent";
import {GetPageNumber} from "../../../../utils/parseInt";
import BreadcrumbComponent from "../../BreadcrumbComponent";
import ROUTE, {getRoute} from "../../../../config/routes";
import {t} from "i18next";
import {InboxIcon, TrashIcon} from "@heroicons/react/24/outline";
import ConfirmModal from "../../Modals/ConfirmModal";
import {toggleConfirmModal} from "../../../../redux/slices/sliceUI";
import {setLatestUpdated} from "../../../../redux/slices/sliceLatestBookmarks";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../redux/rootState";

const BookmarkTrashComponent = () => {
    const location = useLocation();
    const dispatch = useDispatch()
    const searchParams = new URLSearchParams(location.search);
    const page = GetPageNumber(searchParams.get('page'))
    const {pageSize} = userSettings()
    const {data, loading, error, refetch} = useGetTrashBookmarksQuery({variables: {page: page}})
    const refreshBtnRef = useRef<SVGSVGElement>(null)
    const deletehBtnRef = useRef<SVGSVGElement>(null)
    const [isAnimatingRefresh, setIsAnimatingRefresh] = useState(false);
    const [isAnimatingEmpty, setIsAnimatingEmpty] = useState(false);
    const showConfirmModal = useSelector((state: RootState) => state.ui.confirmModal)
    const [emptyTrash] = useEmptyTrashBookmarkMutation()

    const clickEmpty = () => {
        if (data!.GetTrashBookmarks.total !== 0) {
            dispatch(toggleConfirmModal())
        }

    }

    const handleDeleteClick = () => {
        dispatch(toggleConfirmModal())
        setIsAnimatingEmpty(true)
        emptyTrash()
            .then(() => {
                setTimeout(() => {
                    setIsAnimatingEmpty(false)
                }, 1000)
                refetch()
            })
            .catch(console.log)
    }

    const toggleModal = () => {
        dispatch(toggleConfirmModal())
    }
    const clickRefresh = () => {
        refetch()
        setIsAnimatingRefresh(true)
        setTimeout(() => {
            setIsAnimatingRefresh(false)
        }, 1000)
    }

    if (loading) {
        return (
            <p>Loading</p>
        )
    }

    if (error) {
        return (
            <p>Error</p>
        )
    }

    return (
        <div className="pt-10 mb-60">
            {showConfirmModal && <ConfirmModal title={t("Are you sure you want to delete this bookmark?")} content={t("Are you sure you want to delete this bookmark. It will be placed in the trash if you want to recover it.")} btnLabel={t("Yes, I'm sure!")} onClickedYes={handleDeleteClick}  onClickedNo={toggleModal}/>}
            <div className="hidden max-md:flex justify-end mb-2 space-x-2">
                <ActionBtn onClick={clickEmpty} className={classNames(ClassesSubmitBtn, "group  inline-block")} aria-label="Empty trashcan">
                    <TrashIcon className={classNames("w-5 h-5 ", (isAnimatingEmpty ? "animate-wiggle" : ""))} ref={deletehBtnRef}/>
                </ActionBtn>
                <ActionBtn onClick={clickRefresh} className={classNames(ClassesSubmitBtn, "group  inline-block")} aria-label="Refresh page">
                    <ArrowPathIcon className={classNames("w-5 h-5 ", (isAnimatingRefresh ? "animate-spin" : ""))} ref={refreshBtnRef}/>
                </ActionBtn>
            </div>
            <div className="flex">
                <BreadcrumbComponent path={[{name: t("Trash"), href: getRoute(ROUTE.TRASH), current: true, icon: TrashIcon}]}/>
                <div className="w-full justify-end flex-1 space-x-2 text-right max-md:hidden">
                    <ActionBtn onClick={clickEmpty} className={classNames(ClassesSubmitBtn, "group  inline-block", (data!.GetTrashBookmarks.total === 0 ? ' opacity-50' : ''))} aria-label="Empty trashcan">
                        <TrashIcon className={classNames("w-5 h-5 ", (isAnimatingEmpty ? "animate-wiggle" : ""))} ref={deletehBtnRef}/>
                    </ActionBtn>
                    <ActionBtn onClick={clickRefresh} className={classNames(ClassesSubmitBtn, "group  inline-block")} aria-label="Refresh page">
                        <ArrowPathIcon className={classNames("w-5 h-5 ", (isAnimatingRefresh ? "animate-spin" : ""))} ref={refreshBtnRef}/>
                    </ActionBtn>
                </div>
            </div>
            <div className="">
                <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-8 pt-10 pb-10">
                    {data?.GetTrashBookmarks.bookmarks.map((content) => (
                        <BookmarkCard title={content.title} read={false} screenshot={content.screenshot!} text={content.text} url={content.url} tags={content.tags} contentID={content.id} key={content.id}/>
                    ))}
                </div>
                <div className="pt-5">
                    <PaginationComponent total={data!.GetTrashBookmarks.total} pageSize={pageSize} page={page} />
                </div>
            </div>
        </div>
    )
}

export default BookmarkTrashComponent;