import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import theme from "tailwindcss/defaultTheme";

interface userSettingsState {
    theme: string;
    language: string;
    pageSize: number;
    autoPurgeTrash: number;
}

const initialState: userSettingsState = {
    theme: '',
    language: '',
    pageSize: 9,
    autoPurgeTrash: 31,
};

const userSettingsSlice = createSlice({
    name: "userSettings",
    initialState,
    reducers: {
        setTheme(state,  action: PayloadAction<string>) {
            state.theme = action.payload;
        },
        setLanguage(state,  action: PayloadAction<string>) {
            state.language = action.payload;
        },
        setPageSize(state,  action: PayloadAction<number>) {
            state.pageSize = action.payload;
        },
        setAutoPurgeTrash(state,  action: PayloadAction<number>) {
            state.autoPurgeTrash = action.payload;
        }
    },
});

export const    {
                    setTheme,
                    setLanguage,
                    setPageSize,
                    setAutoPurgeTrash,
                } = userSettingsSlice.actions;

export default userSettingsSlice.reducer;