import React, {useCallback} from 'react'
import {useDropzone} from "react-dropzone";
import {t} from "i18next";
import axiosClient from "../../../../config/axios.config";
import {useNavigate} from "react-router-dom";
import ROUTE, {getRoute} from "../../../../config/routes";


interface Props {
}
const BookmarkImportComponent = ({} : Props) => {

    const navigate = useNavigate()

    const upload = async (file: File) => {
        const formData = new FormData()
        formData.append('file', file)
        const restClient = await axiosClient()
        restClient.post('/api/v1/import/bookmarks', formData)
            .then((resp) => {
                console.log(resp.data)
                navigate(getRoute(ROUTE.TAG, {params: [{key: "tagID", value: resp.data.tag},{key: "tagName", value: resp.data.name}]}))
            })
            .catch(console.log)

    }
    const onDrop = useCallback((acceptedFiles: File[]) => {
        upload(acceptedFiles[0])
        // console.log(acceptedFiles)
        // onFileAdded(acceptedFiles[0])
    }, [])

    const {getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
        accept: {
            'text/html': [],
        },
        maxFiles: 1,
        maxSize: 1024 * 1024 * 20,
        onDrop: onDrop,
    });
    return (
        <div className="mx-auto max-w-3xl">
            <div className="mx-auto max-w-7xl px-4 pt-2 pb-2 sm:px-6 sm:pt-16 lg:px-8">
                <div
                    {...getRootProps()}
                    className={`mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10 dark:border-gray-400 ${isDragActive ? "drop-active" : ""}`}>
                    <div className="text-center">
                        <svg className="mx-auto h-12 w-12 text-gray-300" viewBox="0 0 24 24"
                             fill="currentColor" aria-hidden="true">
                            <path fillRule="evenodd"
                                  d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z"
                                  clipRule="evenodd"></path>
                        </svg>
                        <div className="mt-4 flex text-sm leading-6 text-gray-600">
                            <label htmlFor="file-upload"
                                   className="relative cursor-pointer rounded-md bg-white font-semibold text-blue-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-600 focus-within:ring-offset-2 hover:text-blue-500  dark:bg-slate-900 dark:text-white">
                                <span className="dark:text-white">{t("Upload a file")}</span>
                                <input {...getInputProps()} />
                            </label>
                            <p className="pl-1 dark:text-white">{t("or drag and drop")}</p>
                        </div>
                        {!isDragActive && (<p className="text-xs leading-5 text-gray-600 dark:text-white">{t("HTML file (max 20MB)")}</p>)}
                        {isDragActive && (<p className="text-xs leading-5 text-gray-600 dark:text-white">{t("Drop some bookmark file here...")}</p>)}
                        {isDragReject && (<p className="text-xs leading-5 text-gray-600 dark:text-white">{t("Only HTML files ...")}</p>)}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BookmarkImportComponent;
