import React, {useEffect, useRef, useState} from 'react'
import {IScreenshot} from "../../../../config/interfaces/tags";
import {useGetScreenshotStatusLazyQuery} from "../../../../generated/graphql/graph";
import {Simulate} from "react-dom/test-utils";
import load = Simulate.load;


export const STATUS_REQUESTED = 1;
export const STATUS_PENDING = 2;
export const STATUS_DONE = 3;
export const STATUS_ERROR = 4;

interface Props {
    screenshot: IScreenshot;
}
const BookmarkScreenshotImageComponent = ({screenshot} : Props) => {

    const loadImage = new Image();
    const domImage = useRef<HTMLImageElement>(null)
    const [screenshotQuery, {refetch}] = useGetScreenshotStatusLazyQuery()
    const [imageState, setImageState] = useState<number>(screenshot.status)
    const [breakerCount, setBreakerCount] = useState(0)

    loadImage.onload = () => {
        if (domImage.current) {
            domImage.current.src = loadImage.src;
        }

    }

    const pollImage = () => {
        refetch({id: screenshot.id})
            .then((qd) => {
                if(qd && qd.data && qd.data.GetScreenshotStatus) {
                    switch (qd.data.GetScreenshotStatus.status) {
                        case STATUS_DONE:
                            setImageState(STATUS_DONE)
                            loadImage.src = `${screenshot.path}/screenshot.640.png`
                            break;
                        case STATUS_ERROR:
                            setImageState(STATUS_ERROR)
                            break;
                        case STATUS_REQUESTED:
                        case STATUS_PENDING:
                            setBreakerCount(breakerCount + 1)
                            // if (breakerCount < 2) {
                            //     setTimeout(() => {pollImage()}, 10000)
                            // }
                            break;
                    }
                }
            })
            .catch(() => {
                setImageState(STATUS_ERROR)
            })
    }

    useEffect(() => {
        // if (imageState === STATUS_PENDING || imageState === STATUS_REQUESTED) {
        //     setTimeout(pollImage, 5000)
        // }
    }, [screenshot, imageState])

    return (
        <>
            {imageState === STATUS_DONE &&
                  <img
                    src={`${screenshot.path}/screenshot.640.png`}
                    alt="Screenshot of website" className="rounded"
                    ref={domImage}/>

            }
            {(imageState !== STATUS_DONE) &&
                <div className="bg-gray-200 border border-gray-200 pb-[62.5%] animate-pulse rounded-md" ></div>
            }
        </>
    )
}

export default BookmarkScreenshotImageComponent;
