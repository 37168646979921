import React from 'react';
import MainMultiColumnContainer from "../../layout/MainMultiColumnContainer";
import BookmarkEdit from "../../general/Bookmark/BookmarkEdit";

interface Props {
}



const BookMarkEditPage: React.FC<Props> = () => {

    return (
        <div className="App">
            <MainMultiColumnContainer>
                <BookmarkEdit />
            </MainMultiColumnContainer>
        </div>
    );
};

export default BookMarkEditPage


