import BookmarkCard from "../BookmarkCard";
import {
    useGetBookmarksByTagNameQuery,
    useGetBookmarksByTagQuery,
    useGetLatestBookmarksQuery, useGetTagByIdQuery, useGetTagByIdWithParentQuery
} from "../../../../generated/graphql/graph";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../redux/rootState";
import {useEffect, useRef, useState} from "react";
import {ArrowPathIcon, PencilSquareIcon} from '@heroicons/react/20/solid'
import ActionBtn from "../../Buttons/ActionBtn";
import {classNames} from "../../../../utils/classNames";
import {ClassesH1, ClassesP, ClassesSubmitBtn} from "../../../../utils/StylingClasses";
import {useLocation, useParams} from "react-router-dom";
import userSettings from "../../../../hooks/UserSettings";
import PaginationComponent from "../../PaginationComponent";
import {GetPageNumber} from "../../../../utils/parseInt";
import BreadcrumbComponent from "../../BreadcrumbComponent";
import {IBreadCrump} from "../../../../redux/models/IBreadCrump";
import {FormatTagLink} from "../../../../utils/Tags";

interface Props {

}

const pages = [
    { name: 'Projects', href: '#', current: false },
    { name: 'Project Nero', href: '#', current: true },
]

const BookmarkByTagComponent = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const page = GetPageNumber(searchParams.get('page'))
    const {tagID} = useParams();
    const {pageSize} = userSettings()
    const {data: tagData} = useGetTagByIdWithParentQuery({variables: {ID: tagID!}})
    const {data, loading, error, refetch} = useGetBookmarksByTagQuery({variables: {id: tagID!, limit: pageSize, page: page}})
    const refreshBtnRef = useRef<SVGSVGElement>(null)
    const [breadCrumb, setBreadCrumb ] = useState<IBreadCrump[]>([])
    const [isAnimating, setIsAnimating] = useState(false);

    const clickRefresh = () => {
        refetch({id: tagData?.GetTag!.value})
        setIsAnimating(true)
        setTimeout(() => {
            setIsAnimating(false)
        }, 1000)
    }

    useEffect(() => {
        const crumb: IBreadCrump[] = []
        if (tagData && tagData.GetTag) {
            if (tagData.GetTag.parents) {
                for (const parent of tagData.GetTag.parents) {
                    crumb.push({name: `#${parent.label}`, href: FormatTagLink(parent), current: false})
                }
            }
            crumb.push({name: `#${tagData.GetTag.label}`, href: FormatTagLink(tagData.GetTag), current: true})
        }
        setBreadCrumb(crumb)
    }, [tagData])



    if (loading) {
        return (
            <p>Loading</p>
        )
    }

    if (error) {
        return (
            <p>Error</p>
        )
    }

    return (
        <div className="pt-10 mb-60">
            <div className="hidden max-md:flex justify-end mb-2 space-x-2">
                <ActionBtn to={`/tags/edit/${tagID}`} className={classNames(ClassesSubmitBtn, "group inline-block")} aria-label="Refresh page">
                    <PencilSquareIcon className={classNames("w-5 h-5")} ref={refreshBtnRef}/>
                </ActionBtn>
                <ActionBtn onClick={clickRefresh} className={classNames(ClassesSubmitBtn, "group  inline-block")} aria-label="Refresh page">
                    <ArrowPathIcon className={classNames("w-5 h-5 ", (isAnimating ? "animate-spin" : ""))} ref={refreshBtnRef}/>
                </ActionBtn>
            </div>
            <div className="flex">
                <BreadcrumbComponent path={breadCrumb}/>
                <div className="w-full justify-end flex-1 space-x-2 text-right max-md:hidden">
                    <ActionBtn to={`/tags/edit/${tagID}`} className={classNames(ClassesSubmitBtn, "group inline-block")} aria-label="Refresh page">
                        <PencilSquareIcon className={classNames("w-5 h-5")} ref={refreshBtnRef}/>
                    </ActionBtn>
                    <ActionBtn onClick={clickRefresh} className={classNames(ClassesSubmitBtn, "group  inline-block")} aria-label="Refresh page">
                        <ArrowPathIcon className={classNames("w-5 h-5 ", (isAnimating ? "animate-spin" : ""))} ref={refreshBtnRef}/>
                    </ActionBtn>
                </div>
            </div>
            <div>
                <p className={ClassesP}>{tagData?.GetTag!.description}</p>
            </div>
            <div className="">
                <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-8 pt-10 pb-10">
                    {data?.GetBookmarksByTag.bookmarks.map((content) => (
                        <BookmarkCard title={content.title} read={false} screenshot={content.screenshot!} text={content.text} url={content.url} tags={content.tags} contentID={content.id} key={content.id}/>
                    ))}
                </div>
                <div className="pt-5">
                    <PaginationComponent total={data!.GetBookmarksByTag.total} pageSize={pageSize} page={page}/>
                </div>

            </div>
        </div>
    )
}

export default BookmarkByTagComponent;