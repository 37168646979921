import React, {useEffect} from 'react'
import {classNames} from "../../../../utils/classNames";
import {
    useGetAllTagsCountQuery,
    useGetAllTagsQuery,
    useGetTagsByParentWithCountQuery
} from "../../../../generated/graphql/graph";
import {NavLink, useParams} from "react-router-dom";
import {ClassesP} from "../../../../utils/StylingClasses";
import { useLocation } from 'react-router-dom';
import {useTranslation} from "react-i18next";
import {FormatTagLink} from "../../../../utils/Tags";
import {compareStrings} from "../../../../utils/strings";
import {FolderIcon, FolderOpenIcon} from '@heroicons/react/20/solid'
import MenuTagLinkComponent from "./MenuTagLinkComponent";
import {useSelector} from "react-redux";
import {RootState} from "../../../../redux/rootState";
interface Props {
}

const MenuTagLinks = ({} : Props) => {
    const {tagName, tagID} = useParams();
    const {t} = useTranslation()
    const {data, loading, error, refetch} = useGetTagsByParentWithCountQuery();
    const location = useLocation();
    const isUpdated = useSelector((state: RootState) => state.tags.isUpdated)

    useEffect(() => {
        if (isUpdated){
            refetch()
        }
    }, [refetch, isUpdated])

    if (loading) {
        return (
            <li>
                <div className="text-xs font-semibold leading-6 text-gray-400">{t("Tags")}</div>
                <ul role="list" className="-mx-2 mt-2 space-y-1">
                    <li key="tag.label">

                        <NavLink
                            to={`/tag/tag.label`}
                            className={classNames(
                                    'text-gray-700 hover:text-blue-600 hover:bg-gray-50 hover:dark:bg-slate-400 dark:hover:text-white', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold dark:text-gray-400')}
                        >
                          <span
                              className={classNames('text-gray-400 border-gray-200 group-hover:border-blue-600 group-hover:text-blue-600 dark:border-slate-500 dark:group-hover:border-slate-400 dark:group-hover:text-white dark:text-gray-100',
                                  'flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white dark:bg-slate-600'
                              )}>
                            0
                          </span>
                            <span className="truncate animate-pulse rounded-md"><span className="text-gray-400 dark:text-gray-300 dark:group-hover:text-white">#</span>{t("Loading....")}</span>
                        </NavLink>
                    </li>

                </ul>
            </li>
        )
    }

    if (error) {
        return (
            <li>
                <div className="text-xs font-semibold leading-6 text-gray-400">Tags</div>
                <p className={ClassesP}>Could not load tags. Please reload and try again...</p>
            </li>
        )
    }

    return (
        <li>
            <div className="text-xs font-semibold leading-6 text-gray-400">Tags</div>
            <ul role="list" className="-mx-2 mt-2 space-y-1">
                {data!.GetTagsByParent.map((tag) => (
                    <MenuTagLinkComponent tag={tag} key={tag.label} indent={0}/>
                    // <li key={tag.label}>
                    //     <div className="flex items-center justify">
                    //         <div>
                    //             {tag.hasChildren ?
                    //                 <NavLink to="" className="text-gray-700 hover:text-blue-600 hover:bg-gray-50 dark:text-gray-400 dark:hover:bg-slate-400 hover:dark:text-white group flex rounded-md text-sm leading-6 font-semibold">
                    //                     <FolderIcon
                    //                         className={classNames(
                    //                             false ? 'text-blue-600 dark:text-white dark:group-hover:text-white' : 'text-gray-400 dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-white', 'h-6 w-6 shrink-0'
                    //                         )}
                    //                         aria-hidden="true"
                    //                     />
                    //                 </NavLink>
                    //                 : <div className="w-5 h-5"></div>
                    //             }
                    //         </div>
                    //         <div className="flex-grow">
                    //             <NavLink
                    //                 to={FormatTagLink(tag)}
                    //                 className={classNames(
                    //                     compareStrings(tagID, tag.value)
                    //                         ? 'bg-gray-50 text-blue-600 dark:bg-slate-700'
                    //                         : 'text-gray-700 hover:text-blue-600 hover:bg-gray-50 hover:dark:bg-slate-400 dark:hover:text-white',
                    //                     'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold dark:text-gray-400'
                    //                 )}
                    //             >
                    //               <span
                    //                   className={classNames(
                    //                       compareStrings(tagID, tag.value)
                    //                           ? 'text-blue-600 border-blue-600 dark:border-slate-500 dark:group-hover:border-slate-400 dark:group-hover:text-white dark:text-gray-100'
                    //                           : 'text-gray-400 border-gray-200 group-hover:border-blue-600 group-hover:text-blue-600 dark:border-slate-500 dark:group-hover:border-slate-400 dark:group-hover:text-white dark:text-gray-100',
                    //                       'flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white dark:bg-slate-600'
                    //                   )}
                    //               >
                    //                 {tag.count}
                    //               </span>
                    //                 <span className="truncate"><span className="text-gray-400 dark:text-gray-300 dark:group-hover:text-white">#</span><span dangerouslySetInnerHTML={{__html: tag.label}}></span></span>
                    //             </NavLink>
                    //         </div>
                    //     </div>
                    //     <div>
                    //     </div>
                    // </li>
                ))}
            </ul>
        </li>
    )
}

export default MenuTagLinks;
