import { createSlice, PayloadAction } from "@reduxjs/toolkit";


interface PersonState {
    id: string;
    name: string;
    role: string;
    country: string;
    avatar: string;
    authenticated: boolean;
}

const initialState: PersonState = {
    id: '',
    name: '',
    role: '',
    avatar: '',
    country: '',
    authenticated: false
};

const personSlice = createSlice({
    name: "person",
    initialState,
    reducers: {
        setId(state, action: PayloadAction<string>){
            state.id = action.payload;
        },
        setName(state, action: PayloadAction<string>){
            state.name = action.payload;
        },
        setAvatar(state, action: PayloadAction<string>){
            state.avatar = action.payload;
        },
        setRole(state, action: PayloadAction<string>){
           state.role = action.payload;
        },
        setCountry(state, action: PayloadAction<string>) {
            state.country = action.payload;
        },
        setAuthenticated(state, action: PayloadAction<boolean>) {
            state.authenticated = action.payload
        }
    },
});

export const { setName, setAvatar, setRole, setCountry, setAuthenticated, setId} = personSlice.actions;

export default personSlice.reducer;