import React, {useRef, useState} from 'react'
import {t} from "i18next";
import {ClassesTextBtn} from "../../../../../utils/StylingClasses";
import {NavLink} from "react-router-dom";
import {classNames} from "../../../../../utils/classNames";
import SelectComponent from "../../../Form/SelectComponent";
import {ISelectOption} from "../../../../../redux/models/ISelectOption";
import userSettings from "../../../../../hooks/UserSettings";


interface Props {
}
const PageSizeComponent = () => {
    const editButtons = useRef<HTMLSpanElement>(null)
    const updateBtn = useRef<HTMLAnchorElement>(null)
    const saveBtn = useRef<HTMLAnchorElement>(null)
    const showContainer = useRef<HTMLDivElement>(null)
    const editContainer = useRef<HTMLDivElement>(null)
    const [isEdit, setIsEdit] = useState(false)
    const {pageSize, SetPageSize} = userSettings()
    const [newPageSize, setNewPageSize] = useState(0)

    const options: ISelectOption[] = [
        {label: "03", value: "3"},
        {label: "06", value: "6"},
        {label: "09", value: "9"},
        {label: "12", value: "12"},
        {label: "15", value: "15"},
        {label: "18", value: "18"},
        {label: "21", value: "21"},
        {label: "24", value: "24"},
        {label: "27", value: "27"},
        {label: "30", value: "30"},
    ]

    const valueSelected = (selected: ISelectOption) =>  {
        SetPageSize(parseInt(selected.value as string))
        toggleEditState(null)
    }

    const saveClicked = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault()
        SetPageSize(newPageSize)
        toggleEditState(null)
    }

    const toggleEditState = (e: React.MouseEvent<HTMLAnchorElement|HTMLButtonElement, MouseEvent> | null) => {
        if (e) {
            e.preventDefault()
        }

        if (isEdit) {
            editButtons.current!.classList.add("hidden")
            updateBtn.current!.classList.remove("hidden")
            showContainer.current!.classList.remove("hidden")
            editContainer.current!.classList.add("hidden")
        } else {
            editButtons.current!.classList.remove("hidden")
            updateBtn.current!.classList.add("hidden")
            showContainer.current!.classList.add("hidden")
            editContainer.current!.classList.remove("hidden")
        }
        setIsEdit(!isEdit)
    }

    return (
        <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6 dark:text-gray-300">{t("Page size")}</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto dark:text-gray-300">
                <div className="text-gray-900">
                    <div className="text-gray-900 dark:text-gray-300" ref={showContainer}><button onClick={toggleEditState}>{pageSize}</button></div>
                    <div className="hidden" ref={editContainer}>
                        <SelectComponent label="" options={options} selectedValue={pageSize.toString()} onSelect={valueSelected} />
                    </div>
                </div>
                <span ref={editButtons} className="hidden">
                    <NavLink to="#" onClick={toggleEditState} className={classNames(ClassesTextBtn)}>
                        {t("Cancel")}
                    </NavLink>
                </span>
                <NavLink to="#" className={ClassesTextBtn} onClick={toggleEditState} ref={updateBtn}>
                    {t("Change")}
                </NavLink>
            </dd>
        </div>
    )
}

export default PageSizeComponent;
