import React from 'react';
import MainMultiColumnContainer from "../../layout/MainMultiColumnContainer";
import BookmarkHome from "../../general/Bookmark/BookmarkHome";
import BookmarkTag from "../../general/Bookmark/BookmarkTag";


interface Props {
}



const TagPage: React.FC<Props> = () => {
    return (
        <div className="App">
            <MainMultiColumnContainer>
                <BookmarkTag />
            </MainMultiColumnContainer>
        </div>
    );
};

export default TagPage


