import React, {useEffect, useState} from 'react'
import {
    ClassesH1,
    ClassesH2,
    ClassesP,
    ClassesTableHead,
    ClassesTableThFirst, ClassesTableThLast,
    ClassesTableTh, ClassesTableTd, ClassesTableBody, ClassesTableTdFirst, ClassesA, ClassesTableTdLast
} from "../../../../utils/StylingClasses";
import ActionBtn from "../../Buttons/ActionBtn";
import {classNames} from "../../../../utils/classNames";
import {NavLink, useLocation} from "react-router-dom";
import {useDeleteApiKeyMutation, useGetApiKeysQuery} from "../../../../generated/graphql/graph";
import {format, parse} from 'date-fns';
import ROUTE, {getRoute} from "../../../../config/routes";
import moment from "moment";
import {t} from "i18next";
import ConfirmModal from "../../Modals/ConfirmModal";
import {toggleConfirmModal} from "../../../../redux/slices/sliceUI";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../redux/rootState";
import {IRefreshState} from "../../../../redux/models/IRefreshState";

interface Props {
}

interface APIKey {
    __typename: string;
    id: string;
    name: string;
    createdAt: any;
    expiresAt: any;
}

const SettingsApiKeys = () => {
    const { state } = useLocation();
    const dispatch = useDispatch()
    const {data, loading, error, refetch} = useGetApiKeysQuery()
    const showConfirmModal = useSelector((state: RootState) => state.ui.confirmModal)
    const [deleteId, setDeleteId] = useState('0')
    const [deleteMutation] = useDeleteApiKeyMutation()

    const deleteApiKey = (id: string) => {
        setDeleteId(id)
        toggleModal()
    };

    const formatDate = (dateAny : any): string => {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const date = parse(dateAny.toString(), "yyyy-MM-dd'T'HH:mm:ss'Z'", new Date(), );
        const momentDate = moment(date).locale(navigator.language);
        return  momentDate.format('LLL');;
    }

    const isActiveDate = (dateAny : any): boolean => {
        if (dateAny === null) {
            return true;
        }
        const date = parse(dateAny.toString(), "yyyy-MM-dd'T'HH:mm:ss'Z'", new Date());
        return date > new Date();
    }

    const handleDeleteClick = () => {
        deleteMutation({variables: {id: deleteId}})
            .then(() => {
                dispatch(toggleConfirmModal())
                refetch()
            })
    }

    const toggleModal = () => {
        dispatch(toggleConfirmModal())
    }

    useEffect(() => {
        const st = state as IRefreshState
        if (st && st.refresh) {
            refetch()
        }
    })




    if (error) {
        return (
            <p>Error</p>
        )
    }

    return (
        <div className="pt-10 mb-60">
            {showConfirmModal && <ConfirmModal title={t("Are you sure you want to delete this API Key?")} content={t("Are you sure you want to delete this API key. It cannot be undone. Create a new one if needed.")} btnLabel={t("Yes, I'm sure!")} onClickedYes={handleDeleteClick}  onClickedNo={toggleModal}/>}
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h2 className={ClassesH2}>{t("Api keys")}</h2>
                    <p className={ClassesP}>
                        {t("A list of all the API keys available for your account.")}
                    </p>
                </div>
                <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                    <ActionBtn to={ROUTE.ADD_API_KEY}>
                        {t("Add Key")}
                    </ActionBtn>
                </div>
            </div>
            <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead className={ClassesTableHead}>
                                    <tr>
                                        <th scope="col" className={ClassesTableThFirst}>
                                            {t("Name")}
                                        </th>
                                        <th scope="col" className={classNames(ClassesTableTh, "max-sm:hidden")}>
                                            {t("Created")}
                                        </th>
                                        <th scope="col" className={ClassesTableTh}>
                                            {t("Expires")}
                                        </th>
                                        <th scope="col" className={classNames(ClassesTableTh, "max-sm:hidden")}>
                                            {t("Status")}
                                        </th>
                                        <th scope="col" className={ClassesTableThLast}>
                                            <span className="sr-only">{t("Edit")}</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className={ClassesTableBody} style={{display: !loading ? 'none': ''}}>
                                    <tr>
                                        <td className={ClassesTableTdFirst}>
                                            <div className="bg-gray-200 border border-gray-200 w-full h-10 animate-pulse rounded-md"></div>
                                        </td>
                                        <td className={classNames(ClassesTableTd, "max-sm:hidden")}>
                                            <div className="bg-gray-200 border border-gray-200 w-full h-10 animate-pulse rounded-md"></div>
                                        </td>
                                        <td className={classNames(ClassesTableTd, "max-sm:hidden")}>
                                            <div className="bg-gray-200 border border-gray-200 w-full h-10 animate-pulse rounded-md"></div>
                                        </td>
                                        <td className={ClassesTableTd}>
                                            <div className="bg-gray-200 border border-gray-200 w-full h-10 animate-pulse rounded-md"></div>
                                        </td>
                                        <td className={ClassesTableTdLast}>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className={ClassesTableTdFirst}>
                                            <div className="bg-gray-200 border border-gray-200 w-full h-10 animate-pulse rounded-md"></div>
                                        </td>
                                        <td className={classNames(ClassesTableTd, "max-sm:hidden")}>
                                            <div className="bg-gray-200 border border-gray-200 w-full h-10 animate-pulse rounded-md"></div>
                                        </td>
                                        <td className={classNames(ClassesTableTd, "max-sm:hidden")}>
                                            <div className="bg-gray-200 border border-gray-200 w-full h-10 animate-pulse rounded-md"></div>
                                        </td>
                                        <td className={ClassesTableTd}>
                                            <div className="bg-gray-200 border border-gray-200 w-full h-10 animate-pulse rounded-md"></div>
                                        </td>
                                        <td className={ClassesTableTdLast}>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody className={ClassesTableBody} style={{display: loading ? 'none': ''}}>
                                {data?.GetAPIKeys.map((key) => (
                                    <tr key={key.id}>
                                        <td className={ClassesTableTdFirst}>
                                            {key.name}
                                        </td>
                                        <td className={classNames(ClassesTableTd, "max-sm:hidden")}>
                                            {formatDate(key.createdAt)}
                                        </td>
                                        <td className={ClassesTableTd}>
                                            {key.expiresAt ? formatDate(key.expiresAt) : t('never')}
                                        </td>
                                        <td className={classNames(ClassesTableTd, "max-sm:hidden")}>
                                            {isActiveDate(key.expiresAt) ? <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/10">{t("Active")}</span> :
                                                <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">{t("Not active")}</span>}
                                        </td>
                                        <td className={ClassesTableTdLast}>
                                            <NavLink to={getRoute(ROUTE.EDIT_API_KEY, {params: [{key: "id", "value": key.id}]})} className={ClassesA}>
                                                {t("Edit")} <span className="sr-only">, {key.id}</span>
                                            </NavLink>
                                            |&nbsp;
                                            <NavLink to="#" onClick={() => deleteApiKey(key.id)} className={ClassesA}>
                                                {t("Delete")} <span className="sr-only">, {key.id}</span>
                                            </NavLink>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SettingsApiKeys;
