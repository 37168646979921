import React, {useEffect, useState} from 'react';
import {Formik, Form, Field, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import {NavLink, useNavigate} from "react-router-dom";
import {LockClosedIcon} from "@heroicons/react/20/solid";
import WarningModal from "../Modals/WarningModal";
import {appAuth} from '../../../config/firebase'
import {
    createUserWithEmailAndPassword,
    AuthProvider,
    GoogleAuthProvider,
    GithubAuthProvider,
    FacebookAuthProvider,
    browserSessionPersistence,
    setPersistence,
    signInWithPopup,
    onAuthStateChanged
} from 'firebase/auth'
import { FirebaseError } from '@firebase/util'
import {useCreateUserMutation} from '../../../generated/graphql/graph'
import ROUTE from "../../../config/routes";
import {Person} from "../../../redux/models/Person";
import {useDispatch} from "react-redux";



type SignupFormValues = {
    username: string;
    password: string;
};

const initialValues: SignupFormValues = {
    username: '',
    password: '',
};

const validationSchema = Yup.object().shape({
    username: Yup.string().email('Invalid email').required('Required'),
    password: Yup.string().required('Required').min(8, "Password to short")
});
const SignupForm: React.FC = () => {
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState('')
    const [modalTitle, setModalTitle] = useState('Could not create your account!')
    const [createUserMutation] = useCreateUserMutation()
    const navigate = useNavigate()

    const toggleModal = () => {
        setShowModal(!showModal)
    }
    useEffect(() => {
        // onAuthStateChanged(appAuth, (user) => {
        //     if (user) {
        //         navigate(ROUTE.HOME)
        //     }
        // });
    })

    const handleSubmit = async (values: SignupFormValues) => {
        const {username, password} = values;
        setPersistence(appAuth, browserSessionPersistence)
            .then(() =>{
                createUserWithEmailAndPassword(appAuth, username, password)
                    .then((credential) => {
                        createUserMutation({variables: {firebaseID: credential.user.uid}})
                            .then(data => {
                                new Person(dispatch).login()
                                navigate(ROUTE.HOME)
                            })
                            .catch(error => {
                                setModalContent(`System error: CREATE-01...<br />
                                       Please try again or contact support@komtoo.it`)
                                toggleModal()
                            })
                    })
                    .catch((error: FirebaseError) => {
                        switch (error.code) {
                            case "auth/email-already-in-use":
                                setModalContent(`The Email account is already in use.<br />
                                       Please login with this username.`)
                                break;
                            case "auth/invalid-email":
                                setModalContent(`The Email provided is invalid.<br />
                                       Please check your password and try again.`)
                                break;
                            case "auth/operation-not-allowed":
                                setModalContent(`System error CREATE-02...<br />
                                       Please try again or contact support@komtoo.it`)
                                break;
                            case "auth/weak-password":
                                setModalContent(`Password is considered weak!<br />
                                       Please update your password to become more safe.`)
                                break;
                            default:
                        }
                        toggleModal()
                    })
            })
            .catch((error) =>{
                setModalTitle("Unable to create persistent storage")
                setModalContent(`This is an unusual error please contact support@komtoo.it<br .>
                                        with the following error: ${error}`)
                toggleModal()
            })

    };

    const handleGoogleLogin = () => {
        const provider = new GoogleAuthProvider();
        handleExternalLogin(provider, "GOOGLE-01")
    };

    const handleFacebookLogin = () => {
        const provider = new FacebookAuthProvider();
        handleExternalLogin(provider, "TWITTER-01")
    };


    const handleGithubLogin = () => {
        const provider = new GithubAuthProvider();
        handleExternalLogin(provider, "GITHUB-01")
    };

    const handleExternalLogin = (provider: AuthProvider, providerErrorID: string) => {
        signInWithPopup(appAuth, provider)
            .then((result) => {
                const user = result.user;
                createUserMutation({variables: {firebaseID: user.uid, name: user.displayName}})
                    .then(data => {
                        navigate(ROUTE.HOME)
                    })
                    .catch(error => {
                        console.log(error)
                        switch (error.message) {
                            case "user already exists":
                                setModalContent(`The Email account is already in use.<br />
                                               Please login with this username.`)

                                break;
                            default:
                                setModalContent(`System error: ${providerErrorID} - ${error}...<br />
                                               Please try again or contact support@komtoo.it`)

                        }
                        toggleModal()
                    })

            }).catch((error) => {
                setModalContent(`System error: ${providerErrorID} - ${error}...<br />
                                                   Please try again or contact support@komtoo.it`)
            toggleModal()
        })
    }

    return (
        <div className="flex min-h-full items-center justify-center px-4 py-12 sm:px-6 lg:px-8 accountEntryForm">
            <div className="w-full max-w-md space-y-8">
                <div>
                    <img
                        className="mx-auto h-23 w-auto fill-white"
                        src="/img/logo/logo-128.png"
                        alt="Bookmark manager. Store all your bookmarks in one convenient location and keep all those interesting articles you want to read later in a single, organized space. Make all your bookmarks available across all your devices."
                    />
                    <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900 dark:text-white">
                        Sign up!
                    </h2>
                </div>
                <div>
                    <div className="flex min-h-full items-center justify-center py-12 sm:px-6 lg:px-0">
                        <div className="mt-6 grid grid-cols-3 gap-3 min-w-full">
                            <div>
                                <button
                                    onClick={handleFacebookLogin}
                                    className="inline-flex w-full justify-center rounded-md bg-white px-4 py-2 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0 dark:bg-blue-600 dark:highlight-white/20 dark:hover:bg-blue-500 dark:ring-0"
                                >
                                    <span className="sr-only">Sign in with Facebook</span>
                                    <svg className="h-5 w-5 dark:fill-white" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                                        <path
                                            fillRule="evenodd"
                                            d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </button>
                            </div>

                            <div>
                                <button
                                    onClick={handleGoogleLogin}
                                    className="inline-flex w-full justify-center rounded-md bg-white px-4 py-2 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0 dark:bg-blue-600 dark:highlight-white/20 dark:hover:bg-blue-500 dark:ring-0"
                                >
                                    <span className="sr-only">Sign in with Google</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 488 512" className="h-5 w-5 dark:fill-white" fill="currentColor">
                                        <path d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"/>
                                    </svg>
                                </button>
                            </div>

                            <div>
                                <button
                                    onClick={handleGithubLogin}
                                    className="inline-flex w-full justify-center rounded-md bg-white px-4 py-2 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0 dark:bg-blue-600 dark:highlight-white/20 dark:hover:bg-blue-500 dark:ring-0"
                                >
                                    <span className="sr-only">Sign in with GitHub</span>
                                    <svg className="h-5 w-5 dark:fill-white" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                                        <path
                                            fillRule="evenodd"
                                            d="M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="relative">
                        <div className="absolute inset-0 flex items-center">
                            <div className="w-full border-t border-gray-300" />
                        </div>
                        <div className="relative flex justify-center text-sm">
                            <span className="bg-gray-50 dark:bg-slate-900 px-2 text-gray-500 dark:text-white">Or sign up with email</span>
                        </div>
                    </div>
                </div>
                {showModal && <WarningModal onClose={toggleModal} title={modalTitle} content={modalContent} btnLabel='Okay!' />}
                <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
                    {({errors, touched}) => (
                        <Form className="mt-8 space-y-6">
                            <div className="-space-y-px rounded-md shadow-sm">
                                <div>
                                    <label htmlFor="email-address" className="sr-only">
                                        Email address
                                    </label>
                                    <Field
                                        id="email-address"
                                        name="username"
                                        type="email"
                                        autoComplete="email"
                                        required
                                        className="relative block w-full rounded-b-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-slate-800 dark:ring-0 dark:text-slate-300 dark:highlight-white/5 dark:hover:bg-slate-700"
                                        placeholder="Email address"
                                    />
                                    <ErrorMessage name="username" className="text-red-500 text-xs italic" component="p" />
                                </div>
                                <div>
                                    <label htmlFor="password" className="sr-only">
                                        Password
                                    </label>
                                    <Field
                                        id="password"
                                        name="password"
                                        type="password"
                                        autoComplete="current-password"
                                        required
                                        className="relative block w-full rounded-b-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-slate-800 dark:ring-0 dark:text-slate-300 dark:highlight-white/5 dark:hover:bg-slate-700"
                                        placeholder="Password"
                                    />
                                    <ErrorMessage name="password" className="text-red-500 text-xs italic" component="p" />
                                </div>
                            </div>
                            <div>
                                <button
                                    type="submit"
                                    className="group relative flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                >
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                      <LockClosedIcon className="h-5 w-5 text-blue-400 group-hover:text-blue-300" aria-hidden="true" />
                    </span>
                                    Sign in
                                </button>
                                <div className="signUp mt-3">
                                    <NavLink to={ROUTE.SIGN_IN} className="font-medium text-blue-600 hover:text-blue-500 dark:text-white">Already have an account login here</NavLink>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default SignupForm;

