import {FormEventHandler, Fragment, useRef, useState} from 'react'
import { Combobox, Dialog, Transition } from '@headlessui/react'
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import { ExclamationTriangleIcon, FolderIcon, LifebuoyIcon } from '@heroicons/react/24/outline'
import {classNames} from "../../../../utils/classNames";
import {ISearchItem} from "../../../../redux/models/ISearchItem";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../redux/rootState";
import {setShowSearchModal} from "../../../../redux/slices/sliceUI";
import {t} from "i18next";
import {useSearchLazyQuery} from "../../../../generated/graphql/graph";
import {NavLink} from "react-router-dom";
import {FormatTagLink} from "../../../../utils/Tags";
import debounce from "lodash/debounce";

const SearchModalComponent = () =>  {
    // const [open, setOpen] = useState(true)
    const dispatch = useDispatch()
    const searchRef = useRef<HTMLInputElement>(null);
    const showSearchModal = useSelector((state: RootState) => state.ui.showSearchModal)
    const [rawQuery, setRawQuery] = useState('')
    const query = rawQuery.toLowerCase().replace(/^[#>]/, '')
    const [searchQuery, {loading, error}] = useSearchLazyQuery({variables: {query: ''}})
    const [filteredTags, setFilteredTags]= useState<ISearchItem[]>([])
    const [filteredBookmarks, setFilteredBookmarks]= useState<ISearchItem[]>([])

    const setOpen = (value : boolean) => {
        dispatch(setShowSearchModal(value))
    }

    const startQuery = (rQuery: string) => {
        setRawQuery(rQuery)
        searchQuery({variables: {query: rQuery}})
            .then(qd => {
                if (qd && qd.data && qd.data.Search) {
                    const data = qd.data.Search
                    const bmrks: ISearchItem[] = []
                    data.bookmarks.map((bookmark) => {
                        bmrks.push({id: bookmark.id, name: bookmark.title, url: bookmark.url})
                        return bookmark
                    })
                    setFilteredBookmarks(bmrks)

                    const tgs: ISearchItem[] = []
                    data.tags.map((tag) => {
                        tgs.push({id: tag.value, name: tag.path, url: '', path: tag.path})
                        return tag
                    })
                    setFilteredTags(tgs)

                }
            })
            .catch()
    }

    const debouncedOnChange = debounce((value: string) => {
        startQuery(value)
    }, 500);

    const closeSearch = () => {
        dispatch(setShowSearchModal(false))
    }

    const triggerQuery = (query: string) => {
        debouncedOnChange(query)
    }


    return (
        <Transition.Root show={showSearchModal} as={Fragment} afterLeave={() => setRawQuery('')} appear>
            <Dialog as="div" className="relative z-10" onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Dialog.Panel className="mt-20 mx-auto max-w-xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all dark:divide-gray-500 dark:bg-gray-900">
                            <Combobox >
                                {/*onChange={(item : ISearchItem) => (window.location = item.url)}*/}
                                <div className="relative">
                                    <MagnifyingGlassIcon
                                        className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400 dark:text-gray-400"
                                        aria-hidden="true"
                                    />
                                    <Combobox.Input
                                        className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm dark:text-gray-300"
                                        placeholder={t("Search...")}
                                        ref={searchRef}
                                        onInput={(event: React.ChangeEvent<HTMLInputElement>) => triggerQuery(event.target.value)}
                                        // onKeyUp={triggerQuery}
                                    />
                                </div>

                                {(filteredTags.length > 0 || filteredBookmarks.length > 0) && (
                                    <Combobox.Options
                                        static
                                        className="max-h-80 scroll-py-10 scroll-py-10 scroll-pb-2 scroll-pb-2 space-y-4 overflow-y-auto p-4 pb-2 dark:divide-gray-500 dark:divide-opacity-20"
                                    >
                                        {filteredTags.length > 0 && (
                                            <li>
                                                <h2 className="text-xs font-semibold text-gray-900 dark:text-gray-300">{t("Tags")}</h2>
                                                <ul className="-mx-4 mt-2 text-sm text-gray-700 dark:text-gray-400">
                                                    {filteredTags.map((tag) => (
                                                        <Combobox.Option
                                                            key={tag.id}
                                                            value={tag}
                                                            className={({ active }) =>
                                                                classNames(
                                                                    'flex cursor-default select-none items-center px-4 py-2',
                                                                    (active ? 'bg-blue-600 text-white dark:bg-gray-800' : '')
                                                                )
                                                            }
                                                        >
                                                            {({ active }) => (
                                                                <>
                                                                    <FolderIcon
                                                                        className={classNames('h-6 w-6 flex-none', active ? 'text-white' : 'text-gray-400')}
                                                                        aria-hidden="true"
                                                                    />
                                                                    <NavLink to={FormatTagLink({value: tag.id, label: tag.name, path: tag.path!})} onClick={closeSearch}>
                                                                        <span className="ml-3 flex-auto truncate">{tag.name}</span>
                                                                    </NavLink>

                                                                </>
                                                            )}
                                                        </Combobox.Option>
                                                    ))}
                                                </ul>
                                            </li>
                                        )}
                                        {filteredBookmarks.length > 0 && (
                                            <li>
                                                <h2 className="text-xs font-semibold text-gray-900 dark:text-gray-300">{t("Bookmarks")}</h2>
                                                <ul className="-mx-4 mt-2 text-sm text-gray-700 dark:text-gray-400">
                                                    {filteredBookmarks.map((bookmark) => (
                                                        <Combobox.Option
                                                            key={bookmark.id}
                                                            value={bookmark}
                                                            className={({ active }) =>
                                                                classNames(
                                                                    'flex cursor-default select-none items-center px-4 py-2',
                                                                    (active ? 'bg-blue-600 text-white dark:bg-gray-800' : '')
                                                                )
                                                            }
                                                        >
                                                            <img src={bookmark.imageUrl} alt="" className="h-6 w-6 flex-none rounded-full" />
                                                            <NavLink to={bookmark.url} onClick={closeSearch} target="_blank">
                                                                <span className="ml-3 flex-auto truncate">{bookmark.name}</span>
                                                            </NavLink>
                                                        </Combobox.Option>
                                                    ))}
                                                </ul>
                                            </li>
                                        )}
                                    </Combobox.Options>
                                )}

                                {rawQuery === '?' && (
                                    <div className="px-6 py-14 text-center text-sm sm:px-14">
                                        <LifebuoyIcon className="mx-auto h-6 w-6 text-gray-400" aria-hidden="true" />
                                        <p className="mt-4 font-semibold text-gray-900 dark:text-gray-300">{t("Help with searching")}</p>
                                        <p className="mt-2 text-gray-500">
                                            {t(`Use this tool to quickly search for your bookmarks and tags across our platform. You can also
                                            use the search modifiers found in the footer below to limit the results to just bookmarks or tags.`)}
                                        </p>
                                    </div>
                                )}

                                {loading && (
                                    <div className="px-6 py-14 text-center text-sm sm:px-14">
                                        <ExclamationTriangleIcon className="mx-auto h-6 w-6 text-gray-400" aria-hidden="true" />
                                        <p className="mt-4 font-semibold text-gray-900">Loading data...</p>
                                        <p className="mt-2 text-gray-500">We are loading your data...</p>
                                    </div>
                                )}
                                {query !== '' && rawQuery !== '?' && filteredTags.length === 0 && filteredBookmarks.length === 0 && (
                                    <div className="px-6 py-14 text-center text-sm sm:px-14">
                                        <ExclamationTriangleIcon className="mx-auto h-6 w-6 text-gray-400" aria-hidden="true" />
                                        <p className="mt-4 font-semibold text-gray-900">No results found</p>
                                        <p className="mt-2 text-gray-500">We couldn’t find anything with that term. Please try again.</p>
                                    </div>
                                )}

                                <div className="flex flex-wrap items-center bg-gray-50 px-4 py-2.5 text-xs text-gray-700 dark:bg-slate-700 dark:text-gray-200">
                                    Type{' '}
                                    <kbd
                                        className={classNames(
                                            'mx-1 flex h-5 w-5 items-center justify-center rounded border bg-white font-semibold sm:mx-2',
                                            rawQuery.startsWith('#') ? 'border-blue-600 text-blue-600' : 'border-gray-400 text-gray-900'
                                        )}
                                    >
                                        #
                                    </kbd>{' '}
                                    <span className="sm:hidden">{t("for tags,")}</span>
                                    <span className="hidden sm:inline">{t("to access tags,")}</span>
                                    <kbd
                                        className={classNames(
                                            'mx-1 flex h-5 w-5 items-center justify-center rounded border bg-white font-semibold sm:mx-2',
                                            rawQuery.startsWith('>') ? 'border-blue-600 text-blue-600' : 'border-gray-400 text-gray-900'
                                        )}
                                    >
                                        &gt;
                                    </kbd>{' '}
                                    {t("for bookmarks, and")}{' '}
                                    <kbd
                                        className={classNames(
                                            'mx-1 flex h-5 w-5 items-center justify-center rounded border bg-white font-semibold sm:mx-2',
                                            rawQuery === '?' ? 'border-blue-600 text-blue-600' : 'border-gray-400 text-gray-900'
                                        )}
                                    >
                                        ?
                                    </kbd>{' '}
                                    {t("for help.")}
                                </div>
                            </Combobox>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default SearchModalComponent