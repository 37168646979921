import {Fragment, useEffect, useState} from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import {classNames} from "../../../../utils/classNames";
import {ClassesFormLabel, ClassesFormListBoxButton, ClassesFormListBoxOptions} from "../../../../utils/StylingClasses";
import {ISelectOption} from "../../../../redux/models/ISelectOption";
import {t} from "i18next";

interface Props {
    label: string
    options: ISelectOption[]
    selectedValue: string
    onSelect: (selected: ISelectOption) => void
}

const SelectComponent = ({label, options, selectedValue, onSelect} : Props) => {
    const getSelectedIndex = (value: string): ISelectOption => {
        for (const option of options) {
            if (option.value === value) {
                return option
            }
        }
        return {label: t("Please select"), value: ''}
    }

    const [selected, setSelected] = useState<ISelectOption>({label: '', value: ''})

    useEffect(() => {
        setSelected(getSelectedIndex(selectedValue))
    }, [options, selectedValue])

    const handleOnChange = (value: ISelectOption) => {
        setSelected(value)
        onSelect(value)
    }

    return (
        <Listbox value={selected} onChange={handleOnChange}>
            {({ open }) => (
                <>
                    <Listbox.Label className={ClassesFormLabel}>{label}</Listbox.Label>
                    <div className="relative mt-2">
                        <Listbox.Button className={ClassesFormListBoxButton}>
                            <span className="block truncate">{selected.label}</span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
                        </Listbox.Button>

                        <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options className={ClassesFormListBoxOptions}>
                                {options.map((option) => (
                                    <Listbox.Option
                                        key={option.value}
                                        className={({ active }) =>
                                            classNames(
                                                active ? 'bg-blue-600 text-white dark:bg-slate-500' : 'text-gray-900 dark:text-gray-300',
                                                'relative cursor-default select-none py-2 pl-3 pr-9'
                                            )
                                        }
                                        value={option}
                                    >
                                        {({ selected, active }) => (
                                            <>
                        <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                          {option.label}
                        </span>

                                                {selected ? (
                                                    <span
                                                        className={classNames(
                                                            active ? 'text-white' : 'text-blue-600 dark:text-gray-300',
                                                            'absolute inset-y-0 right-0 flex items-center pr-4'
                                                        )}
                                                    >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                                                ) : null}
                                            </>
                                        )}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </>
            )}
        </Listbox>
    )
}

export default SelectComponent
