/* css */


//Form elements

import {classNames} from "./classNames";

export const ClassesFormInput = "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 dark:ring-slate-500 dark:bg-slate-600 dark:text-slate-300 dark:highlight-white/5 dark:hover:bg-slate-600 p-2"
export const ClassesFormInputError = "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-red-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 dark:ring-red-400 dark:bg-slate-600 dark:text-slate-300 dark:highlight-white/5 dark:hover:bg-slate-600"
export const ClassesFormLabel = "block text-sm font-bold leading-6 text-blue-700 dark:text-slate-300"
export const ClassesFormSelect = "block w-full rounded-md border-0 pl-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 dark:ring-slate-500 dark:bg-slate-600 dark:text-slate-300 dark:highlight-white/5 dark:hover:bg-slate-600"
export const ClassesFormListBoxButton = "relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6 dark:ring-slate-500 dark:bg-slate-600 dark:text-slate-300 dark:highlight-white/5 dark:hover:bg-slate-600"
export const ClassesFormListBoxOptions = "absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm dark:bg-slate-600 dark:ring-slate-500"

export const ClassesForm = "relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"


export const ClassesFormDatePicker = "relative transition-all duration-300 py-2.5 pl-4 pr-14 w-full border-gray-300 dark:bg-slate-600 dark:text-white/80 dark:border-slate-600 rounded-lg tracking-wide font-light text-sm placeholder-gray-400 bg-white focus:ring disabled:opacity-40 disabled:cursor-not-allowed focus:border-blue-500 focus:ring-blue-500/2"

// Headings
export const ClassesH1 = "text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-10 dark:text-gray-300"
export const ClassesH2 = "text-base font-semibold leading-6 text-gray-900 dark:text-gray-300"

// Content
export const ClassesP = "mt-2 text-sm text-gray-700 dark:text-gray-300"
export const ClassesA = "text-blue-600 hover:text-blue-900 dark:text-sky-600 dark:hover:text-sky-500"

// Table
export const ClassesTableHead = "bg-gray-50 dark:bg-slate-700"
export const ClassesTableBody = "divide-y divide-gray-200 bg-white dark:bg-slate-900 dark:divide-slate-700"
export const ClassesTableTh = "px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-400"
export const ClassesTableThFirst = classNames(ClassesTableTh, "pl-4 pr-3 sm:pl-6")
export const ClassesTableThLast = classNames(ClassesTableTh, "relative py-3.5 pl-3 pr-4 sm:pr-6")

export const ClassesTableTdBase = "whitespace-nowrap py-4 text-gray-500"
export const ClassesTableTd = classNames(ClassesTableTdBase, "px-3 text-sm")
export const ClassesTableTdFirst = classNames(ClassesTableTdBase, "pl-4 pr-3 font-medium dark:text-gray-300")
export const ClassesTableTdLast = classNames(ClassesTableTdBase, "relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6")

//Buttons
export const ClassesSubmitBtn = "inline-flex items-center gap-x-1.5 rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 bg-blue-600 hover:bg-blue-500 focus-visible:outline-blue-600 dark:bg-sky-600 dark:hover:bg-sky-500 dark:focus-visible:outline-sky-600"
export const ClassesDeleteBtn = "inline-flex items-center gap-x-1.5 rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 bg-red-600 hover:bg-red-500 focus-visible:outline-blue-600 dark:bg-sky-600 dark:hover:bg-red-500 dark:focus-visible:outline-red-600"
export const ClassesCancelBtn = "text-sm font-semibold leading-6 text-gray-900 dark:text-slate-500"
export const ClassesTextBtn = "font-semibold text-blue-600 hover:text-blue-500 dark:text-gray-300 hover:dark:text-gray-200"