import { Navigate, Outlet } from 'react-router-dom'
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/rootState";
import ROUTE from "../../../config/routes";
const PrivateRoutes = () => {
    const isAuthenticated = useSelector((state: RootState) => state.person.authenticated)
    return (
        isAuthenticated ? <Outlet/> : <Navigate to={ROUTE.SIGN_IN} />
    )
}

export default PrivateRoutes