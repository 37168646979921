import React from 'react';
import MainMultiColumnContainer from "../../layout/MainMultiColumnContainer";
import BookmarkHome from "../../general/Bookmark/BookmarkHome";


interface Props {
}



const Home: React.FC<Props> = () => {
    return (
        <div className="App">
            <MainMultiColumnContainer>
                <BookmarkHome />
            </MainMultiColumnContainer>
        </div>
    );
};

export default Home


