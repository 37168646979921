import {IBookmarkTag, IScreenshot} from "../../../../config/interfaces/tags";
import {NavLink} from "react-router-dom";
import {LinkIcon, PencilSquareIcon} from '@heroicons/react/20/solid'
import {ClassesP} from "../../../../utils/StylingClasses";
import {classNames} from "../../../../utils/classNames";
import {id} from "date-fns/locale";
import {useEffect, useState} from "react";
import BookmarkScreenshotImage from "../BookmarkScreenshotImage";
import {FormatTagLink} from "../../../../utils/Tags";

interface Props {
    contentID: string;
    title: string;
    text: string;
    read?: boolean;
    icon?: string;
    screenshot?: IScreenshot;
    url: string;
    tags: IBookmarkTag[];
}

const BookmarkCard = ({title, text, read, icon, url, tags, screenshot, contentID} : Props) => {
    return (
        <div className="overflow-hidden rounded-lg bg-white shadow h-full bg-gray-50 dark:bg-slate-800" key={contentID}>
            <div className="px-4 py-5 sm:p-6">
                <a href={url} rel="noreferrer" target="_blank">
                    <BookmarkScreenshotImage screenshot={screenshot!} />
                </a>
            </div>
            <div className="bg-gray-50 px-4 py-4 sm:px-6 h-full min-h-full dark:bg-slate-700">
                <span className="font-semibold dark:text-white">
                    <a href={url} rel="noreferrer" target="_blank">
                        {title}
                    </a>
                </span><br  />
                <p className={classNames(ClassesP, "text-sm py-3 min-h-fit")}>{text}</p>
                <div className="flex justify-between">
                    <div>
                        <ul className="flex space-x-2">
                            {tags.map((tag) => (
                                <li className="inline-flex items-center gap-x-1.5 rounded-full bg-gray-100 px-1.5 py-0.5 text-xs font-medium text-gray-600 dark:text-gray-200 dark:bg-slate-500" key={`${contentID}-${tag.value}`}>
                                    <svg className="h-1.5 w-1.5 fill-gray-400 dark:fill-white" viewBox="0 0 6 6" aria-hidden="true">
                                        <circle cx={3} cy={3} r={3} />
                                    </svg>
                                    <NavLink to={FormatTagLink(tag)}>
                                        {tag.label}
                                    </NavLink>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div>
                        <div className="flex justify-end m--20 space-x-2">
                            <NavLink to={`/bookmark/edit/${contentID}`}>
                                <PencilSquareIcon className="w-5 h-5 dark:text-white hover:text-gray-500 dark:hover:text-gray-300"/>
                            </NavLink>
                            <a href={url} target="_blank" rel="noreferrer">
                                <LinkIcon className="w-5 h-5 dark:text-white hover:text-gray-500 dark:hover:text-gray-300"/>
                            </a>
                        </div>
                    </div>
                </div>
                {/* We use less vertical padding on card footers at all sizes than on headers or body sections */}
            </div>
        </div>
    )
}

export default BookmarkCard;