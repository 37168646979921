import {ApolloClient, InMemoryCache, DefaultOptions, from, createHttpLink} from "@apollo/client";
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import {appAuth} from "./firebase";
import {User} from 'firebase/auth'

const defaultOptions: DefaultOptions = {
    watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
    },
    query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    },
};

export const waitForAuth = async () :Promise<User | null> => {
    return new Promise((resolve, reject) => {
        const unsubscribe = appAuth.onAuthStateChanged(user => {
            unsubscribe();
            resolve(user);
        }, reject);
    });
};

/* eslint-disable @typescript-eslint/naming-convention */
const authLink = setContext(async (_, { headers }) => {
    const user = await waitForAuth();
    let token = ""
    if (user != null) {
        token  = await user?.getIdToken();
    }
    if(process.env.NODE_ENV !== 'production') {
        console.log(token)
    }
    return {
        headers: {
            ...headers,
            authorization: `Bearer ${token}`,
        },
    };
});

const errorLink = onError(({ graphQLErrors }) => {
    // console.log(graphQLErrors)
    if (graphQLErrors) {
        graphQLErrors.map(({ message }) => {
            //TODO not working yet.
            if (
                message === 'invalid signature' ||
                message === 'jwt malformed' ||
                message === 'jwt expired'
            ) {
                //logout!
            }
            return true;
        });
    }
});

const httpLink = createHttpLink({
    uri:
        process.env.NODE_ENV === 'production'
            ? `/query`
            : `http://app-dev.komtoo.it:8888/query`
});

const appCache = new InMemoryCache();


export const client = new ApolloClient({
    cache: appCache,
    // uri: process.env.NODE_ENV === 'development'
    //     ? `http://app-dev.komtoo.it:8888/query`
    //     : `http://127.0.0.1:8888/query`,
    link: from([errorLink, authLink.concat(httpLink)]),
    // link: from([authLink.concat(httpLink)]),
    // defaultOptions
})
