import React from 'react';
import logo from './logo.svg';
import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./components/pages/HomePage";
import Signup from "./components/pages/Signup";
import Root from './components/pages/Root';
import ProfileSettingsPage from "./components/pages/ProfileSettingsPage";
import ROUTE, {ROUTES} from "./config/routes";
import SigninPage from "./components/pages/SigninPage";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "./redux/rootState";
import BookmarkImportModal from "./components/general/BookmarkImportModal";
import PrivateRoutes from "./components/pages/PrivateRoutes";
import ImplementPage from './components/pages/ImplementPage';
import AvatarModal from "./components/general/Profile/AvatarModal";
import AddBookmarkModal from "./components/general/Modals/AddBookmarkModal";
import BookMarkEditPage from "./components/pages/BookMarkEditPage";
import SettingsPage from "./components/pages/SettingsPage";
import AddApiKeyPage from "./components/pages/AddApiKeyPage";
import ConfirmModal from "./components/general/Modals/ConfirmModal";
import TagPage from "./components/pages/TagPage";
import TagEditPage from './components/pages/TagEditPage';
import BookmarksAllPage from "./components/pages/BookmarksAllPage";
import BookmarksInboxPage from "./components/pages/BookmarksInboxPage";
import BookmarksFavoritesPage from "./components/pages/BookmarksFavoritesPage";
import BookmarksTrashPage from "./components/pages/BookmarksTrashPage";
import SearchModalComponent from "./components/general/Modals/SearchModalComponent";
import EditApiKeyPage from "./components/pages/EditApiKeyPage";
import ConfigScreenPage from "./components/layout/ConfigScreenPage";

function App() {
    const showBookmarkImportModal = useSelector((state: RootState) => state.ui.bookmarkImportModal)
    const showAddBookmarkModal = useSelector((state: RootState) => state.ui.addBookmarkModal)
    const showSearchModal = useSelector((state: RootState) => state.ui.showSearchModal)
    const showAvatarModal = useSelector((state: RootState) => state.ui.avatarModal)

    return (
    <BrowserRouter>
            <div>
                <section>
                    {showBookmarkImportModal && <BookmarkImportModal />}
                    {showAvatarModal && <AvatarModal />}
                    {showAddBookmarkModal && <AddBookmarkModal />}
                    {showSearchModal && <SearchModalComponent />}
                    <Routes>
                        <Route path={ROUTE.ROOT} element={<Root />}/>
                        <Route path={ROUTE.SIGN_UP} element={<Signup />}/>
                        <Route path={ROUTE.SIGN_IN} element={<SigninPage />}/>
                        <Route path={ROUTE.SIGN_OUT} element={<SigninPage />}/>
                        <Route element={<PrivateRoutes />}>
                            {/* App */}
                            <Route path={ROUTE.HOME} element={<Home />}/>
                            <Route path={ROUTE.ALL} element={<BookmarksAllPage />}/>
                            <Route path={ROUTE.INBOX} element={<BookmarksInboxPage />}/>
                            <Route path={ROUTE.FAVORITES} element={<BookmarksFavoritesPage />}/>
                            <Route path={ROUTE.TRASH} element={<BookmarksTrashPage />}/>
                            <Route path={ROUTE.TAG_EDIT} element={<TagEditPage />}/>
                            <Route path={ROUTE.TAG} element={<TagPage />}/>
                            <Route path={ROUTE.CONTENT_EDIT} element={<BookMarkEditPage />} />
                            {/* Config */}
                            {ROUTES.config.map((rt) =>
                                // ()
                                (<Route path={rt.route} element={<ConfigScreenPage>{rt.children}</ConfigScreenPage>}/>)
                            )})
                            {/*<Route path={ROUTE.SETTINGS} element={<SettingsPage />}/>*/}
                            {/*<Route path={ROUTE.ADD_API_KEY} element={<AddApiKeyPage />}/>*/}
                            {/*<Route path={ROUTE.EDIT_API_KEY} element={<EditApiKeyPage />}/>*/}
                            {/*<Route path={ROUTE.IMPORTS} element={<ImplementPage />}/>*/}
                            {/*<Route path={ROUTE.BACKUPS} element={<ImplementPage />}/>*/}
                            {/*<Route path={ROUTE.PROFILE} element={<ProfileSettingsPage />}/>*/}
                        </Route>
                    </Routes>
                </section>
            </div>
        </BrowserRouter>
    );
}

export default App;
