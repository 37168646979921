import React, {ChangeEvent, ReactEventHandler, useState} from "react";
import ReactCrop, {centerCrop, Crop, makeAspectCrop, PercentCrop, PixelCrop} from 'react-image-crop'
import 'react-image-crop/src/ReactCrop.scss'
import {convertToPixelCrop} from "react-image-crop/src/utils";

interface Props {
    onCropAdded: (c: PercentCrop) => void;
    avatarFile: File;
}

const AvatarCropZone: React.FC<Props> = ({ onCropAdded, avatarFile } : Props) => {

    const [crop, setCrop] = useState<Crop>()
    const [init, setInit] = useState(false)

    const onCropChange = (crop: PixelCrop, percentCrop: PercentCrop) => {
        console.log('pixel', crop, 'percent', percentCrop)
        setCrop(crop)
        if (crop?.x && crop?.y && crop.width && crop.height) {
            onCropAdded(percentCrop)
        }
    }

    const onImageLoad  = (e: ChangeEvent<HTMLImageElement>)=> {
        if (init) {
            return
        }
        const { naturalWidth: width, naturalHeight: height } = e.currentTarget;

        const crop = centerCrop(
            makeAspectCrop(
                {
                    unit: '%',
                    width: 90,
                },
                1,
                width,
                height
            ),
            width,
            height
        )

        setCrop(crop)
        onCropAdded(crop)
        setInit(true)
    }

    return (
        <ReactCrop crop={crop} onChange={onCropChange} aspect={1} className="">
            {/**/}
            <img alt="Avatar" src={URL.createObjectURL(avatarFile)} onLoad={onImageLoad} />
        </ReactCrop>
    )
};

export default AvatarCropZone;