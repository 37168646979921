import React, {useEffect, useRef, useState} from 'react'
import {

    ClassesFormLabel, ClassesFormInput, ClassesFormDatePicker, ClassesP, ClassesH2, ClassesSubmitBtn
} from "../../../../utils/StylingClasses";
import {classNames} from "../../../../utils/classNames";
import {useAddApiKeyMutation} from "../../../../generated/graphql/graph";
import {Field, Form, Formik} from "formik";
import CancelBtn from "../../Buttons/CancelBtn";
import SubmitBtn from "../../Buttons/SubmitBtn";
import * as Yup from "yup";
import {string} from "yup";
import Datepicker from "react-tailwindcss-datepicker";
import moment from "moment";
import {useSelector} from "react-redux";
import {RootState} from "../../../../redux/rootState";
import {ClipboardDocumentCheckIcon, ClipboardDocumentIcon} from '@heroicons/react/24/outline'
import {t} from "i18next";
import ROUTE from "../../../../config/routes";

interface Props {
}

interface InitialValues {
    name: string;
    id: string;
    origins: string;
}

const validationSchema = Yup.object().shape({
    name: string().required().min(2)
});


const AddApiKeysComponent = () => {

    const [addApiMutation, {data, loading, error}]= useAddApiKeyMutation()
    const userID = useSelector((state: RootState) => state.person.id)
    const keyInputRef = useRef<HTMLInputElement>(null);
    const [copyState, setCopyState] = useState('')
    const [isCopied, setIsCopied] = useState(false)
    const [dateValue, setDateValue] = useState({
        startDate: null,
        endDate: null
    });


    let initialValues: InitialValues = {
        name: "",
        id: "",
        origins: "",
    }
    const handleSubmit = (values: InitialValues) => {
        let expiresAt: string|null = null;
        if (dateValue.startDate) {
            const momentDate = moment.utc(dateValue.startDate);
            expiresAt = momentDate.format('YYYY-MM-DD');
        }
        addApiMutation({variables: {name: values.name, expiresAt: expiresAt, allowedOrigins: values.origins}}).then((qd) => {
            if (qd && qd.data && qd.data.AddAPIKey) {
                setCopyState(`{\n\t"x-api-user": ${userID},\n\t"x-api-key": "${qd.data.AddAPIKey!.key}"\n}`)
            }
        })
    }
    const handleDateValueChange = (newValue: any) => {
        setDateValue(newValue)
    }

    const handleCopy = () => {
        setIsCopied(true)
        navigator.clipboard.writeText(copyState)
            .then(() => console.log(`Copied to clipboard: ${copyState}`))
            .catch((err) => console.error('Failed to copy text: ', err));
        setTimeout(() => {
            setIsCopied(false)
        }, 2000)
    }

    if (data && data.AddAPIKey && data.AddAPIKey.key) {
        return (
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="mx-auto max-w-3xl">
                    <div className="mx-auto max-w-7xl px-4 py-5 sm:px-6 sm:pt-32 lg:px-8">
                        <div className="border-b border-gray-900/10 pb-12 dark:border-slate-400">
                            <h2 className={ClassesH2}>{t("Added API Key")}</h2>
                            <p className={classNames(ClassesP, "mb-10")}>{t("Copy your key. It will only be shown once.")}</p>
                            <div className="mb-3">
                                <label htmlFor="email" className={ClassesFormLabel}>
                                    {t("Id (x-api-user)")}
                                </label>
                                <div className="mt-2">
                                    <input id="id" name="key" value={userID} className={ClassesFormInput} onChange={()=>{}}/>
                                </div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="email" className={ClassesFormLabel}>
                                    {t("Key (x-api-key)")}
                                </label>
                                <div className="mt-2">
                                    <input id="key" name="key" value={data.AddAPIKey!.key} className={ClassesFormInput} ref={keyInputRef} onChange={()=>{}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-6 flex items-center justify-end gap-x-6">
                        <CancelBtn to={ROUTE.SETTINGS} label={t("Back").toString()}/>
                        <button onClick={handleCopy} className={ClassesSubmitBtn}>
                            {t("Copy to Clipboard")}
                            {!isCopied && <ClipboardDocumentIcon className="w-5 h-5"/>}
                            {isCopied  && <ClipboardDocumentCheckIcon className="w-5 h-5 animate-wiggle"/>}
                        </button>
                    </div>

                </div>
            </div>
        )
    }

    return (
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema} enableReinitialize={true}>
                {({errors, touched}) => (
                    <Form>
                        <div className="mx-auto max-w-3xl">
                            <div className="mx-auto max-w-7xl px-4 py-5 sm:px-6 sm:pt-32 lg:px-8">
                                <div className="border-b border-gray-900/10 pb-12 dark:border-slate-400">
                                    <h2 className="text-base font-semibold leading-7 text-gray-900 mb-3">{t("Add API Key")}</h2>
                                    <div className="mb-3">
                                        <label htmlFor="email" className={ClassesFormLabel}>
                                            {t("Name")}
                                        </label>
                                        <div className="mt-2">
                                            <Field
                                                type="text"
                                                name="name"
                                                id="name"
                                                className={ClassesFormInput} />
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="email" className={ClassesFormLabel}>
                                            {t("Expiration date")}
                                        </label>
                                        <div className="mt-2">
                                            <Datepicker
                                                asSingle={true}
                                                inputClassName={ClassesFormDatePicker}
                                                useRange={false}
                                                startWeekOn="mon"
                                                value={dateValue}
                                                onChange={handleDateValueChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="email" className={ClassesFormLabel}>
                                            {t("Allow Origins. (IP's/IP ranges comma seperated)")}
                                        </label>
                                        <div className="mt-2">
                                            <Field
                                                type="text"
                                                name="origins"
                                                id="origins"
                                                className={ClassesFormInput} />
                                        </div>
                                    </div>
                                </div>
                                <Field
                                    type="hidden"
                                    name="id"
                                    id="id"
                                />
                                <Field
                                    type="hidden"
                                    name="expiredAt"
                                    id="expiredAt"
                                />
                            </div>
                            <div className="mt-6 flex items-center justify-end gap-x-6">
                                <CancelBtn to={ROUTE.SETTINGS} />
                                <SubmitBtn loading={loading} error={error} done={data} />
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default AddApiKeysComponent;
