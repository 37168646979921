import AddApiKeyComponent from "../components/general/Settings/AddApiKeyComponent";
import EditApiKeysComponent from "../components/general/Settings/EditApiKeyComponent";
import ProfileSettings from "../components/general/Profile/ProfileSettings";
import UISettingsComponent from "../components/general/Settings/UISettingsComponent";
import SettingsApiKeys from "../components/general/Settings/SettingsApiKeys";
import ImplementPage from "../components/pages/ImplementPage";
import React from "react";
import BookmarkImportComponent from "../components/general/Bookmark/BookmarkImportComponent";

enum ROUTE {
    ROOT = "/",
    HOME = "/home",
    ALL = "/all",
    INBOX = "/inbox",
    FAVORITES = "/favorites",
    CONTENT_EDIT = "/bookmark/edit/:id",
    TRASH = "/trash",
    TAG_EDIT = "/tags/edit/:tagID",
    TAG = "/tag/:tagID/:tagName",
    SIGN_IN =  "/sign-in",
    SIGN_OUT = "/sign-out",
    SIGN_UP = "/sign-up",
    SETTINGS = "/settings",
    ADD_API_KEY = "/settings/api-keys/add",
    EDIT_API_KEY = "/settings/api-keys/edit/:id",
    PROFILE = "/profile",
    IMPORTS = "/imports",
    BACKUPS = "/backups",
}

export const ROUTES = {
    config: [
            {
                route: ROUTE.ADD_API_KEY,
                children: [<AddApiKeyComponent key={ROUTE.ADD_API_KEY}/>]
            },
            {
                route: ROUTE.EDIT_API_KEY,
                children: [<EditApiKeysComponent key={ROUTE.EDIT_API_KEY} />]
            },
            {
                route: ROUTE.PROFILE,
                children: [<ProfileSettings key={ROUTE.PROFILE} />]
            },
            {
                route: ROUTE.SETTINGS,
                children: [<UISettingsComponent key={ROUTE.SETTINGS}/>, <SettingsApiKeys key={ROUTE.SETTINGS + 1}/>]
            },
            {
                route: ROUTE.BACKUPS,
                children: [<ProfileSettings key={ROUTE.BACKUPS} />]
            },
            {
                route: ROUTE.IMPORTS,
                children: [<BookmarkImportComponent key={ROUTE.IMPORTS} />]
            },
        ],
    app: [

    ]
}


export interface IRouteParams {
    params: IRouteParam[]
}

export interface IRouteParam {
    value: string
    key: string | number
}

// export const getRoute(route: string) {
//     return route
// }
export const getRoute = (route: string, params: IRouteParams = {params: []}): string => {
    params.params.map((param) => {
        route = route.replace(`:${param.key}`, `${param.value}`)
        return param
    })
    return route
}


export default ROUTE