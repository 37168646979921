import React from 'react';
import MainMultiColumnContainer from "../../layout/MainMultiColumnContainer";
import BookmarkAllComponent from "../../general/Bookmark/BookmarkAll";
import BookmarkInbox from "../../general/Bookmark/BookmarkInbox";
import BookmarkFavoritesComponent from "../../general/Bookmark/BookmarkFavorites";


interface Props {
}



const BookmarksFavoritesPage: React.FC<Props> = () => {
    return (
        <div className="App">
            <MainMultiColumnContainer>
                <BookmarkInbox />
            </MainMultiColumnContainer>
        </div>
    );
};

export default BookmarksFavoritesPage


