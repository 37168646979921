import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Uint: any;
};

export type ApiKey = {
  __typename?: 'APIKey';
  allowedOrigins?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  expiresAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  key?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type Avatar = {
  __typename?: 'Avatar';
  size: Scalars['Int'];
  url: Scalars['String'];
};

export type Bookmark = {
  __typename?: 'Bookmark';
  MetaData: Array<MetaData>;
  createdAt: Scalars['DateTime'];
  favorite: Scalars['Boolean'];
  history: Array<ReadHistory>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  node?: Maybe<Node>;
  read: Scalars['Boolean'];
  screenshot: Screenshot;
  tags: Array<Tag>;
  text: Scalars['String'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
};

export enum ContentTypes {
  Bookmark = 'BOOKMARK',
  Url = 'URL'
}

export type HomeBookmarks = {
  __typename?: 'HomeBookmarks';
  favorites: Array<Bookmark>;
  inbox: Array<Bookmark>;
  latest: Array<Bookmark>;
};

export type MetaData = {
  __typename?: 'MetaData';
  ID: Scalars['ID'];
  label: Scalars['String'];
  value: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  AddAPIKey?: Maybe<ApiKey>;
  AddBookmark?: Maybe<Bookmark>;
  AddNode?: Maybe<Node>;
  AddTag?: Maybe<Tag>;
  DeleteAPIKey?: Maybe<Scalars['Boolean']>;
  DeleteBookmark?: Maybe<Scalars['Boolean']>;
  DeleteNode?: Maybe<Scalars['Boolean']>;
  DeleteTag?: Maybe<Scalars['Boolean']>;
  EmptyTrashBookmark?: Maybe<Scalars['Boolean']>;
  InitUser?: Maybe<User>;
  LoginUser?: Maybe<User>;
  ReCrawlBookmark?: Maybe<Array<Bookmark>>;
  RegisterToken?: Maybe<Scalars['Boolean']>;
  TagBookmark?: Maybe<Bookmark>;
  TagNode?: Maybe<Node>;
  UntagBookmark?: Maybe<Bookmark>;
  UntagNode?: Maybe<Node>;
  UpdateAPIKey?: Maybe<ApiKey>;
  UpdateBookmark?: Maybe<Bookmark>;
  UpdateNode?: Maybe<Node>;
  UpdateSettings?: Maybe<User>;
  UpdateTag?: Maybe<Tag>;
  UpdateUser?: Maybe<User>;
};


export type MutationAddApiKeyArgs = {
  allowedOrigins?: InputMaybe<Scalars['String']>;
  expiresAt?: InputMaybe<Scalars['DateTime']>;
  name: Scalars['String'];
};


export type MutationAddBookmarkArgs = {
  icon?: InputMaybe<Scalars['String']>;
  parent?: InputMaybe<Scalars['ID']>;
  read?: InputMaybe<Scalars['Boolean']>;
  tags?: InputMaybe<Array<InputMaybe<TagInput>>>;
  text?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  url: Scalars['String'];
};


export type MutationAddNodeArgs = {
  icon?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  parent: Scalars['ID'];
};


export type MutationAddTagArgs = {
  label: Scalars['String'];
  parent?: InputMaybe<Scalars['ID']>;
  private?: InputMaybe<Scalars['Boolean']>;
};


export type MutationDeleteApiKeyArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteBookmarkArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteNodeArgs = {
  destinationId?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
};


export type MutationDeleteTagArgs = {
  id: Scalars['ID'];
};


export type MutationInitUserArgs = {
  avatar?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  fireID: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
};


export type MutationLoginUserArgs = {
  avatar?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  fireID: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
};


export type MutationReCrawlBookmarkArgs = {
  description?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  ogImage?: InputMaybe<Scalars['Boolean']>;
  screenshot?: InputMaybe<Scalars['Boolean']>;
  user?: InputMaybe<Scalars['ID']>;
};


export type MutationRegisterTokenArgs = {
  token: Scalars['String'];
};


export type MutationTagBookmarkArgs = {
  id: Scalars['ID'];
  tag: Scalars['ID'];
};


export type MutationTagNodeArgs = {
  node: Scalars['ID'];
  tag: Scalars['ID'];
};


export type MutationUntagBookmarkArgs = {
  id: Scalars['ID'];
  tag: Scalars['ID'];
};


export type MutationUntagNodeArgs = {
  node: Scalars['ID'];
  tag: Scalars['ID'];
};


export type MutationUpdateApiKeyArgs = {
  expiresAt?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  origins?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateBookmarkArgs = {
  favorite?: InputMaybe<Scalars['Boolean']>;
  icon?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  parent?: InputMaybe<Scalars['ID']>;
  read?: InputMaybe<Scalars['Boolean']>;
  tags?: InputMaybe<Array<InputMaybe<TagInput>>>;
  text?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateNodeArgs = {
  icon?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  parent?: InputMaybe<Scalars['ID']>;
};


export type MutationUpdateSettingsArgs = {
  autoPurgeTrash?: InputMaybe<Scalars['Int']>;
  language?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  theme?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateTagArgs = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  label?: InputMaybe<Scalars['String']>;
  parent?: InputMaybe<Scalars['Int']>;
  private?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateUserArgs = {
  avatarExtension?: InputMaybe<Scalars['String']>;
  avatarPath?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type Node = {
  __typename?: 'Node';
  bookmarks: Array<Bookmark>;
  children: Array<Node>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  nodeType: NodeType;
  parent?: Maybe<Node>;
  tags: Array<Tag>;
};

export enum NodeType {
  Folder = 'FOLDER',
  Inbox = 'INBOX',
  Root = 'ROOT',
  Trash = 'TRASH'
}

export type PagedBookmarks = {
  __typename?: 'PagedBookmarks';
  bookmarks: Array<Bookmark>;
  total: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  GetAPIKey?: Maybe<ApiKey>;
  GetAPIKeys: Array<ApiKey>;
  GetAllBookmarks: PagedBookmarks;
  GetAllTags: Array<Tag>;
  GetBookmark?: Maybe<Bookmark>;
  GetBookmarksByNode: Array<Bookmark>;
  GetBookmarksByTag: PagedBookmarks;
  GetBookmarksByTagName: PagedBookmarks;
  GetFavoritesBookmarks: PagedBookmarks;
  GetHomeBookmarks: HomeBookmarks;
  GetInboxBookmarks: PagedBookmarks;
  GetLatestBookmarks: PagedBookmarks;
  GetMe: User;
  GetNode?: Maybe<Node>;
  GetNodesByParent: Array<Node>;
  GetNodesByTag: Array<Node>;
  GetScreenshotStatus: ScreenShotStatus;
  GetTag?: Maybe<Tag>;
  GetTagByLabel?: Maybe<Tag>;
  GetTagsByParent: Array<Tag>;
  GetTrashBookmarks: PagedBookmarks;
  Search: SearchResult;
};


export type QueryGetApiKeyArgs = {
  id: Scalars['ID'];
};


export type QueryGetApiKeysArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryGetAllBookmarksArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryGetAllTagsArgs = {
  order?: InputMaybe<Scalars['String']>;
};


export type QueryGetBookmarkArgs = {
  id: Scalars['ID'];
  includeTrash?: InputMaybe<Scalars['Boolean']>;
};


export type QueryGetBookmarksByNodeArgs = {
  id: Scalars['ID'];
};


export type QueryGetBookmarksByTagArgs = {
  id: Scalars['ID'];
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryGetBookmarksByTagNameArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryGetFavoritesBookmarksArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryGetInboxBookmarksArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryGetLatestBookmarksArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryGetNodeArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryGetNodesByParentArgs = {
  id: Scalars['ID'];
};


export type QueryGetNodesByTagArgs = {
  id: Scalars['ID'];
};


export type QueryGetScreenshotStatusArgs = {
  id: Scalars['ID'];
};


export type QueryGetTagArgs = {
  id: Scalars['ID'];
};


export type QueryGetTagByLabelArgs = {
  label: Scalars['String'];
};


export type QueryGetTagsByParentArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryGetTrashBookmarksArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QuerySearchArgs = {
  query: Scalars['String'];
};

export type ReadHistory = {
  __typename?: 'ReadHistory';
  readAt: Scalars['DateTime'];
};

export type ScreenShotStatus = {
  __typename?: 'ScreenShotStatus';
  id: Scalars['ID'];
  status: Scalars['Int'];
};

export type Screenshot = {
  __typename?: 'Screenshot';
  id: Scalars['ID'];
  path: Scalars['String'];
  status: Scalars['Int'];
};

export type SearchResult = {
  __typename?: 'SearchResult';
  bookmarks: Array<Bookmark>;
  tags: Array<Tag>;
};

export type Settings = {
  __typename?: 'Settings';
  autoPurgeTrash: Scalars['Int'];
  language: Scalars['String'];
  pageSize: Scalars['Int'];
  theme: Scalars['String'];
};

export type Tag = {
  __typename?: 'Tag';
  count: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  hasChildren: Scalars['Boolean'];
  label: Scalars['String'];
  parent?: Maybe<Scalars['ID']>;
  parents: Array<Tag>;
  path: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  value: Scalars['ID'];
};

export type TagInput = {
  label: Scalars['String'];
  value: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  avatar: Array<Maybe<Avatar>>;
  country?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  role: Scalars['String'];
  settings: Settings;
};

export type AddApiKeyMutationVariables = Exact<{
  name: Scalars['String'];
  expiresAt?: InputMaybe<Scalars['DateTime']>;
  allowedOrigins?: InputMaybe<Scalars['String']>;
}>;


export type AddApiKeyMutation = { __typename?: 'Mutation', AddAPIKey?: { __typename?: 'APIKey', id: string, name: string, key?: string | null, expiresAt?: any | null, allowedOrigins?: string | null } | null };

export type AddBookmarkMutationVariables = Exact<{
  url: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
}>;


export type AddBookmarkMutation = { __typename?: 'Mutation', AddBookmark?: { __typename?: 'Bookmark', id: string, icon?: string | null, title: string, text: string, url: string, node?: { __typename?: 'Node', id: string, name: string } | null } | null };

export type RegisterTokenMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type RegisterTokenMutation = { __typename?: 'Mutation', RegisterToken?: boolean | null };

export type DeleteApiKeyMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteApiKeyMutation = { __typename?: 'Mutation', DeleteAPIKey?: boolean | null };

export type DeleteBookmarkSoftMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteBookmarkSoftMutation = { __typename?: 'Mutation', DeleteBookmark?: boolean | null };

export type DeleteTagMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteTagMutation = { __typename?: 'Mutation', DeleteTag?: boolean | null };

export type EmptyTrashBookmarkMutationVariables = Exact<{ [key: string]: never; }>;


export type EmptyTrashBookmarkMutation = { __typename?: 'Mutation', EmptyTrashBookmark?: boolean | null };

export type CreateUserMutationVariables = Exact<{
  firebaseID: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', InitUser?: { __typename?: 'User', id: string } | null };

export type LoginUserMutationVariables = Exact<{
  firebaseID: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
}>;


export type LoginUserMutation = { __typename?: 'Mutation', LoginUser?: { __typename?: 'User', id: string } | null };

export type UpdateApiKeyMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
  expiresAt?: InputMaybe<Scalars['DateTime']>;
  allowedOrigins?: InputMaybe<Scalars['String']>;
}>;


export type UpdateApiKeyMutation = { __typename?: 'Mutation', UpdateAPIKey?: { __typename?: 'APIKey', id: string, name: string, key?: string | null, expiresAt?: any | null, allowedOrigins?: string | null } | null };

export type UpdateBookmarkMutationVariables = Exact<{
  id: Scalars['ID'];
  parent?: InputMaybe<Scalars['ID']>;
  title?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
  read?: InputMaybe<Scalars['Boolean']>;
  favorite?: InputMaybe<Scalars['Boolean']>;
  tags?: InputMaybe<Array<InputMaybe<TagInput>> | InputMaybe<TagInput>>;
}>;


export type UpdateBookmarkMutation = { __typename?: 'Mutation', UpdateBookmark?: { __typename?: 'Bookmark', id: string, title: string, favorite: boolean, text: string, icon?: string | null, url: string, read: boolean, node?: { __typename?: 'Node', id: string, name: string } | null, tags: Array<{ __typename?: 'Tag', value: string, label: string }> } | null };

export type UpdateSettingsMutationVariables = Exact<{
  language?: InputMaybe<Scalars['String']>;
  theme?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  autoPurgeTrash?: InputMaybe<Scalars['Int']>;
}>;


export type UpdateSettingsMutation = { __typename?: 'Mutation', UpdateSettings?: { __typename?: 'User', id: string, name: string, role: string, country?: string | null, avatar: Array<{ __typename?: 'Avatar', url: string, size: number } | null>, settings: { __typename?: 'Settings', language: string, pageSize: number, theme: string, autoPurgeTrash: number } } | null };

export type UpdateTagMutationVariables = Exact<{
  id: Scalars['ID'];
  label?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  parent?: InputMaybe<Scalars['Int']>;
  private?: InputMaybe<Scalars['Boolean']>;
}>;


export type UpdateTagMutation = { __typename?: 'Mutation', UpdateTag?: { __typename?: 'Tag', value: string, label: string, description: string, path: string } | null };

export type UpdateUserMutationVariables = Exact<{
  name?: InputMaybe<Scalars['String']>;
  avatarPath?: InputMaybe<Scalars['String']>;
  avatarExtension?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', UpdateUser?: { __typename?: 'User', id: string, name: string, role: string, country?: string | null, avatar: Array<{ __typename?: 'Avatar', url: string, size: number } | null> } | null };

export type GetAllTagsQueryVariables = Exact<{
  order?: InputMaybe<Scalars['String']>;
}>;


export type GetAllTagsQuery = { __typename?: 'Query', GetAllTags: Array<{ __typename?: 'Tag', value: string, label: string, path: string }> };

export type GetAllTagsCountQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllTagsCountQuery = { __typename?: 'Query', GetAllTags: Array<{ __typename?: 'Tag', value: string, label: string, count: number }> };

export type GetApiKeyQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetApiKeyQuery = { __typename?: 'Query', GetAPIKey?: { __typename?: 'APIKey', id: string, name: string, createdAt: any, expiresAt?: any | null, allowedOrigins?: string | null } | null };

export type GetBookmarkQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetBookmarkQuery = { __typename?: 'Query', GetBookmark?: { __typename?: 'Bookmark', id: string, title: string, text: string, url: string, favorite: boolean, screenshot: { __typename?: 'Screenshot', id: string, path: string, status: number }, tags: Array<{ __typename?: 'Tag', value: string, label: string, path: string }> } | null };

export type GetAllBookmarksQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
}>;


export type GetAllBookmarksQuery = { __typename?: 'Query', GetAllBookmarks: { __typename?: 'PagedBookmarks', total: number, bookmarks: Array<{ __typename?: 'Bookmark', id: string, title: string, text: string, url: string, createdAt: any, updatedAt: any, screenshot: { __typename?: 'Screenshot', id: string, path: string, status: number }, tags: Array<{ __typename?: 'Tag', value: string, label: string, path: string }> }> } };

export type GetBookmarksByTagNameQueryVariables = Exact<{
  name: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
}>;


export type GetBookmarksByTagNameQuery = { __typename?: 'Query', GetBookmarksByTagName: { __typename?: 'PagedBookmarks', total: number, bookmarks: Array<{ __typename?: 'Bookmark', id: string, title: string, text: string, url: string, createdAt: any, updatedAt: any, screenshot: { __typename?: 'Screenshot', id: string, path: string, status: number }, tags: Array<{ __typename?: 'Tag', value: string, label: string }> }> } };

export type GetFavoritesBookmarksQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
}>;


export type GetFavoritesBookmarksQuery = { __typename?: 'Query', GetFavoritesBookmarks: { __typename?: 'PagedBookmarks', total: number, bookmarks: Array<{ __typename?: 'Bookmark', id: string, title: string, text: string, url: string, createdAt: any, updatedAt: any, screenshot: { __typename?: 'Screenshot', id: string, path: string, status: number }, tags: Array<{ __typename?: 'Tag', value: string, label: string, path: string }> }> } };

export type GetHomeBookmarksQueryVariables = Exact<{ [key: string]: never; }>;


export type GetHomeBookmarksQuery = { __typename?: 'Query', GetHomeBookmarks: { __typename?: 'HomeBookmarks', inbox: Array<{ __typename?: 'Bookmark', id: string, title: string, text: string, url: string, createdAt: any, updatedAt: any, screenshot: { __typename?: 'Screenshot', id: string, path: string, status: number }, tags: Array<{ __typename?: 'Tag', value: string, label: string, path: string }> }>, latest: Array<{ __typename?: 'Bookmark', id: string, title: string, text: string, url: string, createdAt: any, updatedAt: any, screenshot: { __typename?: 'Screenshot', id: string, path: string, status: number }, tags: Array<{ __typename?: 'Tag', value: string, label: string, path: string }> }>, favorites: Array<{ __typename?: 'Bookmark', id: string, title: string, text: string, url: string, createdAt: any, updatedAt: any, screenshot: { __typename?: 'Screenshot', id: string, path: string, status: number }, tags: Array<{ __typename?: 'Tag', value: string, label: string, path: string }> }> } };

export type GetInboxBookmarksQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
}>;


export type GetInboxBookmarksQuery = { __typename?: 'Query', GetInboxBookmarks: { __typename?: 'PagedBookmarks', total: number, bookmarks: Array<{ __typename?: 'Bookmark', id: string, title: string, text: string, url: string, createdAt: any, updatedAt: any, screenshot: { __typename?: 'Screenshot', id: string, path: string, status: number }, tags: Array<{ __typename?: 'Tag', value: string, label: string, path: string }> }> } };

export type GetLatestBookmarksQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type GetLatestBookmarksQuery = { __typename?: 'Query', GetLatestBookmarks: { __typename?: 'PagedBookmarks', total: number, bookmarks: Array<{ __typename?: 'Bookmark', id: string, title: string, text: string, url: string, createdAt: any, updatedAt: any, screenshot: { __typename?: 'Screenshot', id: string, path: string, status: number }, tags: Array<{ __typename?: 'Tag', value: string, label: string, path: string }> }> } };

export type GetMeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMeQuery = { __typename?: 'Query', GetMe: { __typename?: 'User', id: string, name: string, role: string, country?: string | null, avatar: Array<{ __typename?: 'Avatar', url: string, size: number } | null>, settings: { __typename?: 'Settings', pageSize: number, language: string, theme: string, autoPurgeTrash: number } } };

export type GetRootNodeQueryVariables = Exact<{
  nodeID?: InputMaybe<Scalars['ID']>;
}>;


export type GetRootNodeQuery = { __typename?: 'Query', GetNode?: { __typename?: 'Node', id: string, name: string, children: Array<{ __typename?: 'Node', id: string, name: string }>, bookmarks: Array<{ __typename?: 'Bookmark', id: string, title: string, text: string }> } | null };

export type GetScreenshotStatusQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetScreenshotStatusQuery = { __typename?: 'Query', GetScreenshotStatus: { __typename?: 'ScreenShotStatus', id: string, status: number } };

export type GetTagByIdQueryVariables = Exact<{
  ID: Scalars['ID'];
}>;


export type GetTagByIdQuery = { __typename?: 'Query', GetTag?: { __typename?: 'Tag', value: string, label: string, description: string, parent?: string | null } | null };

export type GetTagByIdWithParentQueryVariables = Exact<{
  ID: Scalars['ID'];
}>;


export type GetTagByIdWithParentQuery = { __typename?: 'Query', GetTag?: { __typename?: 'Tag', value: string, label: string, description: string, path: string, parents: Array<{ __typename?: 'Tag', value: string, label: string, path: string }> } | null };

export type GetTagByLabelQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type GetTagByLabelQuery = { __typename?: 'Query', GetTagByLabel?: { __typename?: 'Tag', value: string, label: string, path: string } | null };

export type GetTagsByParentWithCountQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
}>;


export type GetTagsByParentWithCountQuery = { __typename?: 'Query', GetTagsByParent: Array<{ __typename?: 'Tag', value: string, label: string, path: string, hasChildren: boolean, count: number }> };

export type GetTrashBookmarksQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
}>;


export type GetTrashBookmarksQuery = { __typename?: 'Query', GetTrashBookmarks: { __typename?: 'PagedBookmarks', total: number, bookmarks: Array<{ __typename?: 'Bookmark', id: string, title: string, text: string, url: string, createdAt: any, updatedAt: any, screenshot: { __typename?: 'Screenshot', id: string, path: string, status: number }, tags: Array<{ __typename?: 'Tag', value: string, label: string, path: string }> }> } };

export type SearchQueryVariables = Exact<{
  query: Scalars['String'];
}>;


export type SearchQuery = { __typename?: 'Query', Search: { __typename?: 'SearchResult', tags: Array<{ __typename?: 'Tag', value: string, label: string, path: string }>, bookmarks: Array<{ __typename?: 'Bookmark', id: string, title: string, text: string, url: string }> } };

export type GetApiKeysQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
}>;


export type GetApiKeysQuery = { __typename?: 'Query', GetAPIKeys: Array<{ __typename?: 'APIKey', id: string, name: string, createdAt: any, expiresAt?: any | null }> };

export type GetBookmarksByTagQueryVariables = Exact<{
  id: Scalars['ID'];
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
}>;


export type GetBookmarksByTagQuery = { __typename?: 'Query', GetBookmarksByTag: { __typename?: 'PagedBookmarks', total: number, bookmarks: Array<{ __typename?: 'Bookmark', id: string, title: string, text: string, url: string, createdAt: any, updatedAt: any, screenshot: { __typename?: 'Screenshot', id: string, path: string, status: number }, tags: Array<{ __typename?: 'Tag', value: string, label: string, path: string }> }> } };


export const AddApiKeyDocument = gql`
    mutation AddApiKey($name: String!, $expiresAt: DateTime, $allowedOrigins: String) {
  AddAPIKey(name: $name, expiresAt: $expiresAt, allowedOrigins: $allowedOrigins) {
    id
    name
    key
    expiresAt
    allowedOrigins
  }
}
    `;
export type AddApiKeyMutationFn = Apollo.MutationFunction<AddApiKeyMutation, AddApiKeyMutationVariables>;

/**
 * __useAddApiKeyMutation__
 *
 * To run a mutation, you first call `useAddApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addApiKeyMutation, { data, loading, error }] = useAddApiKeyMutation({
 *   variables: {
 *      name: // value for 'name'
 *      expiresAt: // value for 'expiresAt'
 *      allowedOrigins: // value for 'allowedOrigins'
 *   },
 * });
 */
export function useAddApiKeyMutation(baseOptions?: Apollo.MutationHookOptions<AddApiKeyMutation, AddApiKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddApiKeyMutation, AddApiKeyMutationVariables>(AddApiKeyDocument, options);
      }
export type AddApiKeyMutationHookResult = ReturnType<typeof useAddApiKeyMutation>;
export type AddApiKeyMutationResult = Apollo.MutationResult<AddApiKeyMutation>;
export type AddApiKeyMutationOptions = Apollo.BaseMutationOptions<AddApiKeyMutation, AddApiKeyMutationVariables>;
export const AddBookmarkDocument = gql`
    mutation addBookmark($url: String!, $title: String, $text: String) {
  AddBookmark(url: $url, title: $title, text: $text) {
    id
    icon
    title
    text
    node {
      id
      name
    }
    url
  }
}
    `;
export type AddBookmarkMutationFn = Apollo.MutationFunction<AddBookmarkMutation, AddBookmarkMutationVariables>;

/**
 * __useAddBookmarkMutation__
 *
 * To run a mutation, you first call `useAddBookmarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBookmarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBookmarkMutation, { data, loading, error }] = useAddBookmarkMutation({
 *   variables: {
 *      url: // value for 'url'
 *      title: // value for 'title'
 *      text: // value for 'text'
 *   },
 * });
 */
export function useAddBookmarkMutation(baseOptions?: Apollo.MutationHookOptions<AddBookmarkMutation, AddBookmarkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddBookmarkMutation, AddBookmarkMutationVariables>(AddBookmarkDocument, options);
      }
export type AddBookmarkMutationHookResult = ReturnType<typeof useAddBookmarkMutation>;
export type AddBookmarkMutationResult = Apollo.MutationResult<AddBookmarkMutation>;
export type AddBookmarkMutationOptions = Apollo.BaseMutationOptions<AddBookmarkMutation, AddBookmarkMutationVariables>;
export const RegisterTokenDocument = gql`
    mutation RegisterToken($token: String!) {
  RegisterToken(token: $token)
}
    `;
export type RegisterTokenMutationFn = Apollo.MutationFunction<RegisterTokenMutation, RegisterTokenMutationVariables>;

/**
 * __useRegisterTokenMutation__
 *
 * To run a mutation, you first call `useRegisterTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerTokenMutation, { data, loading, error }] = useRegisterTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useRegisterTokenMutation(baseOptions?: Apollo.MutationHookOptions<RegisterTokenMutation, RegisterTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterTokenMutation, RegisterTokenMutationVariables>(RegisterTokenDocument, options);
      }
export type RegisterTokenMutationHookResult = ReturnType<typeof useRegisterTokenMutation>;
export type RegisterTokenMutationResult = Apollo.MutationResult<RegisterTokenMutation>;
export type RegisterTokenMutationOptions = Apollo.BaseMutationOptions<RegisterTokenMutation, RegisterTokenMutationVariables>;
export const DeleteApiKeyDocument = gql`
    mutation DeleteAPIKey($id: ID!) {
  DeleteAPIKey(id: $id)
}
    `;
export type DeleteApiKeyMutationFn = Apollo.MutationFunction<DeleteApiKeyMutation, DeleteApiKeyMutationVariables>;

/**
 * __useDeleteApiKeyMutation__
 *
 * To run a mutation, you first call `useDeleteApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApiKeyMutation, { data, loading, error }] = useDeleteApiKeyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteApiKeyMutation(baseOptions?: Apollo.MutationHookOptions<DeleteApiKeyMutation, DeleteApiKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteApiKeyMutation, DeleteApiKeyMutationVariables>(DeleteApiKeyDocument, options);
      }
export type DeleteApiKeyMutationHookResult = ReturnType<typeof useDeleteApiKeyMutation>;
export type DeleteApiKeyMutationResult = Apollo.MutationResult<DeleteApiKeyMutation>;
export type DeleteApiKeyMutationOptions = Apollo.BaseMutationOptions<DeleteApiKeyMutation, DeleteApiKeyMutationVariables>;
export const DeleteBookmarkSoftDocument = gql`
    mutation DeleteBookmarkSoft($id: ID!) {
  DeleteBookmark(id: $id)
}
    `;
export type DeleteBookmarkSoftMutationFn = Apollo.MutationFunction<DeleteBookmarkSoftMutation, DeleteBookmarkSoftMutationVariables>;

/**
 * __useDeleteBookmarkSoftMutation__
 *
 * To run a mutation, you first call `useDeleteBookmarkSoftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBookmarkSoftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBookmarkSoftMutation, { data, loading, error }] = useDeleteBookmarkSoftMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBookmarkSoftMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBookmarkSoftMutation, DeleteBookmarkSoftMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBookmarkSoftMutation, DeleteBookmarkSoftMutationVariables>(DeleteBookmarkSoftDocument, options);
      }
export type DeleteBookmarkSoftMutationHookResult = ReturnType<typeof useDeleteBookmarkSoftMutation>;
export type DeleteBookmarkSoftMutationResult = Apollo.MutationResult<DeleteBookmarkSoftMutation>;
export type DeleteBookmarkSoftMutationOptions = Apollo.BaseMutationOptions<DeleteBookmarkSoftMutation, DeleteBookmarkSoftMutationVariables>;
export const DeleteTagDocument = gql`
    mutation DeleteTag($id: ID!) {
  DeleteTag(id: $id)
}
    `;
export type DeleteTagMutationFn = Apollo.MutationFunction<DeleteTagMutation, DeleteTagMutationVariables>;

/**
 * __useDeleteTagMutation__
 *
 * To run a mutation, you first call `useDeleteTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTagMutation, { data, loading, error }] = useDeleteTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTagMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTagMutation, DeleteTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTagMutation, DeleteTagMutationVariables>(DeleteTagDocument, options);
      }
export type DeleteTagMutationHookResult = ReturnType<typeof useDeleteTagMutation>;
export type DeleteTagMutationResult = Apollo.MutationResult<DeleteTagMutation>;
export type DeleteTagMutationOptions = Apollo.BaseMutationOptions<DeleteTagMutation, DeleteTagMutationVariables>;
export const EmptyTrashBookmarkDocument = gql`
    mutation EmptyTrashBookmark {
  EmptyTrashBookmark
}
    `;
export type EmptyTrashBookmarkMutationFn = Apollo.MutationFunction<EmptyTrashBookmarkMutation, EmptyTrashBookmarkMutationVariables>;

/**
 * __useEmptyTrashBookmarkMutation__
 *
 * To run a mutation, you first call `useEmptyTrashBookmarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmptyTrashBookmarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emptyTrashBookmarkMutation, { data, loading, error }] = useEmptyTrashBookmarkMutation({
 *   variables: {
 *   },
 * });
 */
export function useEmptyTrashBookmarkMutation(baseOptions?: Apollo.MutationHookOptions<EmptyTrashBookmarkMutation, EmptyTrashBookmarkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EmptyTrashBookmarkMutation, EmptyTrashBookmarkMutationVariables>(EmptyTrashBookmarkDocument, options);
      }
export type EmptyTrashBookmarkMutationHookResult = ReturnType<typeof useEmptyTrashBookmarkMutation>;
export type EmptyTrashBookmarkMutationResult = Apollo.MutationResult<EmptyTrashBookmarkMutation>;
export type EmptyTrashBookmarkMutationOptions = Apollo.BaseMutationOptions<EmptyTrashBookmarkMutation, EmptyTrashBookmarkMutationVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($firebaseID: String!, $name: String) {
  InitUser(fireID: $firebaseID, name: $name) {
    id
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      firebaseID: // value for 'firebaseID'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const LoginUserDocument = gql`
    mutation LoginUser($firebaseID: String!, $name: String) {
  LoginUser(fireID: $firebaseID, name: $name) {
    id
  }
}
    `;
export type LoginUserMutationFn = Apollo.MutationFunction<LoginUserMutation, LoginUserMutationVariables>;

/**
 * __useLoginUserMutation__
 *
 * To run a mutation, you first call `useLoginUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginUserMutation, { data, loading, error }] = useLoginUserMutation({
 *   variables: {
 *      firebaseID: // value for 'firebaseID'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useLoginUserMutation(baseOptions?: Apollo.MutationHookOptions<LoginUserMutation, LoginUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginUserMutation, LoginUserMutationVariables>(LoginUserDocument, options);
      }
export type LoginUserMutationHookResult = ReturnType<typeof useLoginUserMutation>;
export type LoginUserMutationResult = Apollo.MutationResult<LoginUserMutation>;
export type LoginUserMutationOptions = Apollo.BaseMutationOptions<LoginUserMutation, LoginUserMutationVariables>;
export const UpdateApiKeyDocument = gql`
    mutation UpdateAPIKey($id: ID!, $name: String!, $expiresAt: DateTime, $allowedOrigins: String) {
  UpdateAPIKey(
    id: $id
    name: $name
    expiresAt: $expiresAt
    origins: $allowedOrigins
  ) {
    id
    name
    key
    expiresAt
    allowedOrigins
  }
}
    `;
export type UpdateApiKeyMutationFn = Apollo.MutationFunction<UpdateApiKeyMutation, UpdateApiKeyMutationVariables>;

/**
 * __useUpdateApiKeyMutation__
 *
 * To run a mutation, you first call `useUpdateApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApiKeyMutation, { data, loading, error }] = useUpdateApiKeyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      expiresAt: // value for 'expiresAt'
 *      allowedOrigins: // value for 'allowedOrigins'
 *   },
 * });
 */
export function useUpdateApiKeyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateApiKeyMutation, UpdateApiKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateApiKeyMutation, UpdateApiKeyMutationVariables>(UpdateApiKeyDocument, options);
      }
export type UpdateApiKeyMutationHookResult = ReturnType<typeof useUpdateApiKeyMutation>;
export type UpdateApiKeyMutationResult = Apollo.MutationResult<UpdateApiKeyMutation>;
export type UpdateApiKeyMutationOptions = Apollo.BaseMutationOptions<UpdateApiKeyMutation, UpdateApiKeyMutationVariables>;
export const UpdateBookmarkDocument = gql`
    mutation UpdateBookmark($id: ID!, $parent: ID, $title: String, $text: String, $icon: String, $url: String, $read: Boolean, $favorite: Boolean, $tags: [TagInput]) {
  UpdateBookmark(
    id: $id
    parent: $parent
    title: $title
    text: $text
    icon: $icon
    url: $url
    read: $read
    tags: $tags
    favorite: $favorite
  ) {
    id
    node {
      id
      name
    }
    title
    favorite
    text
    icon
    url
    read
    tags {
      value
      label
    }
  }
}
    `;
export type UpdateBookmarkMutationFn = Apollo.MutationFunction<UpdateBookmarkMutation, UpdateBookmarkMutationVariables>;

/**
 * __useUpdateBookmarkMutation__
 *
 * To run a mutation, you first call `useUpdateBookmarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBookmarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBookmarkMutation, { data, loading, error }] = useUpdateBookmarkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      parent: // value for 'parent'
 *      title: // value for 'title'
 *      text: // value for 'text'
 *      icon: // value for 'icon'
 *      url: // value for 'url'
 *      read: // value for 'read'
 *      favorite: // value for 'favorite'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useUpdateBookmarkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBookmarkMutation, UpdateBookmarkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBookmarkMutation, UpdateBookmarkMutationVariables>(UpdateBookmarkDocument, options);
      }
export type UpdateBookmarkMutationHookResult = ReturnType<typeof useUpdateBookmarkMutation>;
export type UpdateBookmarkMutationResult = Apollo.MutationResult<UpdateBookmarkMutation>;
export type UpdateBookmarkMutationOptions = Apollo.BaseMutationOptions<UpdateBookmarkMutation, UpdateBookmarkMutationVariables>;
export const UpdateSettingsDocument = gql`
    mutation UpdateSettings($language: String, $theme: String, $pageSize: Int, $autoPurgeTrash: Int) {
  UpdateSettings(
    language: $language
    theme: $theme
    pageSize: $pageSize
    autoPurgeTrash: $autoPurgeTrash
  ) {
    id
    name
    role
    country
    avatar {
      url
      size
    }
    settings {
      language
      pageSize
      theme
      autoPurgeTrash
    }
  }
}
    `;
export type UpdateSettingsMutationFn = Apollo.MutationFunction<UpdateSettingsMutation, UpdateSettingsMutationVariables>;

/**
 * __useUpdateSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSettingsMutation, { data, loading, error }] = useUpdateSettingsMutation({
 *   variables: {
 *      language: // value for 'language'
 *      theme: // value for 'theme'
 *      pageSize: // value for 'pageSize'
 *      autoPurgeTrash: // value for 'autoPurgeTrash'
 *   },
 * });
 */
export function useUpdateSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSettingsMutation, UpdateSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSettingsMutation, UpdateSettingsMutationVariables>(UpdateSettingsDocument, options);
      }
export type UpdateSettingsMutationHookResult = ReturnType<typeof useUpdateSettingsMutation>;
export type UpdateSettingsMutationResult = Apollo.MutationResult<UpdateSettingsMutation>;
export type UpdateSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateSettingsMutation, UpdateSettingsMutationVariables>;
export const UpdateTagDocument = gql`
    mutation UpdateTag($id: ID!, $label: String, $description: String, $parent: Int, $private: Boolean) {
  UpdateTag(
    id: $id
    label: $label
    description: $description
    parent: $parent
    private: $private
  ) {
    value
    label
    description
    path
  }
}
    `;
export type UpdateTagMutationFn = Apollo.MutationFunction<UpdateTagMutation, UpdateTagMutationVariables>;

/**
 * __useUpdateTagMutation__
 *
 * To run a mutation, you first call `useUpdateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTagMutation, { data, loading, error }] = useUpdateTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *      label: // value for 'label'
 *      description: // value for 'description'
 *      parent: // value for 'parent'
 *      private: // value for 'private'
 *   },
 * });
 */
export function useUpdateTagMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTagMutation, UpdateTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTagMutation, UpdateTagMutationVariables>(UpdateTagDocument, options);
      }
export type UpdateTagMutationHookResult = ReturnType<typeof useUpdateTagMutation>;
export type UpdateTagMutationResult = Apollo.MutationResult<UpdateTagMutation>;
export type UpdateTagMutationOptions = Apollo.BaseMutationOptions<UpdateTagMutation, UpdateTagMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($name: String, $avatarPath: String, $avatarExtension: String, $country: String) {
  UpdateUser(
    name: $name
    country: $country
    avatarPath: $avatarPath
    avatarExtension: $avatarExtension
  ) {
    id
    name
    role
    country
    avatar {
      url
      size
    }
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      name: // value for 'name'
 *      avatarPath: // value for 'avatarPath'
 *      avatarExtension: // value for 'avatarExtension'
 *      country: // value for 'country'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const GetAllTagsDocument = gql`
    query GetAllTags($order: String) {
  GetAllTags(order: $order) {
    value
    label
    path
  }
}
    `;

/**
 * __useGetAllTagsQuery__
 *
 * To run a query within a React component, call `useGetAllTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllTagsQuery({
 *   variables: {
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetAllTagsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllTagsQuery, GetAllTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllTagsQuery, GetAllTagsQueryVariables>(GetAllTagsDocument, options);
      }
export function useGetAllTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllTagsQuery, GetAllTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllTagsQuery, GetAllTagsQueryVariables>(GetAllTagsDocument, options);
        }
export type GetAllTagsQueryHookResult = ReturnType<typeof useGetAllTagsQuery>;
export type GetAllTagsLazyQueryHookResult = ReturnType<typeof useGetAllTagsLazyQuery>;
export type GetAllTagsQueryResult = Apollo.QueryResult<GetAllTagsQuery, GetAllTagsQueryVariables>;
export const GetAllTagsCountDocument = gql`
    query GetAllTagsCount {
  GetAllTags {
    value
    label
    count
  }
}
    `;

/**
 * __useGetAllTagsCountQuery__
 *
 * To run a query within a React component, call `useGetAllTagsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllTagsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllTagsCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllTagsCountQuery(baseOptions?: Apollo.QueryHookOptions<GetAllTagsCountQuery, GetAllTagsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllTagsCountQuery, GetAllTagsCountQueryVariables>(GetAllTagsCountDocument, options);
      }
export function useGetAllTagsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllTagsCountQuery, GetAllTagsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllTagsCountQuery, GetAllTagsCountQueryVariables>(GetAllTagsCountDocument, options);
        }
export type GetAllTagsCountQueryHookResult = ReturnType<typeof useGetAllTagsCountQuery>;
export type GetAllTagsCountLazyQueryHookResult = ReturnType<typeof useGetAllTagsCountLazyQuery>;
export type GetAllTagsCountQueryResult = Apollo.QueryResult<GetAllTagsCountQuery, GetAllTagsCountQueryVariables>;
export const GetApiKeyDocument = gql`
    query GetAPIKey($id: ID!) {
  GetAPIKey(id: $id) {
    id
    name
    createdAt
    expiresAt
    allowedOrigins
  }
}
    `;

/**
 * __useGetApiKeyQuery__
 *
 * To run a query within a React component, call `useGetApiKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApiKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApiKeyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetApiKeyQuery(baseOptions: Apollo.QueryHookOptions<GetApiKeyQuery, GetApiKeyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetApiKeyQuery, GetApiKeyQueryVariables>(GetApiKeyDocument, options);
      }
export function useGetApiKeyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetApiKeyQuery, GetApiKeyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetApiKeyQuery, GetApiKeyQueryVariables>(GetApiKeyDocument, options);
        }
export type GetApiKeyQueryHookResult = ReturnType<typeof useGetApiKeyQuery>;
export type GetApiKeyLazyQueryHookResult = ReturnType<typeof useGetApiKeyLazyQuery>;
export type GetApiKeyQueryResult = Apollo.QueryResult<GetApiKeyQuery, GetApiKeyQueryVariables>;
export const GetBookmarkDocument = gql`
    query GetBookmark($id: ID!) {
  GetBookmark(id: $id) {
    id
    title
    text
    url
    favorite
    screenshot {
      id
      path
      status
    }
    tags {
      value
      label
      path
    }
  }
}
    `;

/**
 * __useGetBookmarkQuery__
 *
 * To run a query within a React component, call `useGetBookmarkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookmarkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookmarkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBookmarkQuery(baseOptions: Apollo.QueryHookOptions<GetBookmarkQuery, GetBookmarkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBookmarkQuery, GetBookmarkQueryVariables>(GetBookmarkDocument, options);
      }
export function useGetBookmarkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBookmarkQuery, GetBookmarkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBookmarkQuery, GetBookmarkQueryVariables>(GetBookmarkDocument, options);
        }
export type GetBookmarkQueryHookResult = ReturnType<typeof useGetBookmarkQuery>;
export type GetBookmarkLazyQueryHookResult = ReturnType<typeof useGetBookmarkLazyQuery>;
export type GetBookmarkQueryResult = Apollo.QueryResult<GetBookmarkQuery, GetBookmarkQueryVariables>;
export const GetAllBookmarksDocument = gql`
    query GetAllBookmarks($limit: Int, $page: Int) {
  GetAllBookmarks(limit: $limit, page: $page) {
    total
    bookmarks {
      id
      title
      text
      screenshot {
        id
        path
        status
      }
      url
      createdAt
      updatedAt
      tags {
        value
        label
        path
      }
    }
  }
}
    `;

/**
 * __useGetAllBookmarksQuery__
 *
 * To run a query within a React component, call `useGetAllBookmarksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllBookmarksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllBookmarksQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetAllBookmarksQuery(baseOptions?: Apollo.QueryHookOptions<GetAllBookmarksQuery, GetAllBookmarksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllBookmarksQuery, GetAllBookmarksQueryVariables>(GetAllBookmarksDocument, options);
      }
export function useGetAllBookmarksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllBookmarksQuery, GetAllBookmarksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllBookmarksQuery, GetAllBookmarksQueryVariables>(GetAllBookmarksDocument, options);
        }
export type GetAllBookmarksQueryHookResult = ReturnType<typeof useGetAllBookmarksQuery>;
export type GetAllBookmarksLazyQueryHookResult = ReturnType<typeof useGetAllBookmarksLazyQuery>;
export type GetAllBookmarksQueryResult = Apollo.QueryResult<GetAllBookmarksQuery, GetAllBookmarksQueryVariables>;
export const GetBookmarksByTagNameDocument = gql`
    query GetBookmarksByTagName($name: String!, $limit: Int, $page: Int) {
  GetBookmarksByTagName(name: $name, limit: $limit, page: $page) {
    total
    bookmarks {
      id
      title
      text
      screenshot {
        id
        path
        status
      }
      url
      createdAt
      updatedAt
      tags {
        value
        label
      }
    }
  }
}
    `;

/**
 * __useGetBookmarksByTagNameQuery__
 *
 * To run a query within a React component, call `useGetBookmarksByTagNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookmarksByTagNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookmarksByTagNameQuery({
 *   variables: {
 *      name: // value for 'name'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetBookmarksByTagNameQuery(baseOptions: Apollo.QueryHookOptions<GetBookmarksByTagNameQuery, GetBookmarksByTagNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBookmarksByTagNameQuery, GetBookmarksByTagNameQueryVariables>(GetBookmarksByTagNameDocument, options);
      }
export function useGetBookmarksByTagNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBookmarksByTagNameQuery, GetBookmarksByTagNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBookmarksByTagNameQuery, GetBookmarksByTagNameQueryVariables>(GetBookmarksByTagNameDocument, options);
        }
export type GetBookmarksByTagNameQueryHookResult = ReturnType<typeof useGetBookmarksByTagNameQuery>;
export type GetBookmarksByTagNameLazyQueryHookResult = ReturnType<typeof useGetBookmarksByTagNameLazyQuery>;
export type GetBookmarksByTagNameQueryResult = Apollo.QueryResult<GetBookmarksByTagNameQuery, GetBookmarksByTagNameQueryVariables>;
export const GetFavoritesBookmarksDocument = gql`
    query GetFavoritesBookmarks($limit: Int, $page: Int) {
  GetFavoritesBookmarks(limit: $limit, page: $page) {
    total
    bookmarks {
      id
      title
      text
      screenshot {
        id
        path
        status
      }
      url
      createdAt
      updatedAt
      tags {
        value
        label
        path
      }
    }
  }
}
    `;

/**
 * __useGetFavoritesBookmarksQuery__
 *
 * To run a query within a React component, call `useGetFavoritesBookmarksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFavoritesBookmarksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFavoritesBookmarksQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetFavoritesBookmarksQuery(baseOptions?: Apollo.QueryHookOptions<GetFavoritesBookmarksQuery, GetFavoritesBookmarksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFavoritesBookmarksQuery, GetFavoritesBookmarksQueryVariables>(GetFavoritesBookmarksDocument, options);
      }
export function useGetFavoritesBookmarksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFavoritesBookmarksQuery, GetFavoritesBookmarksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFavoritesBookmarksQuery, GetFavoritesBookmarksQueryVariables>(GetFavoritesBookmarksDocument, options);
        }
export type GetFavoritesBookmarksQueryHookResult = ReturnType<typeof useGetFavoritesBookmarksQuery>;
export type GetFavoritesBookmarksLazyQueryHookResult = ReturnType<typeof useGetFavoritesBookmarksLazyQuery>;
export type GetFavoritesBookmarksQueryResult = Apollo.QueryResult<GetFavoritesBookmarksQuery, GetFavoritesBookmarksQueryVariables>;
export const GetHomeBookmarksDocument = gql`
    query GetHomeBookmarks {
  GetHomeBookmarks {
    inbox {
      id
      title
      text
      screenshot {
        id
        path
        status
      }
      url
      createdAt
      updatedAt
      tags {
        value
        label
        path
      }
    }
    latest {
      id
      title
      text
      screenshot {
        id
        path
        status
      }
      url
      createdAt
      updatedAt
      tags {
        value
        label
        path
      }
    }
    favorites {
      id
      title
      text
      screenshot {
        id
        path
        status
      }
      url
      createdAt
      updatedAt
      tags {
        value
        label
        path
      }
    }
  }
}
    `;

/**
 * __useGetHomeBookmarksQuery__
 *
 * To run a query within a React component, call `useGetHomeBookmarksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHomeBookmarksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHomeBookmarksQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetHomeBookmarksQuery(baseOptions?: Apollo.QueryHookOptions<GetHomeBookmarksQuery, GetHomeBookmarksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHomeBookmarksQuery, GetHomeBookmarksQueryVariables>(GetHomeBookmarksDocument, options);
      }
export function useGetHomeBookmarksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHomeBookmarksQuery, GetHomeBookmarksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHomeBookmarksQuery, GetHomeBookmarksQueryVariables>(GetHomeBookmarksDocument, options);
        }
export type GetHomeBookmarksQueryHookResult = ReturnType<typeof useGetHomeBookmarksQuery>;
export type GetHomeBookmarksLazyQueryHookResult = ReturnType<typeof useGetHomeBookmarksLazyQuery>;
export type GetHomeBookmarksQueryResult = Apollo.QueryResult<GetHomeBookmarksQuery, GetHomeBookmarksQueryVariables>;
export const GetInboxBookmarksDocument = gql`
    query GetInboxBookmarks($limit: Int, $page: Int) {
  GetInboxBookmarks(limit: $limit, page: $page) {
    total
    bookmarks {
      id
      title
      text
      screenshot {
        id
        path
        status
      }
      url
      createdAt
      updatedAt
      tags {
        value
        label
        path
      }
    }
  }
}
    `;

/**
 * __useGetInboxBookmarksQuery__
 *
 * To run a query within a React component, call `useGetInboxBookmarksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInboxBookmarksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInboxBookmarksQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetInboxBookmarksQuery(baseOptions?: Apollo.QueryHookOptions<GetInboxBookmarksQuery, GetInboxBookmarksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInboxBookmarksQuery, GetInboxBookmarksQueryVariables>(GetInboxBookmarksDocument, options);
      }
export function useGetInboxBookmarksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInboxBookmarksQuery, GetInboxBookmarksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInboxBookmarksQuery, GetInboxBookmarksQueryVariables>(GetInboxBookmarksDocument, options);
        }
export type GetInboxBookmarksQueryHookResult = ReturnType<typeof useGetInboxBookmarksQuery>;
export type GetInboxBookmarksLazyQueryHookResult = ReturnType<typeof useGetInboxBookmarksLazyQuery>;
export type GetInboxBookmarksQueryResult = Apollo.QueryResult<GetInboxBookmarksQuery, GetInboxBookmarksQueryVariables>;
export const GetLatestBookmarksDocument = gql`
    query GetLatestBookmarks($limit: Int) {
  GetLatestBookmarks(limit: $limit) {
    total
    bookmarks {
      id
      title
      text
      screenshot {
        id
        path
        status
      }
      url
      createdAt
      updatedAt
      tags {
        value
        label
        path
      }
    }
  }
}
    `;

/**
 * __useGetLatestBookmarksQuery__
 *
 * To run a query within a React component, call `useGetLatestBookmarksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestBookmarksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestBookmarksQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetLatestBookmarksQuery(baseOptions?: Apollo.QueryHookOptions<GetLatestBookmarksQuery, GetLatestBookmarksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLatestBookmarksQuery, GetLatestBookmarksQueryVariables>(GetLatestBookmarksDocument, options);
      }
export function useGetLatestBookmarksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLatestBookmarksQuery, GetLatestBookmarksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLatestBookmarksQuery, GetLatestBookmarksQueryVariables>(GetLatestBookmarksDocument, options);
        }
export type GetLatestBookmarksQueryHookResult = ReturnType<typeof useGetLatestBookmarksQuery>;
export type GetLatestBookmarksLazyQueryHookResult = ReturnType<typeof useGetLatestBookmarksLazyQuery>;
export type GetLatestBookmarksQueryResult = Apollo.QueryResult<GetLatestBookmarksQuery, GetLatestBookmarksQueryVariables>;
export const GetMeDocument = gql`
    query getMe {
  GetMe {
    id
    name
    role
    country
    avatar {
      url
      size
    }
    settings {
      pageSize
      language
      theme
      autoPurgeTrash
    }
  }
}
    `;

/**
 * __useGetMeQuery__
 *
 * To run a query within a React component, call `useGetMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeQuery(baseOptions?: Apollo.QueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
      }
export function useGetMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
        }
export type GetMeQueryHookResult = ReturnType<typeof useGetMeQuery>;
export type GetMeLazyQueryHookResult = ReturnType<typeof useGetMeLazyQuery>;
export type GetMeQueryResult = Apollo.QueryResult<GetMeQuery, GetMeQueryVariables>;
export const GetRootNodeDocument = gql`
    query GetRootNode($nodeID: ID) {
  GetNode(id: $nodeID) {
    id
    name
    children {
      id
      name
    }
    bookmarks {
      id
      title
      text
    }
  }
}
    `;

/**
 * __useGetRootNodeQuery__
 *
 * To run a query within a React component, call `useGetRootNodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRootNodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRootNodeQuery({
 *   variables: {
 *      nodeID: // value for 'nodeID'
 *   },
 * });
 */
export function useGetRootNodeQuery(baseOptions?: Apollo.QueryHookOptions<GetRootNodeQuery, GetRootNodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRootNodeQuery, GetRootNodeQueryVariables>(GetRootNodeDocument, options);
      }
export function useGetRootNodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRootNodeQuery, GetRootNodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRootNodeQuery, GetRootNodeQueryVariables>(GetRootNodeDocument, options);
        }
export type GetRootNodeQueryHookResult = ReturnType<typeof useGetRootNodeQuery>;
export type GetRootNodeLazyQueryHookResult = ReturnType<typeof useGetRootNodeLazyQuery>;
export type GetRootNodeQueryResult = Apollo.QueryResult<GetRootNodeQuery, GetRootNodeQueryVariables>;
export const GetScreenshotStatusDocument = gql`
    query GetScreenshotStatus($id: ID!) {
  GetScreenshotStatus(id: $id) {
    id
    status
  }
}
    `;

/**
 * __useGetScreenshotStatusQuery__
 *
 * To run a query within a React component, call `useGetScreenshotStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScreenshotStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScreenshotStatusQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetScreenshotStatusQuery(baseOptions: Apollo.QueryHookOptions<GetScreenshotStatusQuery, GetScreenshotStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetScreenshotStatusQuery, GetScreenshotStatusQueryVariables>(GetScreenshotStatusDocument, options);
      }
export function useGetScreenshotStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetScreenshotStatusQuery, GetScreenshotStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetScreenshotStatusQuery, GetScreenshotStatusQueryVariables>(GetScreenshotStatusDocument, options);
        }
export type GetScreenshotStatusQueryHookResult = ReturnType<typeof useGetScreenshotStatusQuery>;
export type GetScreenshotStatusLazyQueryHookResult = ReturnType<typeof useGetScreenshotStatusLazyQuery>;
export type GetScreenshotStatusQueryResult = Apollo.QueryResult<GetScreenshotStatusQuery, GetScreenshotStatusQueryVariables>;
export const GetTagByIdDocument = gql`
    query GetTagByID($ID: ID!) {
  GetTag(id: $ID) {
    value
    label
    description
    parent
  }
}
    `;

/**
 * __useGetTagByIdQuery__
 *
 * To run a query within a React component, call `useGetTagByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTagByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTagByIdQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetTagByIdQuery(baseOptions: Apollo.QueryHookOptions<GetTagByIdQuery, GetTagByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTagByIdQuery, GetTagByIdQueryVariables>(GetTagByIdDocument, options);
      }
export function useGetTagByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTagByIdQuery, GetTagByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTagByIdQuery, GetTagByIdQueryVariables>(GetTagByIdDocument, options);
        }
export type GetTagByIdQueryHookResult = ReturnType<typeof useGetTagByIdQuery>;
export type GetTagByIdLazyQueryHookResult = ReturnType<typeof useGetTagByIdLazyQuery>;
export type GetTagByIdQueryResult = Apollo.QueryResult<GetTagByIdQuery, GetTagByIdQueryVariables>;
export const GetTagByIdWithParentDocument = gql`
    query GetTagByIDWithParent($ID: ID!) {
  GetTag(id: $ID) {
    value
    label
    description
    path
    parents {
      value
      label
      path
    }
  }
}
    `;

/**
 * __useGetTagByIdWithParentQuery__
 *
 * To run a query within a React component, call `useGetTagByIdWithParentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTagByIdWithParentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTagByIdWithParentQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetTagByIdWithParentQuery(baseOptions: Apollo.QueryHookOptions<GetTagByIdWithParentQuery, GetTagByIdWithParentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTagByIdWithParentQuery, GetTagByIdWithParentQueryVariables>(GetTagByIdWithParentDocument, options);
      }
export function useGetTagByIdWithParentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTagByIdWithParentQuery, GetTagByIdWithParentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTagByIdWithParentQuery, GetTagByIdWithParentQueryVariables>(GetTagByIdWithParentDocument, options);
        }
export type GetTagByIdWithParentQueryHookResult = ReturnType<typeof useGetTagByIdWithParentQuery>;
export type GetTagByIdWithParentLazyQueryHookResult = ReturnType<typeof useGetTagByIdWithParentLazyQuery>;
export type GetTagByIdWithParentQueryResult = Apollo.QueryResult<GetTagByIdWithParentQuery, GetTagByIdWithParentQueryVariables>;
export const GetTagByLabelDocument = gql`
    query GetTagByLabel($name: String!) {
  GetTagByLabel(label: $name) {
    value
    label
    path
  }
}
    `;

/**
 * __useGetTagByLabelQuery__
 *
 * To run a query within a React component, call `useGetTagByLabelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTagByLabelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTagByLabelQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGetTagByLabelQuery(baseOptions: Apollo.QueryHookOptions<GetTagByLabelQuery, GetTagByLabelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTagByLabelQuery, GetTagByLabelQueryVariables>(GetTagByLabelDocument, options);
      }
export function useGetTagByLabelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTagByLabelQuery, GetTagByLabelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTagByLabelQuery, GetTagByLabelQueryVariables>(GetTagByLabelDocument, options);
        }
export type GetTagByLabelQueryHookResult = ReturnType<typeof useGetTagByLabelQuery>;
export type GetTagByLabelLazyQueryHookResult = ReturnType<typeof useGetTagByLabelLazyQuery>;
export type GetTagByLabelQueryResult = Apollo.QueryResult<GetTagByLabelQuery, GetTagByLabelQueryVariables>;
export const GetTagsByParentWithCountDocument = gql`
    query GetTagsByParentWithCount($id: ID) {
  GetTagsByParent(id: $id) {
    value
    label
    path
    hasChildren
    count
  }
}
    `;

/**
 * __useGetTagsByParentWithCountQuery__
 *
 * To run a query within a React component, call `useGetTagsByParentWithCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTagsByParentWithCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTagsByParentWithCountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTagsByParentWithCountQuery(baseOptions?: Apollo.QueryHookOptions<GetTagsByParentWithCountQuery, GetTagsByParentWithCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTagsByParentWithCountQuery, GetTagsByParentWithCountQueryVariables>(GetTagsByParentWithCountDocument, options);
      }
export function useGetTagsByParentWithCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTagsByParentWithCountQuery, GetTagsByParentWithCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTagsByParentWithCountQuery, GetTagsByParentWithCountQueryVariables>(GetTagsByParentWithCountDocument, options);
        }
export type GetTagsByParentWithCountQueryHookResult = ReturnType<typeof useGetTagsByParentWithCountQuery>;
export type GetTagsByParentWithCountLazyQueryHookResult = ReturnType<typeof useGetTagsByParentWithCountLazyQuery>;
export type GetTagsByParentWithCountQueryResult = Apollo.QueryResult<GetTagsByParentWithCountQuery, GetTagsByParentWithCountQueryVariables>;
export const GetTrashBookmarksDocument = gql`
    query GetTrashBookmarks($limit: Int, $page: Int) {
  GetTrashBookmarks(limit: $limit, page: $page) {
    total
    bookmarks {
      id
      title
      text
      screenshot {
        id
        path
        status
      }
      url
      createdAt
      updatedAt
      tags {
        value
        label
        path
      }
    }
  }
}
    `;

/**
 * __useGetTrashBookmarksQuery__
 *
 * To run a query within a React component, call `useGetTrashBookmarksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTrashBookmarksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTrashBookmarksQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetTrashBookmarksQuery(baseOptions?: Apollo.QueryHookOptions<GetTrashBookmarksQuery, GetTrashBookmarksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTrashBookmarksQuery, GetTrashBookmarksQueryVariables>(GetTrashBookmarksDocument, options);
      }
export function useGetTrashBookmarksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTrashBookmarksQuery, GetTrashBookmarksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTrashBookmarksQuery, GetTrashBookmarksQueryVariables>(GetTrashBookmarksDocument, options);
        }
export type GetTrashBookmarksQueryHookResult = ReturnType<typeof useGetTrashBookmarksQuery>;
export type GetTrashBookmarksLazyQueryHookResult = ReturnType<typeof useGetTrashBookmarksLazyQuery>;
export type GetTrashBookmarksQueryResult = Apollo.QueryResult<GetTrashBookmarksQuery, GetTrashBookmarksQueryVariables>;
export const SearchDocument = gql`
    query Search($query: String!) {
  Search(query: $query) {
    tags {
      value
      label
      path
    }
    bookmarks {
      id
      title
      text
      url
    }
  }
}
    `;

/**
 * __useSearchQuery__
 *
 * To run a query within a React component, call `useSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchQuery(baseOptions: Apollo.QueryHookOptions<SearchQuery, SearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchQuery, SearchQueryVariables>(SearchDocument, options);
      }
export function useSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchQuery, SearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchQuery, SearchQueryVariables>(SearchDocument, options);
        }
export type SearchQueryHookResult = ReturnType<typeof useSearchQuery>;
export type SearchLazyQueryHookResult = ReturnType<typeof useSearchLazyQuery>;
export type SearchQueryResult = Apollo.QueryResult<SearchQuery, SearchQueryVariables>;
export const GetApiKeysDocument = gql`
    query GetAPIKeys($id: ID) {
  GetAPIKeys(id: $id) {
    id
    name
    createdAt
    expiresAt
  }
}
    `;

/**
 * __useGetApiKeysQuery__
 *
 * To run a query within a React component, call `useGetApiKeysQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApiKeysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApiKeysQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetApiKeysQuery(baseOptions?: Apollo.QueryHookOptions<GetApiKeysQuery, GetApiKeysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetApiKeysQuery, GetApiKeysQueryVariables>(GetApiKeysDocument, options);
      }
export function useGetApiKeysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetApiKeysQuery, GetApiKeysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetApiKeysQuery, GetApiKeysQueryVariables>(GetApiKeysDocument, options);
        }
export type GetApiKeysQueryHookResult = ReturnType<typeof useGetApiKeysQuery>;
export type GetApiKeysLazyQueryHookResult = ReturnType<typeof useGetApiKeysLazyQuery>;
export type GetApiKeysQueryResult = Apollo.QueryResult<GetApiKeysQuery, GetApiKeysQueryVariables>;
export const GetBookmarksByTagDocument = gql`
    query GetBookmarksByTag($id: ID!, $limit: Int, $page: Int) {
  GetBookmarksByTag(id: $id, limit: $limit, page: $page) {
    total
    bookmarks {
      id
      title
      text
      screenshot {
        id
        path
        status
      }
      url
      createdAt
      updatedAt
      tags {
        value
        label
        path
      }
    }
  }
}
    `;

/**
 * __useGetBookmarksByTagQuery__
 *
 * To run a query within a React component, call `useGetBookmarksByTagQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookmarksByTagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookmarksByTagQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetBookmarksByTagQuery(baseOptions: Apollo.QueryHookOptions<GetBookmarksByTagQuery, GetBookmarksByTagQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBookmarksByTagQuery, GetBookmarksByTagQueryVariables>(GetBookmarksByTagDocument, options);
      }
export function useGetBookmarksByTagLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBookmarksByTagQuery, GetBookmarksByTagQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBookmarksByTagQuery, GetBookmarksByTagQueryVariables>(GetBookmarksByTagDocument, options);
        }
export type GetBookmarksByTagQueryHookResult = ReturnType<typeof useGetBookmarksByTagQuery>;
export type GetBookmarksByTagLazyQueryHookResult = ReturnType<typeof useGetBookmarksByTagLazyQuery>;
export type GetBookmarksByTagQueryResult = Apollo.QueryResult<GetBookmarksByTagQuery, GetBookmarksByTagQueryVariables>;