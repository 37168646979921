import React, { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import Dropzone from "../AvatarDropzone";
import AvatarCrop from "../AvatarCrop";
import {PercentCrop} from "react-image-crop/src/types";
import axiosClient from "../../../../config/axios.config";
import {useUpdateUserMutation} from "../../../../generated/graphql/graph";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../redux/rootState";
import {Person} from "../../../../redux/models/Person";
import {toggleAvatarModal} from "../../../../redux/slices/sliceUI";


interface AvatarEditModelProps {
}
export default function AvatarModal({} : AvatarEditModelProps) {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(true)
    const [uploadIsDisabled, setUploadIsDisabled] = useState(true)
    const [file, setFile] = useState<File | null>(null);
    const [crop, setCrop] = useState<PercentCrop>();
    const [updateUserMutation, {loading, error, data} ] = useUpdateUserMutation()
    const cancelButtonRef = useRef(null)
    const avatar = useSelector((state: RootState) => state.person.avatar)

    const handleClickClose = (event: React.MouseEvent<HTMLElement>) => {
        setOpen(false)
        dispatch(toggleAvatarModal())
    }
    const handleSubmit = async (event: React.MouseEvent<HTMLElement>) => {
        if (file) {
            const formData = new FormData()
            formData.append('file', file)
            formData.append('crop', JSON.stringify(crop))

            const restClient = await axiosClient()
            restClient.post('/api/v1/upload/avatar', formData)
                .then((value) => {
                    console.log(value)
                    updateUserMutation({variables: {avatarPath: value.data.path, avatarExtension: value.data.type}})
                        .then((updatedUser) => {
                            if (updatedUser.data && updatedUser.data.UpdateUser) {
                                new Person(dispatch)
                                    .importGraphQlUser(updatedUser.data!.UpdateUser)
                                    .dispatchAvatar();
                            }
                        })
                    dispatch(toggleAvatarModal())
                })
                .catch(console.log)
        }
    }

    const  cropAdded = (c: PercentCrop) => {
        setCrop(c)
        setUploadIsDisabled(false)
    }

    const handleFileAdded = (newFile: File) => {
        setFile(newFile);
    };

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 dark:accent-slate-900">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg dark:accent-slate-900">
                                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 dark:bg-slate-900">
                                    <div className="sm:flex sm:items-start">
                                        <div className="container mx-auto">
                                            <label htmlFor="cover-photo"
                                                   className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">Profile photo</label>
                                            {file == null && (<Dropzone onFileAdded={handleFileAdded} />)}
                                            {file != null && (<AvatarCrop onCropAdded={cropAdded} avatarFile={file} />)}
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 dark:bg-slate-700">
                                    <button
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto disabled:text-gray-900 disabled:bg-gray-200 dark:disabled:bg-gray-400"
                                        onClick={handleSubmit}
                                        disabled={uploadIsDisabled}
                                    >
                                        Upload
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto dark:bg-blue-600 dark:ring-blue-600 dark:text-white"
                                        onClick={handleClickClose}

                                        ref={cancelButtonRef}

                                    >
                                        Cancel
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
