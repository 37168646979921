import React from 'react'
import {ReactSVG} from "react-svg";
import WaitSpinSvg from "../../../../assets/svg/animated-spin.svg";
import {ClassesSubmitBtn} from "../../../../utils/StylingClasses";
import {CheckCircleIcon, ExclamationCircleIcon} from "@heroicons/react/20/solid";
import {useTranslation} from "react-i18next";

interface Props {
    loading: any;
    error: any;
    done: any;
    label?: string
}
const SubmitBtn = ({loading, error, done, label} : Props) => {
    const {t} = useTranslation()
    return (
        <button
            type="submit" className={ClassesSubmitBtn}>
            {label ? label : t("Save")}
            {loading  && <ReactSVG src={WaitSpinSvg} className="-mr-0.5 h-4 w-4" aria-hidden="true"/>}
            <div>
                {error && <ExclamationCircleIcon className="-mr-0.5 h-5 w-5" aria-hidden="true"/>}
            </div>
            <div>
                {done && <CheckCircleIcon className="-mr-0.5 h-5 w-5" aria-hidden="true"/>}
            </div>
        </button>
    )
}

export default SubmitBtn;
