import React from 'react';
import MainMultiColumnContainer from "../../layout/MainMultiColumnContainer";
import BookmarkFavoritesComponent from "../../general/Bookmark/BookmarkFavorites";


interface Props {
}



const BookmarksInboxPage: React.FC<Props> = () => {
    return (
        <div className="App">
            <MainMultiColumnContainer>
                <BookmarkFavoritesComponent />
            </MainMultiColumnContainer>
        </div>
    );
};

export default BookmarksInboxPage


