export const generatePageList = (currentPage: number, totalPages: number, maxPagesToShow: number = 7): number[] => {
    const links = [];
    const maxAdjacentPages = Math.floor((maxPagesToShow - 3) / 2);
    const firstPage = 1;
    const lastPage = totalPages;

    if (totalPages <= maxPagesToShow) {
        // Show all pages if there are few enough
        for (let i = firstPage; i <= lastPage; i++) {
            links.push(i);
        }
    } else if (currentPage <= maxAdjacentPages + 1) {
        // Show the first `maxPagesToShow - 2` pages plus ellipsis
        for (let i = firstPage; i < firstPage + maxPagesToShow - 2; i++) {
            links.push(i);
        }
        links.push(-1);
        links.push(lastPage);
    } else if (currentPage >= lastPage - maxAdjacentPages) {
        // Show the last `maxPagesToShow - 2` pages plus ellipsis
        links.push(firstPage);
        links.push(-1);
        for (let i = lastPage - maxPagesToShow + 3; i <= lastPage; i++) {
            links.push(i);
        }
    } else {
        // Show a sliding window of `maxPagesToShow - 4` pages plus ellipses
        links.push(firstPage);
        links.push(-1);
        const windowStart = currentPage - maxAdjacentPages;
        const windowEnd = currentPage + maxAdjacentPages;
        for (let i = windowStart; i <= windowEnd; i++) {
            links.push(i);
        }
        links.push(-1);
        links.push(lastPage);
    }

    return links;
}