import React, {useEffect, useState} from 'react'
import {messaging} from "../../../config/firebase";
import { getToken, onMessage } from "firebase/messaging";
import { onBackgroundMessage } from "firebase/messaging/sw";
import {useRegisterTokenMutation} from "../../../generated/graphql/graph";

interface Props {
}
const NotificationComponent = ({} : Props) => {

    const [registerToken] = useRegisterTokenMutation()
    const [token, setToken] = useState(false)

    useEffect(() => {
        if (!token) {
            getToken(messaging, { vapidKey: 'BI7e0cnXIx-n_POHhV_OtHZQ5yD1_m41xUtkO9ijfvts8b1hOhLdKw2cw-y6TyVbjBS-fDZYa5XASzmDqkLec-k' })
                .then((currentToken) => {
                    setToken(true)
                    if (currentToken) {
                        registerToken({variables: {token: currentToken}})
                            .catch(console.log)
                    } else {
                        console.log('No registration token available. Request permission to generate one.');
                    }
                }).catch((err) => {
                console.log('An error occurred while retrieving token. ', err);
            });
        }
    })

    onMessage(messaging, (payload) => {
        console.log('Message received. ', payload);
        // ...
    });

    // onBackgroundMessage(messaging, (payload) => {
    //     console.log('[firebase-messaging-sw.js] Received background message ', payload);
    //     // // Customize notification here
    //     // const notificationTitle = 'Background Message Title';
    //     // const notificationOptions = {
    //     //     body: 'Background Message body.',
    //     //     icon: '/firebase-logo.png'
    //     // };
    //     //
    //     // self.registration.showNotification(notificationTitle,
    //     //     notificationOptions);
    // });
    return (
        <></>
    )
}

export default NotificationComponent;
