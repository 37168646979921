import sliceReducer from "./slices/sliceCounter"
import {combineReducers, configureStore} from "@reduxjs/toolkit";
import slicePerson from "./slices/slicePerson";
import sliceUI from "./slices/sliceUI";
import {persistReducer, persistStore} from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from 'redux-thunk';
import sliceLatestBookmarks from "./slices/sliceLatestBookmarks";
import sliceUserSettings from "./slices/sliceUserSettings";
import tagsSlice from "./slices/sliceTags";

const persistConfig = {
    key: 'root',
    storage,
};

const reducers = combineReducers({
    counter: sliceReducer,
    person: slicePerson,
    ui: sliceUI,
    userSettings: sliceUserSettings,
    latestBookmarks: sliceLatestBookmarks,
    tags: tagsSlice
});
const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunk],

})

export const persistor = persistStore(store);

