import { AnyAction } from "@reduxjs/toolkit";
import {Dispatch} from "react";
import {setAvatar, setName, setRole, setCountry, setAuthenticated, setId} from "../slices/slicePerson";

export class Avatar {
    url: string;
    size: number;

    constructor(url: string, size: number) {
        this.url = url;
        this.size = size;
    }
}

export class Person {

    public id: string;

    public name: string;

    public role: string;

    public avatar: Avatar[];

    public country: string;

    public dispatch: Dispatch<AnyAction>

    constructor(dispatch: Dispatch<AnyAction>) {
        this.id = '';
        this.name = '';
        this.role = '';
        this.country = '';
        this.avatar = [];
        this.dispatch = dispatch;
    }

    public dispatchAvatar(): Person {
        this.dispatch(setAvatar(this.getAvatarUrl(32)))
        return this;
    }

    public dispatchId() : Person {
        this.dispatch(setId(this.id))
        return this;
    }

    public dispatchName() : Person {
        this.dispatch(setName(this.name))
        return this;
    }

    public dispatchRole(): Person {
        this.dispatch(setRole(this.role))
        return this;
    }

    public dispatchCountry(): Person {
        this.dispatch(setCountry(this.country))
        return this;
    }

    public getAvatarUrl(size: number): string {
        for (const avatar of this.avatar) {
            if (avatar.size === size) {
                return avatar.url + "?"+ Date.now()
            }
        }
        return "";
    }

    public login()  {
        this.dispatch(setAuthenticated(true))
    }

    public logout() {
        this.id = '';
        this.name = '';
        this.country = '';
        this.role = '';
        this.avatar = [];
        this.dispatchId()
        this.dispatchName()
        this.dispatchRole()
        this.dispatchAvatar()
        this.dispatchCountry()
        this.dispatch(setAuthenticated(false))
    }

    public importGraphQlUser(user: {
        __typename?: 'User',
        id: string,
        name: string,
        role: string,
        country?: string | null,
        avatar: Array<{ __typename?: 'Avatar', url: string, size: number } | null>
    }): Person {
        this.id = user.id;
        this.role = user.role
        this.name = user.name
        if (user.country) {
            this.country = user.country
        }
        this.avatar = [];
        for (const avatar of user.avatar) {
            this.avatar.push(new Avatar(avatar!.url, avatar!.size))
        }
        this.dispatch(setAuthenticated(true))
        this.dispatchName()
            .dispatchAvatar()
            .dispatchCountry()
            .dispatchId()
            .dispatchRole();
        return this;
    }
}