import {IBookmarkTag} from "../../../../config/interfaces/tags";
import BookmarkCard from "../BookmarkCard";
import {useGetHomeBookmarksQuery, useGetLatestBookmarksQuery} from "../../../../generated/graphql/graph";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../redux/rootState";
import {useEffect, useRef, useState} from "react";
import {setLatestBookmarks, setLatestUpdated, toggleLatestUpdated} from "../../../../redux/slices/sliceLatestBookmarks";
import {ArrowPathIcon} from '@heroicons/react/20/solid'
import ActionBtn from "../../Buttons/ActionBtn";
import {classNames} from "../../../../utils/classNames";
import {ClassesH1, ClassesH2, ClassesP, ClassesSubmitBtn} from "../../../../utils/StylingClasses";
import PageSizeSelect from "../../Form/PageSize";
import {setPageSize} from "../../../../redux/slices/sliceUI";
import userSettings from "../../../../hooks/UserSettings";
import {t} from "i18next";

interface Props {

}

const posts = [
    {
        id: 1,
        title: 'Boost your conversion rate',
        href: '#',
        description:
            'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
        imageUrl:
            'https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80',
        date: 'Mar 16, 2020',
        datetime: '2020-03-16',
        category: { title: 'Marketing', href: '#' },
        author: {
            name: 'Michael Foster',
            role: 'Co-Founder / CTO',
            href: '#',
            imageUrl:
                'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
    },
    // More posts...
]

const BookmarkHome = () => {
    const {pageSize, SetPageSize} = userSettings()
    const {data, loading, error, refetch} = useGetHomeBookmarksQuery()
    const refreshBtnRef = useRef<SVGSVGElement>(null)
    const isUpdated = useSelector((state: RootState) => state.latestBookmarks.isUpdated)
    const dispatch = useDispatch()
    const [isAnimating, setIsAnimating] = useState(false);

    useEffect(() => {
        if (isUpdated) {
            dispatch(toggleLatestUpdated())
            refetch()
        }
    }, [data])

    const pageSizeChange = (pageSize: number) => {
        // refetch().then((result) => {
        //     SetPageSize(pageSize)
        // })
    }

    const clickRefresh = () => {
        refetch()
        setIsAnimating(true)
        setTimeout(() => {
            setIsAnimating(false)
        }, 1000)
    }

    if (loading) {
        return (
            <p>Loading</p>
        )
    }

    if (error) {
        return (
            <p>Error</p>
        )
    }

    return (
        <div className="pt-10 mb-60">
            <div className="pt-20">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto max-w-2xl text-center">
                        <h2 className={ClassesH1}>{t("Favorites")}</h2>
                        <p className={ClassesP}>
                            {t("A selection of your favorite bookmarks")}
                        </p>
                    </div>
                    <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                        {data!.GetHomeBookmarks!.favorites.map((post) => (
                            <BookmarkCard contentID={post.id} title={post.title} text={post.text} url={post.url} tags={post.tags} screenshot={post.screenshot} />
                        ))}
                    </div>
                    <div className="mx-auto max-w-2xl text-center pt-10">
                        <h2 className={ClassesH1}>{t("Inbox")}</h2>
                        <p className={ClassesP}>
                            {t("Your untagged bookmarks")}
                        </p>
                    </div>
                    <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                        {data!.GetHomeBookmarks!.inbox.map((post) => (
                            <BookmarkCard contentID={post.id} title={post.title} text={post.text} url={post.url} tags={post.tags} screenshot={post.screenshot} />
                        ))}
                    </div>
                    <div className="mx-auto max-w-2xl text-center pt-10">
                        <h2 className={ClassesH1}>{t("The latest and greatest")}</h2>
                        <p className={ClassesP}>
                            {t("A selection of your latest added bookmarks")}
                        </p>
                    </div>
                    <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                        {data!.GetHomeBookmarks!.latest.map((post) => (
                            <BookmarkCard contentID={post.id} title={post.title} text={post.text} url={post.url} tags={post.tags} screenshot={post.screenshot} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BookmarkHome;