import React, {ForwardRefExoticComponent, SVGProps} from 'react'
import {classNames} from "../../../../utils/classNames";
import {NavLink, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {openSidebar} from "../../../../redux/slices/sliceUI";
import {useDispatch} from "react-redux";


export interface NavigationItem {
    name: string;
    href: string;
    icon: ForwardRefExoticComponent<Omit<SVGProps<SVGSVGElement>, "ref">>;
}

interface Props {
    links: NavigationItem[];
}

const MenuTopLinks = ({links} : Props) => {
    const location = useLocation()
    const { t } = useTranslation();
    const dispatch = useDispatch()

    const closeSideMenu = () => {
        dispatch(openSidebar(false))
    }

    return (
        <li>
            <ul role="list" className="-mx-2 space-y-1">
                {links.map((item) => (
                    <li key={item.name}>
                        <NavLink to={item.href}
                                 onClick={closeSideMenu}
                                 className={classNames(
                                     (item.href === location.pathname.toLowerCase())
                                         ? 'bg-gray-50 text-blue-600 dark:text-gray-600 dark:bg-slate-400'
                                         : 'text-gray-700 hover:text-blue-600 hover:bg-gray-50 dark:text-gray-400 dark:hover:bg-slate-400 hover:dark:text-white',
                                     'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                 )}>
                            <item.icon
                                className={classNames(
                                    (item.href === location.pathname.toLowerCase()) ? 'text-blue-600 dark:text-white dark:group-hover:text-white' : 'text-gray-400 dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-white', 'h-6 w-6 shrink-0'
                                )}
                                aria-hidden="true"/>
                            {t(item.name)}
                        </NavLink>
                    </li>
                ))}
            </ul>
        </li>
    )
}

export default MenuTopLinks;
