import {initializeApp} from 'firebase/app'
import {getAnalytics} from 'firebase/analytics'
import {getAuth} from 'firebase/auth'
import {getMessaging} from "@firebase/messaging";

const app = initializeApp({
    appId: "1:1046765746223:web:5ec8a9bbc12658b7646eda",
    apiKey: "AIzaSyARJB6C0tQvRkabst0uQjdI8pMPXAzGrTI",
    // authDomain: "komtooit-c9a8a.firebaseapp.com",
    authDomain: "app.komtoo.it",
    projectId: "komtooit-c9a8a",
    storageBucket: "komtooit-c9a8a.appspot.com",
    messagingSenderId: "1046765746223",
    measurementId: "G-72X95T0DTQ"
})
export const analytics = getAnalytics(app)
export const appAuth = getAuth(app)
export const messaging = getMessaging(app)



// export const SendRequest = () => {
//     console.log("Requesting User Permission......");
//     Notification.requestPermission().then((permission) => {
//         if (permission === "granted") {
//             console.log("Notification User Permission Granted.");
//         } else {
//             console.log("User Permission Denied.");
//         }
//     });
// }
//
// SendRequest();
