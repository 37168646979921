import axios, { AxiosInstance } from 'axios';
import {waitForAuth} from "./apollo.config";

const axiosClient = async (): Promise<AxiosInstance> => {
    const user = await waitForAuth();
    let token = ""
    if (user != null) {
        token  = await user?.getIdToken();
    }

    return axios.create({
        baseURL:
            process.env.NODE_ENV === 'production'
            ? `/`
            : `http://app-dev.komtoo.it:8888/`,
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });
}

export default axiosClient