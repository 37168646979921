import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon, PlusIcon } from '@heroicons/react/24/outline'
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../redux/rootState";
import {toggleAddBookmarkModal} from "../../../../redux/slices/sliceUI";
import {useAddBookmarkMutation} from "../../../../generated/graphql/graph";

import {string} from "yup";
import {RegexUrl} from "../../../../utils/regexUrl";
import {ClassesFormInput, ClassesFormInputError} from "../../../../utils/StylingClasses";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {classNames} from "../../../../utils/classNames";
import {setLatestBookmarks, setLatestUpdated} from "../../../../redux/slices/sliceLatestBookmarks";
const schema = string().matches(RegexUrl).required()


export default function AddBookmarkModal() {
    const open = useSelector((state: RootState) => state.ui.addBookmarkModal)
    const {t} = useTranslation()
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [addContentMutation, {data, error, loading}] = useAddBookmarkMutation()
    const addURLtRef = useRef<HTMLInputElement>(null);
    const [fieldStateError, setFieldStateError] = useState(false)

    const toggleModal = () => {
        dispatch(toggleAddBookmarkModal())
    }

    const storeBookMark = async () => {
        const url = addURLtRef.current!.value
        // const valid = await schema.isValid(url)
        // const urlRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,20}\b([-a-zA-Z0-9()!@:%_\+.~#?&\/\/=]*)/
        if (isValidUrl(url)) {
            addContentMutation({variables: {
                    url: url,
                }})
                .then((value) => {
                    const id = value.data!.AddBookmark!.id
                    dispatch(toggleAddBookmarkModal())
                    dispatch(setLatestUpdated(true))
                    navigate("/bookmark/edit/" + id)
                })
                .catch((error) => {
                    console.log("Implement error", error)
                })

        } else{
            setFieldStateError(true)
            console.log("invalid URL")
        }
    }

    function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        storeBookMark();
    }

    const isValidUrl = (urlString: string) => {
        var urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
            '(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator
        return !!urlPattern.test(urlString);
    }

    const keyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const url = addURLtRef.current!.value
        if(isValidUrl(url)) {
            setFieldStateError(false)
        } else {
            setFieldStateError(true)
        }
    }

    const cancelButtonRef = useRef(null)

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={toggleModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg dark:bg-slate-700">
                                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 dark:bg-slate-700">
                                    <div className="sm:flex sm:items-start">
                                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                                            <PlusIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
                                        </div>
                                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                            <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900 dark:text-white">
                                                {t("Add Bookmark")}
                                            </Dialog.Title>
                                            <div className="mt-2">
                                                <p className="text-sm text-gray-500 dark:text-gray-100 mb-4">
                                                    {t("Insert the URL of the bookmark you want to add. You will get the ability to add tagging an location later.")}
                                                </p>
                                                <form onSubmit={handleSubmit}>
                                                    <div>
                                                        <label htmlFor="email" className="block leading-6 text-sm text-gray-500 dark:text-gray-100 text-left font-bold">
                                                            {t("URL")}:
                                                        </label>
                                                        <div className="mt-2">
                                                            <input
                                                                type="url"
                                                                name="url"
                                                                id="AddBookmarkModalUrl"
                                                                className={(fieldStateError ? ClassesFormInputError : ClassesFormInput)}
                                                                placeholder="https://"
                                                                ref={addURLtRef}
                                                                onKeyDown={keyPress}
                                                                onKeyUp={keyPress}
                                                            />
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 dark:bg-slate-600">
                                    <button
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
                                        onClick={() => storeBookMark()}
                                    >
                                        {t("Add Bookmark")}
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto dark:bg-slate-400 dark:ring-slate-400"
                                        onClick={() => toggleModal()}
                                        ref={cancelButtonRef}
                                    >
                                        {t("Cancel")}
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
