import React from 'react';
import MainMultiColumnContainer from "../../layout/MainMultiColumnContainer";
import BookmarkEdit from "../../general/Bookmark/BookmarkEdit";
import TagEditComponent from '../../general/Tags/TagEditComponent';

interface Props {
}



const TagEditPage: React.FC<Props> = () => {

    return (
        <div className="App">
            <MainMultiColumnContainer>
                <TagEditComponent />
            </MainMultiColumnContainer>
        </div>
    );
};

export default TagEditPage


