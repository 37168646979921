import React, {MouseEventHandler, ReactNode, Ref, RefObject, useEffect} from 'react'
import {ClassesSubmitBtn} from "../../../../utils/StylingClasses";
import {NavLink} from "react-router-dom";

interface Props {
    children: ReactNode;
    label?: string
    to?: string
    className?: string
    ref?: Ref<any> | undefined;
    onClick?: MouseEventHandler<HTMLButtonElement|HTMLAnchorElement> | undefined
}
const ActionBtn = ({onClick, children, to, className, ref } : Props) => {
        return (
        <NavLink to={to ? to : "#"} className={(className ? className : ClassesSubmitBtn)} onClick={onClick} ref={ref}>
            {children}
        </NavLink>
    )
}

export default ActionBtn;
