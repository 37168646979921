import React, {Fragment, useEffect, useState} from 'react'
import { Menu, Transition } from '@headlessui/react'
import {
    ArrowRightOnRectangleIcon,
    PlusIcon,
    UserCircleIcon,
    CogIcon,
    ArrowUpTrayIcon,
} from '@heroicons/react/20/solid'
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../redux/rootState";
import {ReactComponent as ProfileImage} from '../../../../assets/svg/profile-image.svg';
import {NavLink, useNavigate} from "react-router-dom";
import {signOut} from "firebase/auth";
import {appAuth} from "../../../../config/firebase";
import ROUTE from "../../../../config/routes";
import {openSidebar, toggleAddBookmarkModal, toggleBookmarkImportModal} from '../../../../redux/slices/sliceUI'
import {Person} from "../../../../redux/models/Person";
import {useGetMeQuery} from "../../../../generated/graphql/graph";
import {classNames} from "../../../../utils/classNames";
import {useTranslation} from "react-i18next";

interface Props {

}

const ProfileMenuDropDown = ({} : Props) =>  {
    const {t} = useTranslation()
    const dispatch = useDispatch();
    const [greeting, setGreeting] = useState('Hello')
    const {data, loading, error} = useGetMeQuery()
    const avatar = useSelector((state: RootState) => state.person.avatar)
    const name = useSelector((state: RootState) => state.person.name)
    const showBookmarkImportModal = useSelector((state: RootState) => state.ui.bookmarkImportModal)
    const navigate = useNavigate()
    useEffect(() => {
        const hour = new Date().getHours();
        if (hour >= 0 && hour < 6) {
            setGreeting("Good night")
        } else if (hour >= 6 && hour < 12) {
            setGreeting("Good morning")
        } else if (hour >= 12 && hour < 18) {
            setGreeting("Good afternoon")
        } else {
            setGreeting("Good evening")
        }
        if (data?.GetMe) {
            new Person(dispatch)
                .importGraphQlUser(data.GetMe)
        }
    },[greeting, data])

    const handleLogout = (event: React.MouseEvent<HTMLElement>) => {
        signOut(appAuth)
            .then(() => {
                new Person(dispatch).logout()
                navigate(ROUTE.SIGN_IN)
            })
            .catch(() => navigate(ROUTE.SIGN_IN))

    }

    const addBookmarkClick = () => {
        dispatch(openSidebar(false))
        dispatch(toggleAddBookmarkModal())
    }

    return (
        <Menu as="div" className="relative inline-block text-left">
            <Menu.Button className="inline-flex w-full justify-end rounded-md bg-transparent text-sm font-semibold text-gray-900" title={t("Settings menu")}>
                <span className="sr-only">Open user menu</span>
                {avatar && <img className="h-8 w-8 rounded-full" src={avatar} alt="Profile" />}
                {!avatar && <ProfileImage className="h-8 w-8 fill-gray-400" />}
            </Menu.Button>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 dark:divide-slate-400 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-slate-700">
                    <div className="py-1">
                        <Menu.Item>
                            {({ active }) => (
                                <span
                                    className="text-gray-700 dark:text-gray-300 group flex items-center px-4 py-2 text-sm"
                                >
                                    {avatar && <img className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" src={avatar} alt={t("Profile").toString()} />}
                                    {!avatar && <ProfileImage className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />}
                                    <span>
                                        <span className="font-bold">{t(greeting)}</span><br />
                                        {name}
                                    </span>

                                </span>
                            )}
                        </Menu.Item>
                    </div>
                    <div className="py-1">
                        <Menu.Item>
                            {({ active }) => (
                                <NavLink to="#" onClick={addBookmarkClick}
                                         className={classNames(active ? 'bg-gray-100 text-gray-900 dark:bg-slate-500 dark:text-white' : 'text-gray-700 dark:text-gray-300', 'group flex items-center px-4 py-2 text-sm')}>
                                    <PlusIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 dark:group-hover:text-white" aria-hidden="true" />
                                    {t("Add new bookmark")}
                                </NavLink>

                            )}
                        </Menu.Item>
                    </div>
                    <div className="py-1">
                        <Menu.Item>
                            {({ active }) => (
                                <NavLink to={ROUTE.PROFILE}
                                         className={classNames(active ? 'bg-gray-100 text-gray-900 dark:bg-slate-500 dark:text-white' : 'text-gray-700 dark:text-gray-300', 'group flex items-center px-4 py-2 text-sm')}>
                                    <UserCircleIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 dark:group-hover:text-white" aria-hidden="true" />
                                    {t("Your profile")}
                                </NavLink>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({ active }) => (
                                <NavLink to={ROUTE.SETTINGS}
                                         className={classNames(active ? 'bg-gray-100 text-gray-900 dark:bg-slate-500 dark:text-white' : 'text-gray-700 dark:text-gray-300', 'group flex items-center px-4 py-2 text-sm')}>
                                    <CogIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 dark:group-hover:text-white" aria-hidden="true" />
                                    {t("Settings")}
                                </NavLink>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({ active }) => (
                                <NavLink to={ROUTE.IMPORTS}
                                         className={classNames(active ? 'bg-gray-100 text-gray-900 dark:bg-slate-500 dark:text-white' : 'text-gray-700 dark:text-gray-300', 'group flex items-center px-4 py-2 text-sm')}>
                                    <ArrowUpTrayIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 dark:group-hover:text-white" aria-hidden="true" />
                                    {t("Import Bookmarks")}
                                </NavLink>
                            )}
                        </Menu.Item>
                    </div>
                    <div className="py-1">
                        <Menu.Item>
                            {({ active }) => (
                                <NavLink to="" onClick={handleLogout}
                                         className={classNames(active ? 'bg-gray-100 text-gray-900 dark:bg-slate-500 dark:text-white' : 'text-gray-700 dark:text-gray-300', 'group flex items-center px-4 py-2 text-sm')}>
                                    <ArrowRightOnRectangleIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 dark:group-hover:text-white" aria-hidden="true" />
                                    {t("Sign out")}
                                </NavLink>
                            )}
                        </Menu.Item>
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}

export default ProfileMenuDropDown;