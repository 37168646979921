import React from 'react';
import SigninForm from "../../general/SignInForm";


interface Props {
}

const Login: React.FC<Props> = () => {
    return (
        <div className="App">
            <section>
                <div>
                    <SigninForm />
                </div>
            </section>
        </div>
    );
};

export default Login


