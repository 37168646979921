import { UserCircleIcon } from '@heroicons/react/24/solid'
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../redux/rootState";
import CountrySelect from '../CountrySelect';
import {useUpdateUserMutation} from "../../../../generated/graphql/graph";
import {Person} from "../../../../redux/models/Person";
import {setCountry} from "../../../../redux/slices/slicePerson";
import classNames from "classnames";
import CheckIconSvg from '../../../../assets/svg/fa/solid/check-circle.svg'
import ErrorIconSvg from '../../../../assets/svg/fa/solid/exclamation-circle.svg'
import { ReactSVG } from 'react-svg'
import {toggleAvatarModal} from "../../../../redux/slices/sliceUI";
import {ClassesFormLabel, ClassesFormInput, ClassesH1} from "../../../../utils/StylingClasses";
import ActionBtn from "../../Buttons/ActionBtn";
import CancelBtn from "../../Buttons/CancelBtn";
import SubmitBtn from "../../Buttons/SubmitBtn";
import {t} from "i18next";
type ProfileFormValues = {
    firstname: string;
    country: string;
};


const validationSchema = Yup.object().shape({
    firstname: Yup.string().required('Required'),
    country: Yup.string().required('Required')
});


export default function ProfileSettings() {
    const dispatch = useDispatch();
    const name = useSelector((state: RootState) => state.person.name)
    const avatar = useSelector((state: RootState) => state.person.avatar)
    const country = useSelector((state: RootState) => state.person.country)
    const [updateUserMutation, {loading: updateLoading, error: updateError, data: updateData} ] = useUpdateUserMutation()
    let initialValues: ProfileFormValues = {
        firstname: name,
        country: country,
    }

    const toggleAvatarMdl = () => {
        dispatch(toggleAvatarModal())
    }

    const countryChange = (code: string) => {
        dispatch(setCountry(code));
    }

    const handleSubmit = async (values: ProfileFormValues) => {
        updateUserMutation({variables: {name: values.firstname, country: values.country}})
            .then((updatedUser) => {
                if (updatedUser.data && updatedUser.data.UpdateUser) {
                    new Person(dispatch)
                        .importGraphQlUser(updatedUser.data!.UpdateUser)
                        .dispatchName()
                        .dispatchCountry();

                }
            })
            .catch(console.log)
        console.log(values)
    };
    return (
        <div className="mx-auto max-w-7xl px-4 py-20 sm:px-6 sm:py-20 lg:px-8">
            <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema} enableReinitialize={true}>
                {({errors, touched}) => (
                <Form>
                    <div className="mx-auto max-w-3xl">
                        <div className="mx-auto max-w-7xl px-4 py-5 sm:px-6 sm:pt-32 lg:px-8">
                            <h1 className={ClassesH1}>{t("Edit your profile")}</h1>
                            <div className="mb-10">
                                <label htmlFor="photo" className={ClassesFormLabel}>
                                    {t("Photo")}
                                </label>
                                <div className="mt-2 flex items-center gap-x-3">
                                    {!avatar && <UserCircleIcon className="h-12 w-12 text-gray-300" aria-hidden="true" />}
                                    {avatar && <img alt="Avatar" src={avatar} /> }
                                    <ActionBtn onClick={toggleAvatarMdl}>
                                        {t("Change")}
                                    </ActionBtn>
                                </div>
                            </div>
                            <div className="border-b border-gray-900/10 pb-12 dark:border-slate-400">
                                <div className="mb-3">
                                    <label htmlFor="email" className={ClassesFormLabel}>
                                        {t("First name")}*
                                    </label>
                                    <div className="mt-2">
                                        <Field
                                            type="text"
                                            name="firstname"
                                            id="firstname"
                                            autoComplete="given-name"
                                            className={ClassesFormInput}
                                        />
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="email" className={ClassesFormLabel}>
                                        {t("Country")}
                                    </label>
                                    <div className="mt-2">
                                        <CountrySelect  onChange={countryChange}/>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-6 flex items-center justify-end gap-x-6">
                                <CancelBtn to="/" />
                                <SubmitBtn loading={updateLoading} error={updateError} done={updateData} />
                            </div>
                        </div>
                    </div>
            </Form>
            )}
        </Formik>
        </div>
    )
}
