import {Fragment, useEffect, useState} from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import {countries} from '../ProfileSettings/countries'
import {useSelector} from "react-redux";
import {RootState} from "../../../../redux/rootState";
import {ClassesFormSelect} from "../../../../utils/StylingClasses";

// @ts-ignore
function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

interface Props {
    onChange: (code: string) => void;
}

interface Country {
    code: string;
    name: string;
}

export default function CountrySelect({onChange} : Props) {
    const country = useSelector((state: RootState) => state.person.country)
    const [selected, setSelected] = useState(countries[0])


    const findSelectedCountry = (): Country => {
        console.log("country", country)
        for (const cCountry of countries) {
            if (cCountry.code === country) {
                return cCountry;
            }
        }
        return countries[0];
    }

    useEffect(() => {
        setSelected(findSelectedCountry())
    }, [country])



    const handleChange = (value: Country) => {
        onChange(value.code)
        setSelected(value)
    }
    return (
        <Listbox value={selected} onChange={handleChange}>
            {({ open }) => (
                <>
                    <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900"></Listbox.Label>
                    <div className="relative mt-2">
                        <Listbox.Button className={ClassesFormSelect}>
              <span className="flex items-center">
                  {selected.code ? <img src={`https://flagcdn.com/w40/${selected.code.toLowerCase()}.png`}
                       srcSet={`https://flagcdn.com/w80/${selected.code.toLowerCase()}.png 2x`}
                       className="h-5 w-5 flex-shrink-0 rounded-full"
                       alt={selected.name} /> : ''}
                <span className="ml-3 block truncate">
                    {selected.name}
                </span>
              </span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400 dark:fill-gray-400" aria-hidden="true" />
              </span>
                        </Listbox.Button>

                        <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {countries.map((country) => (
                                    <Listbox.Option
                                        key={country.code}
                                        className={({ active }) =>
                                            classNames(
                                                active ? 'bg-blue-600 text-white' : 'text-gray-900',
                                                'relative cursor-default select-none py-2 pl-3 pr-9 dark:bg-slate-800 dark:ring-0 dark:text-slate-300 dark:highlight-white/5 dark:hover:bg-slate-700'
                                            )
                                        }
                                        value={country}
                                    >
                                        {({ selected, active }) => (
                                            <>
                                                <div className="flex items-center">
                                                    {country.code ? <img src={`https://flagcdn.com/w40/${(country.code ? country.code.toLowerCase() : '')}.png`}
                                                         className="h-5 w-5 flex-shrink-0 rounded-full"
                                                        alt={country.name} />: ''}
                                                    <span
                                                        className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}>
                                                    {country.name}
                                                  </span>
                                                </div>

                                                {selected ? (
                                                    <span
                                                        className={classNames(
                                                            active ? 'text-white' : 'text-blue-600',
                                                            'absolute inset-y-0 right-0 flex items-center pr-4 dark:text-slate-400'
                                                        )}
                                                    >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                                                ) : null}
                                            </>
                                        )}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </>
            )}
        </Listbox>
    )
}
