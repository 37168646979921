import { ArrowLongLeftIcon, ArrowLongRightIcon } from '@heroicons/react/20/solid'
import {useEffect, useState} from "react";
import {createList} from "../../../utils/parseInt";
import {generatePageList} from "../../../utils/paginanation";
import {classNames} from "../../../utils/classNames";
import {NavLink} from "react-router-dom";

interface Props {
    total: number
    pageSize: number
    page: number
}

const PaginationComponent = ({total, pageSize, page} : Props) => {
    const [pages, setPages] = useState(0)


    useEffect(() => {
        const pagesSum = Math.ceil(total / pageSize)
        setPages(pagesSum)
        // console.log(generateLinks(page, pages))
    })

    return (
        <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0 dark:border-slate-600">
            <div className="-mt-px flex w-0 flex-1">
                {page > 1 ?
                <NavLink
                    to={`${window.location.pathname}?page=${page - 1}`}
                    className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700 dark:text-gray-300 dark:hover:border-slate-600"
                >
                    <ArrowLongLeftIcon className="mr-3 h-5 w-5 text-gray-400 dark:text-gray-200" aria-hidden="true" />
                    Previous
                </NavLink>
                    : ''
                }
            </div>
            <div className="hidden md:-mt-px md:flex">
                {generatePageList(page, pages).map(index => (
                    <>
                        {index > -1 ?
                            <NavLink to={`${window.location.pathname}?page=${index}`}
                                 className={index === page ? "inline-flex items-center border-t-2 border-blue-500 px-4 pt-4 text-sm font-medium text-blue-600 dark:text-gray-400 dark:border-slate-500" : "inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700  dark:text-gray-400 dark:hover:border-slate-400"}
                                 aria-current={index === page ? 'page' : undefined} key={index}>
                                {index}
                            </NavLink>
                            :
                            <span className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500" key={index}>
                                ...
                            </span>
                        }
                    </>
                ))}
            </div>
            <div className="-mt-px flex w-0 flex-1 justify-end">
                {page < pages ?
                <NavLink
                    to={`${window.location.pathname}?page=${page + 1}`}
                    className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700 dark:text-gray-300 dark:hover:border-slate-600"
                >
                    Next
                    <ArrowLongRightIcon className="ml-3 h-5 w-5 text-gray-400  dark:text-gray-200" aria-hidden="true" />
                </NavLink>
                    : ''
                }
            </div>
        </nav>
    )
}

export default PaginationComponent;