import BookmarkCard from "../BookmarkCard";
import {
    useGetAllBookmarksQuery,
} from "../../../../generated/graphql/graph";
import {useRef, useState} from "react";
import {ArrowPathIcon, RssIcon} from '@heroicons/react/20/solid'
import ActionBtn from "../../Buttons/ActionBtn";
import {classNames} from "../../../../utils/classNames";
import {ClassesSubmitBtn} from "../../../../utils/StylingClasses";
import {useLocation} from "react-router-dom";
import userSettings from "../../../../hooks/UserSettings";
import PaginationComponent from "../../PaginationComponent";
import {GetPageNumber} from "../../../../utils/parseInt";
import BreadcrumbComponent from "../../BreadcrumbComponent";
import ROUTE, {getRoute} from "../../../../config/routes";
import {t} from "i18next";

const BookmarkAllComponent = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const page = GetPageNumber(searchParams.get('page'))
    const {pageSize} = userSettings()
    const {data, loading, error, refetch} = useGetAllBookmarksQuery({variables: {page: page}})
    const refreshBtnRef = useRef<SVGSVGElement>(null)
    const [isAnimating, setIsAnimating] = useState(false);

    const clickRefresh = () => {
        refetch()
        setIsAnimating(true)
        setTimeout(() => {
            setIsAnimating(false)
        }, 1000)
    }

    if (loading) {
        return (
            <p>Loading</p>
        )
    }

    if (error) {
        return (
            <p>Error</p>
        )
    }

    return (
        <div className="pt-10 mb-60">
            <div className="hidden max-md:flex justify-end mb-2 space-x-2">
                <ActionBtn onClick={clickRefresh} className={classNames(ClassesSubmitBtn, "group  inline-block")} aria-label="Refresh page">
                    <ArrowPathIcon className={classNames("w-5 h-5 ", (isAnimating ? "animate-spin" : ""))} ref={refreshBtnRef}/>
                </ActionBtn>
            </div>
            <div className="flex">
                <BreadcrumbComponent path={[{name: t("All"), href: getRoute(ROUTE.ALL), current: true, icon: RssIcon}]}/>
                <div className="w-full justify-end flex-1 space-x-2 text-right max-md:hidden">
                    <ActionBtn onClick={clickRefresh} className={classNames(ClassesSubmitBtn, "group  inline-block")} aria-label="Refresh page">
                        <ArrowPathIcon className={classNames("w-5 h-5 ", (isAnimating ? "animate-spin" : ""))} ref={refreshBtnRef}/>
                    </ActionBtn>
                </div>
            </div>
            <div className="">
                <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-8 pt-10 pb-10">
                    {data?.GetAllBookmarks.bookmarks.map((content) => (
                        <BookmarkCard title={content.title} read={false} screenshot={content.screenshot!} text={content.text} url={content.url} tags={content.tags} contentID={content.id} key={content.id}/>
                    ))}
                </div>
                <div className="pt-5">
                    <PaginationComponent total={data!.GetAllBookmarks.total} pageSize={pageSize} page={page} />
                </div>
            </div>
        </div>
    )
}

export default BookmarkAllComponent;