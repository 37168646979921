import React, {useEffect, useState} from 'react'
import {
    useDeleteTagMutation,
    useGetAllTagsQuery,
    useGetTagByIdQuery,
    useUpdateTagMutation
} from "../../../../generated/graphql/graph";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import ROUTE, {getRoute} from "../../../../config/routes";
import {t} from "i18next";
import ConfirmModal from "../../Modals/ConfirmModal";
import {ErrorMessage, Field, Form, Formik} from "formik";
import BookmarkScreenshotImage from "../../Bookmark/BookmarkScreenshotImage";
import {ClassesDeleteBtn, ClassesFormInput, ClassesFormLabel} from "../../../../utils/StylingClasses";
import CreatableSelect from "react-select/creatable";
import ActionBtn from "../../Buttons/ActionBtn";
import CancelBtn from "../../Buttons/CancelBtn";
import SubmitBtn from "../../Buttons/SubmitBtn";
import * as Yup from "yup";
import SelectComponent from "../../Form/SelectComponent";
import {ISelectOption} from "../../../../redux/models/ISelectOption";
import {FormatTagLink} from "../../../../utils/Tags";
import {useDispatch, useSelector} from "react-redux";
import {setTagsUpdated} from "../../../../redux/slices/sliceTags";
import {toggleConfirmModal} from "../../../../redux/slices/sliceUI";
import {RootState} from "../../../../redux/rootState";

interface InitialValues {
    label: string;
    description: string;
    parent: string;
    id: string;
}


const validationSchema = Yup.object().shape({
    // @ts-ignore
    label: Yup.string().required(t("Label is a required field"))
});


interface Props {
}
const TagEditComponent = () => {
    const {tagID: ID} = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {data: tagData, loading: tagLoading, error: tagError} = useGetTagByIdQuery({variables: {ID: ID!}})
    const {data: tagsData, loading: tagsLoading, error: tagsError, refetch: refetchAllTags} = useGetAllTagsQuery({variables: {order: "path"}})
    const [updateTagMutation, {loading: updateLoading, error: updateError, data: updateData}] = useUpdateTagMutation()
    const [deleteTagMutation] = useDeleteTagMutation()
    const [tagID, setTagID] = useState('')
    const [tagLabel, setTagLabel] = useState('')
    const [tagDescription, setTagDescription] = useState('')
    const [tagParent, setTagParent] = useState('')
    const [options, setOptions] = useState<ISelectOption[]>([])
    const [selectedValue, setSelectedValue] = useState('')
    const showConfirmModal = useSelector((state: RootState) => state.ui.confirmModal)

    let initialValues: InitialValues = {
        id: tagID!,
        label: tagLabel,
        parent: tagParent,
        description: tagDescription,
    }

    useEffect(() => {
        refetchAllTags()
        if (tagsData && tagData?.GetTag) {
            const data = tagData!.GetTag!
            setSelectedValue(tagData!.GetTag!.parent ? tagData!.GetTag!.parent : '')
            setTagDescription(data.description)
            setTagID(data.value)
            setTagLabel(data.label)
        }

        if (tagsData && tagsData.GetAllTags) {
            let opts: ISelectOption[] = [{value: '-1', label: t("This tag does not need any parent")}]
            for (const tag of tagsData.GetAllTags) {
                if (parseInt(ID!) !== parseInt(tag.value)) {
                    opts.push({value: tag.value, label: tag.path})
                }
            }
            setOptions(opts)
        }

    }, [ID, tagsData, tagData])

    const handleSubmit = async (values: InitialValues) => {
        updateTagMutation({variables: {id: values.id, description: values.description, label: values.label, parent: parseInt(values.parent)}})
            .then((gd) => {
                if (gd && gd.data && gd.data.UpdateTag) {
                    const d = gd.data.UpdateTag
                    dispatch(setTagsUpdated(true))
                    navigate(FormatTagLink(d))
                }
            })
            .catch(console.log)
        console.log(values)
    }

    const handleSelectChange = (option: ISelectOption) => {
        setTagParent(option.value.toString())
    }

    const handleDeleteClick = ()=> {
        dispatch(toggleConfirmModal())
        deleteTagMutation({variables: {id: tagID}})
            .then((qd) => {
                setTimeout(() => {
                    dispatch(setTagsUpdated(true))
                    navigate(getRoute(ROUTE.HOME))
                }, 500)
            })
            .catch(console.log)
    }

    const toggleModal = () => {
        dispatch(toggleConfirmModal())
    }

    if (tagError || tagsError) {
        return (
            <div className="bg-white shadow sm:rounded-lg dark:bg-slate-700">
                <div className="px-4 py-2 sm:p-6">
                    <h3 className="text-base font-semibold leading-6 text-gray-900 dark:text-white">{t("Issue loading the requested Tag")}</h3>
                    <div className="mt-2 sm:flex sm:items-start sm:justify-between">
                        <div className="max-w-xl text-sm text-gray-500 dark:text-gray-300">
                            <p>
                                {t("Sorry, we couldn't load your Tag right now due to an error. We apologize for the inconvenience and suggest that you try again later. If the problem persists, please contact our support team for assistance.")}
                                <span className="font-bold py-3 block">{t("Error")}: {tagError && tagError.message}{tagsError && tagsError.message}</span>
                            </p>
                        </div>
                        <div className="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-center">
                            <NavLink
                                to={ROUTE.HOME}
                                className="inline-flex items-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
                            >
                                {t("Continue")}
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    if (tagsLoading || tagLoading)  {
        <div>loading</div>
    }


    return (
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            {showConfirmModal && <ConfirmModal title={t("Are you sure you want to delete this Tag?")} content={t("Are you sure you want to delete this Tag. It cannot be recovered.")} btnLabel={t("Yes, I'm sure!")} onClickedYes={handleDeleteClick}  onClickedNo={toggleModal}/>}
            <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema} enableReinitialize={true}>
                {({errors, touched}) => (
                    <Form>
                        <div className="mx-auto max-w-3xl">
                            <div className="mx-auto max-w-7xl px-4 py-5 sm:px-6 sm:pt-28 lg:px-8">
                                <div className="border-b border-gray-900/10 pb-8 dark:border-slate-400">
                                    <h2 className="text-base font-semibold leading-7 text-gray-900 mb-3">{t("Edit Tag")}</h2>
                                    <div className="mb-3">
                                        <label htmlFor="label" className={ClassesFormLabel}>
                                            {t("Name")} *
                                        </label>
                                        <div className="mt-2">
                                            <Field
                                                type="text"
                                                name="label"
                                                id="label"
                                                className={ClassesFormInput} />
                                            <ErrorMessage name="label" className="text-red-500 text-xs italic" component="p" />
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="description" className={ClassesFormLabel}>
                                            {t("Description")}
                                        </label>
                                        <div className="mt-2">
                                            <Field
                                                type="text"
                                                name="description"
                                                id="description"
                                                className={ClassesFormInput} />
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <SelectComponent label={t("Place tag under")} options={options} selectedValue={selectedValue} onSelect={handleSelectChange}/>
                                    </div>
                                </div>
                                <Field
                                    type="hidden"
                                    name="parent"
                                    id="parent"
                                />
                                <Field
                                    type="hidden"
                                    name="id"
                                    id="id"
                                />
                            </div>
                            <div className="flex justify-between mt-3">
                                <div className="flex items-center justify-normal gap-x-6">
                                    <ActionBtn onClick={toggleModal} className={ClassesDeleteBtn}>
                                        {t("Delete")}
                                    </ActionBtn>
                                </div>
                                <div className="flex items-center justify-end gap-x-6">
                                    <CancelBtn to="/" />
                                    <SubmitBtn loading={updateLoading} error={updateError} done={updateData} />
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default TagEditComponent;
