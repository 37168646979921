import React from 'react';
import MainMultiColumnContainer from "../../layout/MainMultiColumnContainer";
import BookmarkAllComponent from "../../general/Bookmark/BookmarkAll";
import BookmarkInbox from "../../general/Bookmark/BookmarkInbox";


interface Props {
}



const BookmarksAllPage: React.FC<Props> = () => {
    return (
        <div className="App">
            <MainMultiColumnContainer>
                <BookmarkAllComponent />
            </MainMultiColumnContainer>
        </div>
    );
};

export default BookmarksAllPage


