

export const GetPageNumber = (page: string | null) => {
    if (null === page) {
        return 1;
    }
    const pageNumber = parseInt(page)
    if (pageNumber === undefined) {
        return 1
    }
    return pageNumber
}


export const createList = (x: number) => Array.from({ length: x }, (_, i) => i);

export const pageList = (x: number) => Array.from({ length: x }, (_, i) => i);
